import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// import { $get, $post, $put, $delete, $upload } from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import { $post, $put, $delete, $get, $dateTimezone } from "../utils/common";
// import WorkspaceData from "./Workspace.json";
import Swal from "sweetalert2";
import ShareModal from "../component/ShareModal";
import Loading from "../component/Loading";

function TokenUsage() {
  const navigate = useNavigate();

  const { isLoggedIn, tokenData, checkLogin } = useAuth();

  const [tokenList, setTokenList] = useState([]);
  const [pageLimit, setPageLimit] = useState(15);
  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageRange, setPageRange] = useState([]);
  const [lastPage, setLastPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const scrollRef = useRef(null);

  const goToToken = (id, step) => () => {};

  const paging = () => {
    const pages = [];

    const pageFirst =
      pageNo % 5 === 0 ? pageNo - 4 : Math.floor(pageNo / 5) * 5 + 1;
    let pageLast = pageFirst + 4;

    if (pageLast > totalPage) pageLast = totalPage;
    setLastPage(pageLast);

    for (let i = pageFirst; i <= pageLast; i++) {
      pages.push(i);
    }
    setPageRange(pages);
  };

  const getTokenList = async () => {
    setLoading(true);
    const res = await $get(`/api/token/list/${pageNo}/${pageLimit}`);
    if (res.status === 200) {
      setTokenList(res.data.tokens);
      setTotalPage(Math.ceil(res.data.total_count / pageLimit));
      paging();
    } else {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "사용자 조회 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
    setLoading(false);
  };

  const changePagination = async () => {
    const res = await $get(`/api/token/month/${pageNo}/${pageLimit}`);
    if (res.status === 200) {
      setTokenList(res.data.tokens);
      setTotalPage(Math.ceil(res.data.total_count / pageLimit));
      paging();
    }
  };

  useEffect(() => {
    paging();
  }, [totalPage]);

  useEffect(() => {
    changePagination(1);
  }, [pageNo]);

  useEffect(() => {
    // checkLogin();
    // getTokenList();
  }, []);

  return (
    <main className="container">
      <div className="content">
        <div className="content-body align-items-start">
          <div
            className="flex justify-content-between align-items-center mb-10"
            style={{ width: "100%" }}
          >
            <div className="sub-title font-nanumMyeongjo">토큰 사용량</div>
          </div>
          <div className="table-responsive">
            <table className="table table-hover table-nowrap">
              <thead>
                <tr>
                  <th>년월</th>
                  <th>토큰 인풋</th>
                  <th>토큰 아웃풋</th>
                  <th>토큰 총합계</th>
                </tr>
              </thead>
              <tbody>
                {tokenList &&
                  tokenList.map((token, idx) => (
                    <tr key={`token-${idx}`}>
                      <td>{token.date}</td>
                      <td>{token.prompt_tokens}</td>
                      <td>{token.completion_tokens}</td>
                      <td>{token.total_tokens}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {totalPage > 1 && (
              <div className="d-flex justify-content-center mt-10">
                <nav aria-label="Page navigation example">
                  <ul className="pagination">
                    {pageNo > 5 && (
                      <li className="page-item">
                        <a
                          className="page-link"
                          onClick={(e) => setPageNo(pageNo - 1)}
                        >
                          <i className="fa-solid fa-arrow-left"></i>
                        </a>
                      </li>
                    )}

                    {pageRange.map((page) => (
                      <li key={page}>
                        <a
                          className={
                            pageNo === page ? "page-link active" : "page-link"
                          }
                          onClick={(e) => setPageNo(page)}
                        >
                          {page}
                        </a>
                      </li>
                    ))}

                    {lastPage < totalPage && (
                      <li className="page-item">
                        <a
                          className="page-link"
                          onClick={(e) => setPageNo(pageNo + 1)}
                        >
                          <i className="fa-solid fa-arrow-right"></i>
                        </a>
                      </li>
                    )}
                  </ul>
                </nav>
              </div>
            )}
          </div>
        </div>
      </div>

      {loading && <Loading />}
    </main>
  );
}

export default TokenUsage;
