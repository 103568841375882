import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../login/AuthProvider";
import { $put, $get, $dateTimezone } from "../utils/common";
import Swal from "sweetalert2";
import Loading from "../component/Loading";

function QuestionAnswer() {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const navigate = useNavigate();

  const { isLoggedIn, userData, checkLogin } = useAuth();

  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { questionId } = location.state || { questionId: null };
  const [question, setQuestion] = useState({});

  const saveQuestion = async () => {
    if (!question.reply_content) {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "답변 내용을 입력해주세요.",
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }

    Swal.fire({
      iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
      title: "답변 저장",
      text: "답변을 저장하시겠습니까?",
      showCancelButton: true,
      confirmButtonText: "예",
      cancelButtonText: "아니오",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $put(`/api/question/reply/${questionId}`, {
          reply_yn: "Y",
          reply_content: question.reply_content,
        });

        if (res.status === 200) {
          Swal.fire({
            iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
            title: "저장되었습니다.",
            showConfirmButton: false,
            timer: 1000,
          });
          navigate("/question-admin");
        } else {
          Swal.fire({
            iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
            title: "저장 실패",
            text: res.message,
            showConfirmButton: false,
            timer: 1000,
          });
        }
      }
    });
  };

  const getQuestionDetail = async () => {
    if (!questionId) return;
    const res = await $get(`/api/question/${questionId}`);
    if (res.status === 200) {
      setQuestion(res.data);
    } else {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "팝업 조회 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  const goToList = () => {
    if (userData.role_level === 0) {
      navigate("/question-admin");
    } else {
      navigate("/question");
    }
  };

  useEffect(() => {
    getQuestionDetail();
  }, []);

  return (
    <main className="container">
      <div className="content">
        <div className="content-body align-items-start">
          <div className="form-group">
            <label className="form-label">문의 제목</label>
            <input
              type="text"
              className="form-control"
              value={question.title}
              onChange={(e) =>
                setQuestion({ ...question, title: e.target.value })
              }
              disabled={true}
            />
          </div>
          <div className="form-group">
            <label className="form-label">문의 내용</label>
            <textarea
              className="form-control"
              rows="10"
              value={question.content}
              onChange={(e) =>
                setQuestion({ ...question, content: e.target.value })
              }
              disabled={true}
            />
          </div>
          {question.reply_yn === "Y" && (
            <div className="form-group">
              <label className="form-label">답변 일시</label>
              <input
                type="text"
                className="form-control"
                value={$dateTimezone(question.reply_datetime)}
                disabled={true}
              />
            </div>
          )}
          <div className="form-group">
            <label className="form-label">답변 내용</label>
            <textarea
              className="form-control"
              rows="10"
              value={question.reply_content}
              onChange={(e) =>
                setQuestion({ ...question, reply_content: e.target.value })
              }
              disabled={userData && userData.role_level === 0 ? false : true}
            />
          </div>

          <div className="flex justify-content-center">
            <button
              className="btn btn-default mr-10"
              onClick={() => goToList()}
            >
              목록
            </button>
            {userData && userData.role_level === 0 && (
              <button
                className="btn btn-primary"
                onClick={() => saveQuestion()}
              >
                저장
              </button>
            )}
          </div>
        </div>
      </div>

      {loading && <Loading />}
    </main>
  );
}

export default QuestionAnswer;
