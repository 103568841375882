import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// import { $get, $post, $put, $delete, $upload } from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import { $post, $put, $delete, $get, $dateTimezone } from "../utils/common";
// import WorkspaceData from "./Workspace.json";
import Swal from "sweetalert2";
import ShareModal from "../component/ShareModal";
import Loading from "../component/Loading";

function NovelList() {
  const navigate = useNavigate();

  const { isLoggedIn, userData, checkLogin } = useAuth();

  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [paletteModalOpen, setPaletteModalOpen] = useState(false);
  const [sortKey, setSortKey] = useState("title");
  const [sortOrder, setSortOrder] = useState("asc");
  const [productList, setProductList] = useState([]);
  const [sharedProduct, setSharedProduct] = useState({});
  const [searchText, setSearchText] = useState("");
  // 한달전 날짜
  const beforeMonth = new Date();
  beforeMonth.setMonth(beforeMonth.getMonth() - 12);
  beforeMonth.setDate(beforeMonth.getDate() + 1);

  const [searchFromDate, setSearchFromDate] = useState(
    beforeMonth.toISOString().slice(0, 10)
  );
  // 오늘 날짜
  const [searchToDate, setSearchToDate] = useState(
    `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${new Date().getDate().toString().padStart(2, "0")}`
  );
  const [pageLimit, setPageLimit] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageRange, setPageRange] = useState([]);
  const [lastPage, setLastPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const scrollRef = useRef(null);

  const goToProduct = (id, step) => () => {
    if (step === 6) {
      navigate("/novel", { state: { id: id } });
    } else {
      navigate("/novel-register", {
        state: { id: id, step: step },
      });
    }
  };

  const paging = () => {
    const pages = [];

    const pageFirst =
      pageNo % 5 === 0 ? pageNo - 4 : Math.floor(pageNo / 5) * 5 + 1;
    let pageLast = pageFirst + 4;

    if (pageLast > totalPage) pageLast = totalPage;
    setLastPage(pageLast);

    for (let i = pageFirst; i <= pageLast; i++) {
      pages.push(i);
    }
    setPageRange(pages);
  };

  const getProductList = async () => {
    setLoading(true);
    const res = await $get(
      `/api/product/list/${pageNo}/${pageLimit}?fromDate=${searchFromDate}&toDate=${searchToDate}&search=${searchText}`
    );
    if (res.status === 200) {
      setProductList(res.data.products);
      setTotalPage(Math.ceil(res.data.total_count / pageLimit));
      paging();
    } else {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "작품 조회 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
    setLoading(false);
  };

  const changePagination = async () => {
    setLoading(true);
    const res = await $get(
      `/api/product/list/${pageNo}/${pageLimit}?fromDate=${searchFromDate}&toDate=${searchToDate}&search=${searchText}`
    );
    setLoading(false);
    if (res.status === 200) {
      setProductList(res.data.products);
      setTotalPage(Math.ceil(res.data.total_count / pageLimit));
      paging();
    } else {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "작품 조회 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  const deleteProduct = async (id) => {
    Swal.fire({
      iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
      title: "작품 삭제",
      text: "작품을 삭제하시겠습니까?",
      showCancelButton: true,
      confirmButtonText: "네, 삭제합니다",
      cancelButtonText: "아니오",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $delete(`/api/product/${id}`);
        if (res.status === 200) {
          Swal.fire({
            iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
            title: "작품 삭제 성공",
            text: "작품이 삭제되었습니다.",
            showConfirmButton: false,
            timer: 1000,
          });
          getProductList();
        } else {
          Swal.fire({
            iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
            title: "작품 삭제 실패",
            text: res.message,
            showConfirmButton: false,
            timer: 1000,
          });
        }
      }
    });
  };

  const goToRegister = () => {
    navigate("/novel-register", { state: { step: 1 } });
  };

  useEffect(() => {
    paging();
  }, [totalPage]);

  useEffect(() => {
    changePagination(pageNo);
  }, [pageNo]);

  useEffect(() => {
    // checkLogin();
    // getProductList();
  }, []);

  return (
    <main className="container">
      <div className="content">
        <div className="content-body align-items-start" ref={scrollRef}>
          <div className="sub-title font-nanumMyeongjo">작품 목록</div>
          <div className="description">
            <div>
              <p>새 작품을 등록하여 집필을 시작하세요.</p>
              <p>
                작품은 혼자 집필할 수도 있고, 협업자와 함께 집필할 수도
                있습니다.
              </p>
            </div>
          </div>
          <div className="search-box mb-10 mt-20">
            <div className="input-group-sm">
              <input
                type="date"
                className="form-control"
                value={searchFromDate}
                onChange={(e) => setSearchFromDate(e.target.value)}
              />
              ~
              <input
                type="date"
                className="form-control"
                value={searchToDate}
                onChange={(e) => setSearchToDate(e.target.value)}
              />
              <input
                type="text"
                className="form-control"
                placeholder="작품명을 입력하세요."
                style={{ width: `240px` }}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => {
                  e.key === "Enter" && getProductList();
                }}
              />
              <button
                className="btn btn-transparent border-none btn-sm"
                style={{ position: "relative", left: "-60px" }}
                type="button"
                onClick={() => getProductList()}
              >
                <i
                  className="fa-solid fa-search"
                  style={{ color: "var(--bread-primary-main)" }}
                ></i>
              </button>
            </div>
            <button
              className="btn btn-main"
              type="button"
              onClick={() => goToRegister()}
            >
              <span>
                새 작품
                <br />
                등록하기
              </span>{" "}
              <i className="fa-solid fa-file-circle-plus"></i>
            </button>
          </div>
          <div className="table-responsive">
            <table className="table table-hover table-nowrap">
              <thead>
                <tr>
                  <th>작품명</th>
                  <th width="140px">카테고리</th>
                  <th width="90px">회차수</th>
                  <th width="90px">작품상태</th>
                  <th width="90px">역할</th>
                  <th width="160px">마지막 변경일시</th>
                  <th width="140px" style={{ textAlign: "center" }}>
                    관리
                  </th>
                </tr>
              </thead>
              <tbody>
                {productList &&
                  productList.map((product) => (
                    <tr key={product.id}>
                      <td>
                        <a onClick={goToProduct(product.id, product.step)}>
                          <b>{product.title}</b>
                        </a>
                      </td>
                      <td>{product.category_title}</td>
                      <td style={{ textAlign: "center" }}>
                        {product.total_episode}
                      </td>
                      <td>{product.complete_yn === "N" ? "집필중" : "완료"}</td>
                      <td>{product.role === "O" ? "메인작가" : "협업자"}</td>
                      <td>{$dateTimezone(product.modify_datetime)}</td>
                      <td>
                        {product.role === "O" && (
                          <div className="btn-group justify-content-center">
                            <button
                              className="btn btn-transparent border-none btn-xsm tooltip"
                              onClick={(e) => {
                                setSharedProduct(product);
                                setShareModalOpen(true);
                              }}
                            >
                              <i className="fa-solid fa-user-plus"></i>
                              <div className="tooltiptext">
                                보조작가 초대하기
                              </div>
                            </button>
                            <button
                              className="btn btn-transparent border-none btn-xsm"
                              onClick={() => deleteProduct(product.id)}
                            >
                              <i className="fa-solid fa-trash-can"></i>
                            </button>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {totalPage > 1 && (
              <div className="d-flex justify-content-center mt-10">
                <nav aria-label="Page navigation example">
                  <ul className="pagination">
                    {pageNo > 5 && (
                      <li className="page-item">
                        <a
                          className="page-link"
                          onClick={(e) => setPageNo(pageNo - 1)}
                        >
                          <i className="fa-solid fa-arrow-left"></i>
                        </a>
                      </li>
                    )}

                    {pageRange.map((page) => (
                      <li key={page}>
                        <a
                          className={
                            pageNo === page ? "page-link active" : "page-link"
                          }
                          onClick={(e) => setPageNo(page)}
                        >
                          {page}
                        </a>
                      </li>
                    ))}

                    {lastPage < totalPage && (
                      <li className="page-item">
                        <a
                          className="page-link"
                          onClick={(e) => setPageNo(pageNo + 1)}
                        >
                          <i className="fa-solid fa-arrow-right"></i>
                        </a>
                      </li>
                    )}
                  </ul>
                </nav>
              </div>
            )}
          </div>
        </div>
      </div>

      {loading && <Loading message="작품 목록을 조회하는 중입니다..." />}

      {shareModalOpen && (
        <ShareModal onModal={setShareModalOpen} product={sharedProduct} />
      )}
    </main>
  );
}

export default NovelList;
