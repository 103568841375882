import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";

function OrderFail() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    Swal.fire({
      title: "결제 실패",
      text: "결제에 실패하였습니다.",
      iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
      confirmButtonText: "확인",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      stopKeydownPropagation: false,
      showConfirmButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/payment");
      }
    });
  }, []);

  return <div className="result wrapper"></div>;
}

export default OrderFail;
