import React, { useState, useEffect } from "react";
import { $get, $post, $delete } from "../utils/common";
import Swal from "sweetalert2";
import { useAuth } from "../login/AuthProvider";

function ShareModal(props) {
  const { userData } = useAuth();
  const [sharedUsers, setSharedUsers] = useState([]);
  const [addUsers, setAddUsers] = useState([]);
  const [sharedEmail, setSharedEmail] = useState("");
  const copyShareLink = () => {
    const url = `${window.location.origin}/share/${props.product.id}`;
    navigator.clipboard.writeText(url);
    Swal.fire({
      iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
      title: "링크가 복사되었습니다.",
      showConfirmButton: false,
      timer: 1000,
    });
  };
  const saveShare = async () => {
    if (addUsers.length === 0) {
      props.onModal(false);
      return;
    }

    const promises = addUsers.map((user) =>
      $post(`/api/product/subauthor/${props.product.id}`, {
        email: user,
      })
    );
    const res = await Promise.all(promises);
    console.log(res);

    Swal.fire({
      iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
      title: "공유가 완료되었습니다.",
      showConfirmButton: false,
      timer: 1000,
    });

    props.onModal(false);
    setAddUsers([]);
  };

  const addShare = async (e) => {
    e.preventDefault();
    if (e.nativeEvent.isComposing) {
      return;
    }

    // TODO: 이메일 형식 체크
    if (sharedEmail === "") {
      return;
    }

    const regExpEmail =
      /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,}$/i;
    if (!regExpEmail.test(sharedEmail)) {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "이메일 형식이 올바르지 않습니다.",
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }

    if (
      addUsers.find((user) => user.email === sharedEmail) ||
      sharedUsers.find((user) => user.email === sharedEmail) ||
      props.product.email === sharedEmail
    ) {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "이미 추가된 사용자입니다.",
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }

    const res = await $post(`/api/product/subauthor/${props.product.id}`, {
      email: sharedEmail,
    });

    if (res.status === 200) {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "공유가 완료되었습니다.",
        showConfirmButton: false,
        timer: 1000,
      });

      setSharedEmail("");
      getSharedUsers();
    } else {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "공유에 실패했습니다.",
        showConfirmButton: false,
        timer: 1000,
      });
    }
    // setAddUsers([...addUsers, sharedEmail]);
  };

  const removeShare = (email) => {
    setAddUsers(addUsers.filter((userEmail) => userEmail !== email));
  };

  const removeSubauthor = async (email) => {
    Swal.fire({
      title: "보조작가와 공유를 해제하시겠습니까?",
      iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
      showCancelButton: true,
      confirmButtonText: "해제",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $delete(
          `/api/product/subauthor/${props.product.id}/${email}`
        );
        if (res.status === 401) {
          Swal.fire({
            iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
            title: "권한이 없습니다.",
            showConfirmButton: false,
            timer: 1000,
          });
          return;
        }

        getSharedUsers();
        if (res.data.affectedRows > 0) {
          Swal.fire({
            iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
            title: `${email} 보조 작가가와 공유가 해제되었습니다.`,
            showConfirmButton: false,
            timer: 1000,
          });
        } else {
          Swal.fire({
            iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
            title: "공유 해제에 실패했습니다.",
            showConfirmButton: false,
            timer: 1000,
          });
        }
      }
    });
  };

  const getSharedUsers = async () => {
    const res = await $get(`/api/product/subauthor/${props.product.id}`);
    setSharedUsers(res.data);
  };

  useEffect(() => {
    getSharedUsers();
  }, []);

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="modal-header">
          <span className="close" onClick={() => props.onModal(false)}>
            &times;
          </span>
          <h3 className="font-nanumMyeongjo">협업자 초대하기</h3>
          <p>"{props.product.title}"를 공유합니다.</p>
        </div>
        <div className="modal-body">
          <div className="mb-3">
            <input
              type="search"
              className="form-control form-control-lg"
              placeholder="이메일을 쓰고 엔터를 치세요.(예, writer@b-writer.kr)"
              value={sharedEmail}
              onChange={(e) => setSharedEmail(e.target.value)}
              onKeyDown={(e) => {
                e.key === "Enter" && addShare(e);
              }}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">권한이 있는 사용자</label>
            <table className="table table-borderless table-hover">
              <tbody>
                <tr>
                  <td>
                    <div className="access-user-info">
                      {/* <div className="user-name">{props.product.nickname}</div> */}
                      <div className="user-email">{props.product.email}</div>
                    </div>
                  </td>
                  <td>
                    <div className="access-user-info">
                      <div className="user-role">소유자</div>
                    </div>
                  </td>
                </tr>
                {sharedUsers.map((sharedUser, index) => (
                  <tr key={index}>
                    <td>
                      <div className="access-user-info">
                        <div className="user-email">{sharedUser.email}</div>
                      </div>
                    </td>
                    <td>
                      <div className="access-user-info">
                        <div className="user-role">보조 작가</div>
                      </div>
                    </td>
                    <td>
                      <div className="access-user-info">
                        <div className="user-role">
                          <button
                            className="btn btn-outline-danger btn-sm"
                            onClick={() => removeSubauthor(sharedUser.email)}
                          >
                            <i className="fa-solid fa-trash"></i>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="mb-3">
            {addUsers.length > 0 && (
              <>
                <label className="form-label">공유 추가 사용자</label>
                <table className="table table-borderless table-hover">
                  <tbody>
                    {addUsers.map((email, index) => (
                      <tr key={index}>
                        <td>
                          <div className="access-user-info">
                            <div className="user-email">{email}</div>
                          </div>
                        </td>
                        <td>
                          <div className="access-user-info">
                            <div className="user-role">
                              <button
                                className="btn btn-outline-danger btn-sm"
                                onClick={() => removeShare(email)}
                              >
                                <i className="fa-solid fa-trash"></i>
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )}
          </div>
        </div>
        <div className="modal-footer flex justify-content-between">
          <button className="btn btn-default" onClick={() => copyShareLink()}>
            <i className="fa-solid fa-link"></i> 링크 복사
          </button>
          <button
            className="btn btn-primary"
            onClick={() => props.onModal(false)}
          >
            완료
          </button>
        </div>
      </div>
    </div>
  );
}

export default ShareModal;
