import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// import { $get, $post, $put, $delete, $upload } from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import { $post, $put, $delete, $get, $dateTimezone } from "../utils/common";
// import WorkspaceData from "./Workspace.json";
import Swal from "sweetalert2";
import ShareModal from "../component/ShareModal";
import Loading from "../component/Loading";

function FaqAdmin() {
  const navigate = useNavigate();

  const { isLoggedIn, userData, checkLogin } = useAuth();

  const [faqList, setFaqList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [faqModalOpen, setFaqModalOpen] = useState(false);
  const [activeFaq, setActiveFaq] = useState({
    title: "",
    content: "",
  });

  const location = useLocation();
  const scrollRef = useRef(null);

  const getFaqList = async () => {
    setLoading(true);
    const res = await $get(`/api/faq/all`);
    if (res.status === 200) {
      setFaqList(res.data);
    } else {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "FAQ 조회 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
    setLoading(false);
  };

  const deleteFaq = async (id) => {
    Swal.fire({
      iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
      title: "FAQ 삭제",
      text: "FAQ을 삭제하시겠습니까?",
      showCancelButton: true,
      confirmButtonText: "예",
      cancelButtonText: "아니오",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $delete(`/api/faq/${id}`);
        if (res.status === 200) {
          Swal.fire({
            iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
            title: "FAQ 삭제 성공",
            text: "FAQ가 삭제되었습니다.",
            showConfirmButton: false,
            timer: 1000,
          });
          getFaqList();
        } else {
          Swal.fire({
            iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
            title: "FAQ 삭제 실패",
            text: res.message,
            showConfirmButton: false,
            timer: 1000,
          });
        }
      }
    });
  };

  const goToFaqRegistration = (faqId) => {
    if (faqId) {
      navigate(`/faq-register`, { state: { faqId } });
    } else {
      navigate("/faq-register");
    }
  };

  // useEffect(() => {
  //   if (userData && userData.role_level !== 0) {
  //     navigate("/401");
  //   }
  // }, [userData]);

  useEffect(() => {
    getFaqList();
  }, []);

  return (
    <main className="container">
      <div className="content">
        <div className="content-body align-items-start" ref={scrollRef}>
          <div
            className="flex justify-content-between align-items-center mb-10"
            style={{ width: "100%" }}
          >
            <div className="sub-title font-nanumMyeongjo">FAQ</div>
            {userData && userData.role_level === 0 && (
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => goToFaqRegistration()}
              >
                <i className="fa-regular fa-plust"></i> FAQ 추가
              </button>
            )}
          </div>

          <div className="table-responsive">
            <table className="table table-hover table-nowrap">
              <thead>
                <tr>
                  <th>FAQ 제목</th>
                  <th width="90px">분류</th>
                  <th width="90px">공개여부</th>
                  <th width="200px">마지막 변경일시</th>
                  <th width="140px">관리</th>
                </tr>
              </thead>
              <tbody>
                {faqList &&
                  faqList.map((faq) => (
                    <tr key={faq.id}>
                      <td>
                        <a
                          className="white-space-normal"
                          onClick={() => goToFaqRegistration(faq.id)}
                        >
                          {faq.title}
                        </a>
                      </td>
                      <td>{faq.category_title}</td>
                      {userData.role_level === 0 && (
                        <td>{faq.active_yn === "Y" ? "공개" : "비공개"}</td>
                      )}

                      <td>{$dateTimezone(faq.modify_datetime)}</td>

                      <td>
                        <button
                          className="btn btn-transparent btn-sm mr-5"
                          onClick={() => goToFaqRegistration(faq.id)}
                        >
                          <i className="fa-solid fa-edit"></i>
                        </button>
                        <button
                          className="btn btn-transparent btn-sm"
                          onClick={() => deleteFaq(faq.id)}
                        >
                          <i className="fa-solid fa-trash-can"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {loading && <Loading />}
    </main>
  );
}

export default FaqAdmin;
