import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../login/AuthProvider";
import {
  $get,
  $put,
  $upload,
  $delete,
  $dateFormat,
  $convertNumberFormat,
} from "../utils/common";
import Swal from "sweetalert2";
import Loading from "../component/Loading";
import PointUsageHistoryModal from "../component/PointUsageHistoryModal";

function Profile() {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const navigate = useNavigate();
  const { userData, setUserData } = useAuth();
  const [productCount, setProductCount] = useState(0);
  const [nickname, setNickname] = useState(userData.nickname);
  const [userName, setUserName] = useState(userData.name);
  const [pageLimit, setPageLimit] = useState(5);
  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageRange, setPageRange] = useState([]);
  const [lastPage, setLastPage] = useState(1);

  const [pageLimit2, setPageLimit2] = useState(10);
  const [pageNo2, setPageNo2] = useState(1);
  const [totalPage2, setTotalPage2] = useState(1);
  const [pageRange2, setPageRange2] = useState([]);
  const [lastPage2, setLastPage2] = useState(1);
  const [loading, setLoading] = useState(false);
  const [paymentList, setPaymentList] = useState([]);
  const [pointUsageList, setPointUsageList] = useState([]);
  const [userPoints, setUserPoints] = useState(0);
  const [showPointUsageHistoryModal, setShowPointUsageHistoryModal] =
    useState(false);
  const [activeDate, setActiveDate] = useState("");

  const fileRef = useRef(null);

  const paging = () => {
    const pages = [];

    const pageFirst =
      pageNo % 5 === 0 ? pageNo - 4 : Math.floor(pageNo / 5) * 5 + 1;
    let pageLast = pageFirst + 4;

    if (pageLast > totalPage) pageLast = totalPage;
    setLastPage(pageLast);

    for (let i = pageFirst; i <= pageLast; i++) {
      pages.push(i);
    }
    setPageRange(pages);
  };

  const paging2 = () => {
    const pages = [];

    const pageFirst =
      pageNo2 % 5 === 0 ? pageNo2 - 4 : Math.floor(pageNo2 / 5) * 5 + 1;
    let pageLast = pageFirst + 4;

    if (pageLast > totalPage2) pageLast = totalPage2;
    setLastPage2(pageLast);

    for (let i = pageFirst; i <= pageLast; i++) {
      pages.push(i);
    }
    setPageRange2(pages);
  };

  const uploadImage = async (file) => {
    const res = await $upload("/api/upload/image", file);
    if (res.status === 200) {
      const res2 = await $put(`/api/user/${userData.id}`, {
        profile_image_url: `/static/images/${res.data.filename}`,
      });

      if (res2.status === 200) {
        setUserData({
          ...userData,
          profile_image_url: `/static/images/${res.data.filename}`,
        });
      }
    } else {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "이미지 업로드 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  const getProductCount = async () => {
    const res = await $get(`/api/product/count`);
    if (res.status === 200) {
      setProductCount(res.data.count);
    }
  };

  const saveProfile = async () => {
    const res = await $put(`/api/user/${userData.id}`, {
      nickname,
      name: userName,
    });
    if (res.status === 200) {
      setUserData({ ...userData, nickname, name: userName });
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "저장 성공",
        text: "저장했습니다.",
        showConfirmButton: false,
        timer: 1000,
      });
    } else {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "이름 저장 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  const withdrawAccount = async () => {
    Swal.fire({
      iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
      title: "정말 회원탈퇴 하시겠습니까?",
      text: "회원탈퇴를 하면 개인 정보 및 작업한 모든 데이터가 영구히 삭제됩니다.",
      showCancelButton: true,
      confirmButtonText: "확인",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $delete(`/api/user/withdraw/${userData.email}`);
        if (res.status === 200) {
          Swal.fire({
            iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
            title: "회원탈퇴 성공",
            text: "회원탈퇴 되었습니다.",
            showConfirmButton: false,
            timer: 1000,
          });
          // setUserData(null);
          navigate("/");
        } else {
          Swal.fire({
            iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
            title: "회원탈퇴 실패",
            text: res.message,
            showConfirmButton: false,
            timer: 1000,
          });
        }
      }
    });
  };

  const getUserPoints = async () => {
    const res = await $get("/api/user/points");
    if (res.status === 200) {
      setUserPoints(res.data.points);
    }
  };

  const changePaginationPayment = async () => {
    const res = await $get(`/api/payment/user/${pageNo}/${pageLimit}`);
    if (res.status === 200) {
      setPaymentList(res.data.list);
      setTotalPage(Math.ceil(res.data.total_count / pageLimit));
      paging();
    }
  };

  const changePaginationPointUsage = async () => {
    const res = await $get(`/api/point/user/${pageNo}/${pageLimit}`);
    if (res.status === 200) {
      setPointUsageList(res.data.points);
      setTotalPage2(Math.ceil(res.data.total_count / pageLimit));
      paging2();
    }
  };

  useEffect(() => {
    paging();
  }, [totalPage]);

  useEffect(() => {
    changePaginationPayment(pageNo);
  }, [pageNo]);

  useEffect(() => {
    paging2();
  }, [totalPage2]);

  useEffect(() => {
    changePaginationPointUsage(pageNo2);
  }, [pageNo2]);

  useEffect(() => {
    getUserPoints();
  }, []);

  return (
    <main className="container">
      {userData && (
        <div className="content">
          <div className="content-body align-items-start">
            <div
              className="flex justify-content-between align-items-center mb-20"
              style={{ width: "100%" }}
            >
              <div className="sub-title font-nanumMyeongjo">내 정보</div>
            </div>
            <div className="form-group">
              <label className="form-label">닉네임</label>
              <input type="text" className="form-control" value={nickname} />
            </div>
            <div className="form-group">
              <label className="form-label">이름</label>
              <input type="text" className="form-control" value={userName} />
            </div>
            <div className="form-group">
              <label className="form-label">이메일</label>
              <input
                type="text"
                className="form-control"
                value={userData.email}
                disabled={true}
              />
            </div>
            <div className="form-group">
              <label className="form-label">잼(포인트)</label>
              <input
                type="text"
                className="form-control mr-10"
                value={userPoints}
                disabled={true}
              />
              <button
                type="button"
                className="btn btn-main"
                onClick={() => navigate("/payment")}
              >
                잼 구매하러가기
              </button>
            </div>
            <div className="form-group">
              <label className="form-label">프로필 이미지</label>
              <img
                className="user-avatar"
                src={
                  userData.profile_image_url.startsWith("/static/images")
                    ? `${serverUrl}${userData.profile_image_url}`
                    : userData.profile_image_url !== null &&
                      userData.profile_image_url !== ""
                    ? userData.profile_image_url
                    : "./assets/images/default-profile.jpg"
                }
                alt="avatar"
                onClick={() => fileRef.current.click()}
              />
              <input
                type="file"
                accept="image/png, image/jpeg"
                ref={fileRef}
                style={{ display: "none" }}
                onChange={(e) => uploadImage(e.target.files[0])}
              />
            </div>
            <div className="flex justify-content-center">
              <button
                type="button"
                className="btn btn-primary btn-sm mr-5"
                onClick={() => saveProfile()}
              >
                저장
              </button>
              {/* <button
                type="button"
                className="btn btn-danger btn-sm"
                onClick={(e) => withdrawAccount()}
              >
                회원탈퇴
              </button> */}
            </div>

            <div
              className="flex justify-content-between align-items-center mb-10 mt-40"
              style={{ width: "100%" }}
            >
              <div className="sub-title font-nanumMyeongjo">결제 내역</div>
            </div>
            <div className="table-responsive">
              <table className="table table-hover table-nowrap">
                <thead>
                  <tr>
                    <th>결제일</th>
                    <th>주문명</th>
                    <th>결제금액</th>
                  </tr>
                </thead>
                <tbody>
                  {paymentList &&
                    paymentList.map((payment, idx) => (
                      <tr key={`payment-${idx}`}>
                        <td>{$dateFormat(payment.approved_at)}</td>
                        <td>{payment.order_name}</td>
                        <td>{$convertNumberFormat(payment.total_amount)}원</td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {totalPage > 1 && (
                <div className="d-flex justify-content-center mt-10">
                  <nav aria-label="Page navigation example">
                    <ul className="pagination">
                      {pageNo > 5 && (
                        <li className="page-item">
                          <a
                            className="page-link"
                            onClick={(e) => setPageNo(pageNo - 1)}
                          >
                            <i className="fa-solid fa-arrow-left"></i>
                          </a>
                        </li>
                      )}

                      {pageRange.map((page) => (
                        <li key={page}>
                          <a
                            className={
                              pageNo === page ? "page-link active" : "page-link"
                            }
                            onClick={(e) => setPageNo(page)}
                          >
                            {page}
                          </a>
                        </li>
                      ))}

                      {lastPage < totalPage && (
                        <li className="page-item">
                          <a
                            className="page-link"
                            onClick={(e) => setPageNo(pageNo + 1)}
                          >
                            <i className="fa-solid fa-arrow-right"></i>
                          </a>
                        </li>
                      )}
                    </ul>
                  </nav>
                </div>
              )}
            </div>

            <div
              className="flex justify-content-between align-items-center mb-10 mt-40"
              style={{ width: "100%" }}
            >
              <div className="sub-title font-nanumMyeongjo">사용 내역</div>
            </div>
            <div className="table-responsive">
              <table className="table table-hover table-nowrap">
                <thead>
                  <tr>
                    <th>년월</th>
                    <th>총 사용 잼</th>
                    <th>총 사용 수</th>
                  </tr>
                </thead>
                <tbody>
                  {pointUsageList &&
                    pointUsageList.map((pointUsage, idx) => (
                      <tr key={`pointUsage-${idx}`}>
                        <td>{pointUsage.date}</td>
                        <td>{$convertNumberFormat(pointUsage.point)}</td>
                        <td>
                          <a
                            onClick={() => {
                              setActiveDate(pointUsage.date);
                              setShowPointUsageHistoryModal(true);
                            }}
                            style={{ textDecoration: "underline" }}
                          >
                            {$convertNumberFormat(pointUsage.count)}
                          </a>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {totalPage > 1 && (
                <div className="d-flex justify-content-center mt-10">
                  <nav aria-label="Page navigation example">
                    <ul className="pagination">
                      {pageNo2 > 5 && (
                        <li className="page-item">
                          <a
                            className="page-link"
                            onClick={(e) => setPageNo2(pageNo2 - 1)}
                          >
                            <i className="fa-solid fa-arrow-left"></i>
                          </a>
                        </li>
                      )}

                      {pageRange2.map((page) => (
                        <li key={page}>
                          <a
                            className={
                              pageNo2 === page
                                ? "page-link active"
                                : "page-link"
                            }
                            onClick={(e) => setPageNo2(page)}
                          >
                            {page}
                          </a>
                        </li>
                      ))}

                      {lastPage2 < totalPage2 && (
                        <li className="page-item">
                          <a
                            className="page-link"
                            onClick={(e) => setPageNo2(pageNo2 + 1)}
                          >
                            <i className="fa-solid fa-arrow-right"></i>
                          </a>
                        </li>
                      )}
                    </ul>
                  </nav>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {showPointUsageHistoryModal && (
        <PointUsageHistoryModal
          activeDate={activeDate}
          roleLevel={userData.role_level}
          userEmail={userData.email}
          onModal={() => setShowPointUsageHistoryModal(false)}
        />
      )}

      {loading && <Loading />}
    </main>
  );
}

export default Profile;
