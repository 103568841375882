import { useNavigate, useLocation } from "react-router-dom";
import { $put } from "../utils/common";
import Swal from "sweetalert2";

function NovelStartGuide(props) {
  const navigate = useNavigate();
  const appUrl = process.env.REACT_APP_URL;

  const goToNovel = async () => {
    const res = await $put(`/api/product/${props.productId}`, {
      step: 6,
    });

    if (res.status === 200) {
      navigate("/novel", { state: { id: props.productId } });
    } else {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "작품 생성 실패",
        text: "작품을 생성할 수 없습니다.",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  return (
    <div className="register-box">
      <div className="sub-title font-nanumMyeongjo">원고 집필 시작하기</div>
      <div className="description">
        <div>
          <p>모든 에피소드 구조 설정이 완료되었습니다.</p>
          <p>이제 각 에피소드의 원고를 직접 집필해보세요.</p>
        </div>
      </div>
      <div className="episode-guide">
        <ul>
          <li>원고는 전문 에디터를 활용하여 집필하게 됩니다.</li>
          <li>각 에피소드의 줄거리를 AI를 통해 추천받을 수 있습니다.</li>
          <li>
            작품은 협업자와 함께 공동 작업
            <span style={{ fontWeight: "normal" }}>
              (실시간 공동 집필 아님)
            </span>
            을 할 수 있습니다. 협업자를 초대해보세요.
          </li>
          <li>
            원고를 작성하며 ‘오른쪽 마우스 클릭’을 하면 각 문장에 대한 ‘메모’를
            남기거나, ‘AI 문장 바꿔쓰기’ 기능을 사용할 수 있어요.
          </li>
          <li>
            200자 이상의 원고를 집필하다가 막히면 ‘단락 이어쓰기’ 기능을 사용할
            수 있어요.
          </li>
        </ul>
      </div>
      <div className="button-list mt-30">
        <button
          type="button"
          className="btn btn-primary mr-10"
          onClick={() => goToNovel()}
        >
          1회차 원고 집필하기
        </button>

        <button
          type="button"
          className="btn btn-default"
          onClick={() => props.onNext(-1)}
        >
          이전으로 돌아가기
        </button>
      </div>
    </div>
  );
}

export default NovelStartGuide;
