import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  $post,
  $put,
  $delete,
  $get,
  $dateTimezone,
  $convertNumberFormat,
} from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import SystemPopupModal from "../component/SystemPopupModal";
import Swal from "sweetalert2";

function Story({ onRegisterButtonClick }) {
  const navigate = useNavigate();
  const { isLoggedIn, userData, login, logout } = useAuth();
  const [popupModalOpen, setPopupModalOpen] = useState(false);
  const [activePopup, setActivePopup] = useState({});
  const [totalActiveUserCount, setTotalActiveUserCount] = useState(0);
  const [totalProductCount, setTotalProductCount] = useState(0);
  const [totalEpisodeCount, setTotalEpisodeCount] = useState(0);

  const slickSettings = {
    fade: true,
    infinite: true,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    // initialSlide: 1,
    // centerMode: true,
  };

  const getSystemModalList = async () => {
    const currentDateTime = new Date()
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    const res = await $get(`/api/popup/now/${currentDateTime}`);
    if (res.status === 200) {
      if (res.data.length > 0) {
        const localStorageKey = `popup-${res.data[0].id}`;
        const localStorageValue = localStorage.getItem(localStorageKey);
        if (localStorageValue === "false") return;
        setActivePopup(res.data[0]);
        setPopupModalOpen(true);
      }
    }
  };

  const getStatus = async () => {
    const res = await $get(`/api/bwriter-status`);
    if (res.status === 200) {
      setTotalActiveUserCount(res.data.userCount);
      setTotalProductCount(res.data.productCount);
      setTotalEpisodeCount(res.data.episodeCount);
    }
  };

  const mobileCheck = () => {
    const mobileCheck =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );

    // 해상도 모바일 체크
    if (mobileCheck || window.innerWidth < 768) {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "모바일 사용 불가",
        text: "모바일에서는 사용이 불가합니다. PC에서 사용하세요.",
        showConfirmButton: true,
      });
      return true;
    } else {
      return false;
    }
  };

  const moveToNovelList = () => {
    if (mobileCheck()) return;

    if (userData && userData.email) {
      navigate("/novel-list");
    } else {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "로그인이 필요합니다.",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  useEffect(() => {
    getStatus();
    getSystemModalList();
  }, []);
  return (
    // <div className="home"></div>
    <main
      className="container full2"
      style={{ backgroundColor: "var(--bread-primary-main2)" }}
    >
      <div className="container">
        <div className="content">
          <div className="story-title font-nanumMyeongjo mb-20">
            <p>누구나 이야기를</p>
            <p>풀어내는 시대</p>
          </div>
          <div className="story-subtitle mb-20">
            <p>세상에는 다양한 콘텐츠가 넘쳐나지만,</p>
            <p>가장 기본은 글입니다.</p>
            <p>이야기가 담긴 글은 드라마, 영화, 만화 등으로 확장하며</p>
            <p>그 중요성을 입증하고 있습니다.</p>
          </div>
          <div className="story-subtitle mb-20">
            <p>비·라이터는</p>
            <p>누구나 쉽게 자신의 이야기를 글로</p>
            <p>쉽게 풀어낼 수 있도록 돕는 영리한 도구입니다.</p>
            <p>글쓰기의 기본 소양은 여전히 중요하지만,</p>
            <p>기술을 통해 창작 과정의 어려움을</p>
            <p>해결할 수 있다고 믿습니다.</p>
          </div>
          <div className="story-subtitle mb-20">
            <p>비·라이터는</p>
            <p>아이디어가 있는 모든 이들이</p>
            <p>그 아이디어를 포기하지 않고</p>
            <p>세상에 펼쳐내길 응원합니다.</p>
          </div>

          <div className="story-button mb-50 mt-50">
            <img
              src="./assets/images/Logo_white_simple.png"
              alt="비라이터 로고"
              style={{ width: "30px" }}
            />
            <div className="story-slogan font-nanumMyeongjo">
              <p>It’s Easy,</p>
              <p>to be a Writer</p>
            </div>

            <div className="mt-30">
              <button
                className="btn btn-lg btn-default mr-20"
                onClick={onRegisterButtonClick}
              >
                작가 가입하기
              </button>
              <button
                className="btn btn-lg btn-default"
                onClick={() => moveToNovelList()}
              >
                작품 집필하기
              </button>
            </div>
          </div>
        </div>
      </div>

      {popupModalOpen && (
        <SystemPopupModal
          popupId={activePopup.id}
          preview={false}
          onModal={setPopupModalOpen}
        />
      )}
    </main>
  );
}

export default Story;
