import React, { useState, useEffect, useRef } from "react";
import { $get } from "../utils/common";
import Swal from "sweetalert2";

function NovelSetCharacterView(props) {
  const [characters, setCharacters] = useState([]);

  const getCharacterList = async () => {
    const res = await $get(`/api/product/character/${props.productId}`);
    if (res.status === 200) {
      setCharacters(res.data);
    } else {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "등장 인물 조회 실패",
        text: res.message,
        timer: 1000,
      });
    }
  };

  useEffect(() => {
    getCharacterList();
  }, []);
  return (
    <div className="content p-30 overflow-y">
      <div className="sub-title font-nanumMyeongjo mb-30">등장 인물</div>
      <div className="table-responsive">
        <table className="table table-hover">
          <thead>
            <tr>
              <th width="100px">이름</th>
              <th width="70px">성별</th>
              <th width="70px">나이</th>
              <th width="140px">직업</th>
              <th width="140px">역할</th>
              <th width="200px">성격</th>
              <th width="*">배경</th>
            </tr>
          </thead>
          <tbody>
            {characters.map((character, i) => (
              <tr key={`character-${i}`}>
                <td>{character.name}</td>
                <td>{character.gender}</td>
                <td>{character.age}</td>
                <td>{character.job}</td>
                <td>{character.role}</td>
                <td>{character.personality}</td>
                <td>{character.background}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default NovelSetCharacterView;
