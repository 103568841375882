import React, { useState, useEffect, useRef } from "react";
import { $get, $put, $post } from "../utils/common";
import Swal from "sweetalert2";
import BeatLoader from "react-spinners/BeatLoader";

function SpellCheckerModal(props) {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const [assistantMessage, setAssistantMessage] = useState("");
  const [spellCheckResult, setSpellCheckResult] = useState([]);
  const [spellCheckData, setSpellCheckData] = useState([]);

  const [loading, setLoading] = useState(false);
  const assistantScrollRef = useRef(null);

  let bProgress = false;

  const checkSpell = async () => {
    setLoading(true);
    setAssistantMessage("");
    setSpellCheckResult([]);
    setSpellCheckData([]);

    const res = await $get(`/api/util/spell-checker-passportkey`);
    if (res.status !== 200) {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "맞춤법 검사기 오류",
        text: "맞춤법 검사기 오류가 발생했습니다. 잠시 후 다시 시도해 주세요.",
      });
      setLoading(false);
      return;
    }

    const passportKey = res.data.passportKey;

    const paragraphs = props.check;
    const tempSpellCheckData = [];
    const tempSpellCheckResult = [];
    for (let i = 0; i < paragraphs.length; i++) {
      // const queryText = encodeURIComponent(paragraphs[i]);
      const res = await $post(`/api/util/spell-checker`, {
        queryText: paragraphs[i],
        passportKey,
      });

      if (res.status === 200) {
        if (res.data.message.result.errata_count > 0) {
          // const resultTags = [
          //   `<p>${res.data.message.result.origin_html}</p><p><i class="fa-solid fa-arrow-right" style="color:red;"></i> ${res.data.message.result.html}</p>`
          // ];
          tempSpellCheckData.push({
            original: res.data.message.result.q,
            corrected: res.data.message.result.notag_html,
          });
          tempSpellCheckResult.push(
            `<p>${res.data.message.result.origin_html}</p><p><i class="fa-solid fa-arrow-right" style="color:red;"></i> ${res.data.message.result.html}</p>`
          );
          setSpellCheckResult([...tempSpellCheckResult]);
          // assistantScrollRef.current.scrollTop =
          //   assistantScrollRef.current.scrollHeight;
        }
      }
    }

    // setSpellCheckResult(resultTags);
    setSpellCheckData(tempSpellCheckData);

    setLoading(false);
  };

  const handleMouseOver = (index) => {
    if (loading) return;
    setTimeout(() => {
      props.onMouseOver(spellCheckData[index]);
    }, 500);
  };

  const handleMouseOut = (index) => {
    if (loading) return;
    setTimeout(() => {
      props.onMouseOut(spellCheckData[index]);
    }, 500);
  };

  const changeSentence = (index) => {
    // index 번째 제거
    setSpellCheckResult(spellCheckResult.filter((_, i) => i !== index));
    props.onMouseOut(index);
    props.onChangeSentence({ ...spellCheckData[index] });
    setSpellCheckData(spellCheckData.filter((_, i) => i !== index));
  };

  useEffect(() => {
    checkSpell();
  }, []);

  return (
    <div className="modal right">
      <div className="modal-content">
        <div className="modal-header">
          {!loading && (
            <span className="close" onClick={() => props.onModal(false)}>
              &times;
            </span>
          )}
          <h3>
            {"맞춤법 검사"}({spellCheckResult ? spellCheckResult.length : 0}){" "}
            {loading && <BeatLoader color="#ae7e55" />}
          </h3>
          <div>
            <i className="fa-solid fa-circle red_text"></i> {"맞춤법"}
          </div>
          <div>
            <i className="fa-solid fa-circle green_text"></i> {"띄어쓰기"}
          </div>
          <div>
            <i className="fa-solid fa-circle violet_text"></i> {"표준어의심"}
          </div>
          <div>
            <i className="fa-solid fa-circle blue_text"></i> {"통계적교정"}
          </div>
        </div>

        <div className="modal-body" ref={assistantScrollRef}>
          {spellCheckResult.map((item, index) => (
            <div
              className="spell-error"
              key={index}
              dangerouslySetInnerHTML={{ __html: item }}
              onClick={() => changeSentence(index)}
              onMouseOver={() => handleMouseOver(index)}
              onMouseOut={() => handleMouseOut(index)}
            ></div>
          ))}
          {spellCheckResult.length === 0 && !loading && (
            <div className="spell-error">
              <p>{"맞춤법 오류가 없습니다."}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SpellCheckerModal;
