import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// import { $get, $post, $put, $delete, $upload } from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import {
  $post,
  $put,
  $delete,
  $get,
  $dateTimezone,
  $dateFormat,
  $convertNumberFormat,
} from "../utils/common";
// import WorkspaceData from "./Workspace.json";
import Swal from "sweetalert2";
import Loading from "../component/Loading";
import PointModal from "../component/PointModal";

function PointAdmin() {
  const navigate = useNavigate();
  const location = useLocation();

  const { isLoggedIn, userData, checkLogin } = useAuth();
  const [loading, setLoading] = useState(false);
  const [pointPriceList, setPointPriceList] = useState([]);
  const [pointFunctionList, setPointFunctionList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [activePointFunction, setActivePointFunction] = useState({});

  const getPointPriceList = async () => {
    setLoading(true);
    const res = await $get(`/api/point/price`);
    if (res.status === 200) {
      setPointPriceList(res.data);
    }
    setLoading(false);
  };

  const getPointFunctionList = async () => {
    setLoading(true);
    const res = await $get(`/api/point/func`);
    if (res.status === 200) {
      setPointFunctionList(res.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getPointPriceList();
    getPointFunctionList();
  }, []);
  return (
    <main className="container" style={{ height: `calc(100vh - 68px)` }}>
      {userData && (
        <div className="content">
          <div className="content-body align-items-start">
            <div
              className="flex justify-content-between align-items-center mb-10"
              style={{ width: "100%" }}
            >
              <div className="sub-title font-nanumMyeongjo">
                기능별 포인트 차감 정책
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-hover table-nowrap">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>기능</th>
                    <th>차감 잼(포인트)</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {pointFunctionList &&
                    pointFunctionList.map((pointFunction, idx) => (
                      <tr key={`pointFunction-${idx}`}>
                        <td>{pointFunction.func_category}</td>
                        <td>{pointFunction.func_title}</td>
                        <td>{$convertNumberFormat(pointFunction.points)}잼</td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-danger btn-sm"
                            onClick={() => {
                              setActivePointFunction(pointFunction);
                              setShowModal(true);
                            }}
                          >
                            수정
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      {showModal && (
        <PointModal
          showModal={showModal}
          onModal={setShowModal}
          activePointFunction={activePointFunction}
          onSave={() => {
            setShowModal(false);
            getPointFunctionList();
          }}
        />
      )}

      {loading && <Loading />}
    </main>
  );
}

export default PointAdmin;
