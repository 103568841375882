import React, { useState, useEffect } from "react";
import { $get, $put, $post } from "../utils/common";
import Swal from "sweetalert2";
import BeatLoader from "react-spinners/BeatLoader";

function BreadProductModal(props) {
  const [productList, setProductList] = useState([]);
  const [existBreads, setExistBreads] = useState({});
  const [loading, setLoading] = useState(false);

  const getBreadProductList = async () => {
    setLoading(true);
    const res = await $get(`/api/bread/list`);
    if (res.status === 200) {
      setProductList(res.data);
    }
    setLoading(false);
  };

  const createBread = async () => {
    if (props.basicInfo.title === "") {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "작품명을 입력하세요.",
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }

    if (props.basicInfo.category === "") {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "카테고리를 선택하세요.",
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }

    if (props.basicInfo.keyword === "") {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "키워드를 입력하세요.",
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }

    if (props.basicInfo.plot === "") {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "개요를 입력하세요.",
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }

    setLoading(true);
    const res = await $post(`/api/bread`, {
      title: props.basicInfo.title,
      description: props.basicInfo.plot,
      genreId: props.basicInfo.category,
    });

    if (res.status === 200) {
      const breadId = res.data;

      props.onConnect({
        bread_product_id: breadId,
        title: props.basicInfo.title,
        plot: props.basicInfo.plot,
        category: props.basicInfo.category,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    getBreadProductList();
  }, []);

  return (
    <div className="modal">
      <div className="modal-content modal-lg">
        <div className="modal-header">
          {!loading && (
            <span className="close" onClick={() => props.onModal(false)}>
              &times;
            </span>
          )}
          <h3>문의하기</h3>
        </div>
        <div className="modal-body">
          <table className="table table-hover table-nowrap">
            <thead>
              <tr>
                <th scope="col">작품명</th>
                {/* <th scope="col">작품설명</th> */}
                <th scope="col">장르</th>
                <th scope="col">에피소드수</th>
                <th scope="col">작품생성일</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {productList.map((product, index) => (
                <tr
                  key={product.id}
                  className={product.id === props.breadId ? "active" : ""}
                >
                  <td>{product.title}</td>
                  {/* <td>{product.description}</td> */}
                  <td>{product.genre.description}</td>
                  <td>{product.episodeCount}</td>
                  <td>{product.createdAt}</td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      onClick={() =>
                        props.onConnect({
                          bread_product_id: product.id,
                          title: product.title,
                          plot: product.description,
                          category: product.genre.id,
                        })
                      }
                    >
                      선택
                    </button>{" "}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="modal-footer flex">
          {loading && (
            <div className="pl-20">
              <BeatLoader color="#ae7e55" />
            </div>
          )}
          {!loading && (
            <button
              type="button"
              className="btn btn-default"
              onClick={() => createBread()}
            >
              브레드에 새작품 생성
            </button>
          )}
          {!loading && (
            <button
              type="button"
              className="btn btn-default"
              onClick={() => props.onModal(false)}
            >
              닫기
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default BreadProductModal;
