import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// import { $get, $post, $put, $delete, $upload } from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import {
  $post,
  $put,
  $delete,
  $get,
  $dateTimezone,
  $dateFormat,
  $convertNumberFormat,
} from "../utils/common";
// import WorkspaceData from "./Workspace.json";
import Swal from "sweetalert2";
import Loading from "../component/Loading";

function PaymentList() {
  const navigate = useNavigate();
  const location = useLocation();

  const { isLoggedIn, userData, checkLogin } = useAuth();
  const { email } = location.state || { email: null };

  const [user, setUser] = useState(null);
  const [pageLimit, setPageLimit] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageRange, setPageRange] = useState([]);
  const [lastPage, setLastPage] = useState(1);

  const [loading, setLoading] = useState(false);
  const [paymentList, setPaymentList] = useState([]);

  const scrollRef = useRef(null);

  const paging = () => {
    const pages = [];

    const pageFirst =
      pageNo % 5 === 0 ? pageNo - 4 : Math.floor(pageNo / 5) * 5 + 1;
    let pageLast = pageFirst + 4;

    if (pageLast > totalPage) pageLast = totalPage;
    setLastPage(pageLast);

    for (let i = pageFirst; i <= pageLast; i++) {
      pages.push(i);
    }
    setPageRange(pages);
  };

  const changePagination = async () => {
    const res = await $get(`/api/payment/all/${pageNo}/${pageLimit}`);
    if (res.status === 200) {
      setPaymentList(res.data.list);
      setTotalPage(Math.ceil(res.data.total_count / pageLimit));
      paging();
    }
  };

  useEffect(() => {
    paging();
  }, [totalPage]);

  useEffect(() => {
    changePagination(pageNo);
  }, [pageNo]);

  useEffect(() => {}, []);

  return (
    <main className="container" style={{ height: `calc(100vh - 68px)` }}>
      <div className="content">
        <div className="content-body align-items-start">
          <div
            className="flex justify-content-between align-items-center mb-10"
            style={{ width: "100%" }}
          >
            <div className="sub-title font-nanumMyeongjo">결제 내역</div>
            <div>
              <button
                className="btn btn-default btn-sm mr-10"
                onClick={(e) => {
                  navigate("/point-admin");
                }}
              >
                포인트정책
              </button>
              <button
                className="btn btn-default btn-sm"
                onClick={(e) => {
                  navigate("/payment-admin");
                }}
              >
                결제정책
              </button>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-hover table-nowrap">
              <thead>
                <tr>
                  <th>이메일</th>
                  <th style={{ width: "100px" }}>이름</th>
                  {/* <th>닉네임</th> */}
                  <th>결제일</th>
                  <th style={{ width: "180px" }}>주문명</th>
                  <th style={{ width: "140px" }}>결제금액</th>
                </tr>
              </thead>
              <tbody>
                {paymentList &&
                  paymentList.map((payment, idx) => (
                    <tr key={`payment-${idx}`}>
                      <td>{payment.email}</td>
                      <td>{payment.name}</td>
                      {/* <td>{payment.nickname}</td> */}
                      <td>{$dateFormat(payment.approved_at)}</td>
                      <td>{payment.order_name}</td>
                      <td>{$convertNumberFormat(payment.total_amount)}원</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {totalPage > 1 && (
              <div className="d-flex justify-content-center mt-10">
                <nav aria-label="Page navigation example">
                  <ul className="pagination">
                    {pageNo > 5 && (
                      <li className="page-item">
                        <a
                          className="page-link"
                          onClick={(e) => setPageNo(pageNo - 1)}
                        >
                          <i className="fa-solid fa-arrow-left"></i>
                        </a>
                      </li>
                    )}

                    {pageRange.map((page) => (
                      <li key={page}>
                        <a
                          className={
                            pageNo === page ? "page-link active" : "page-link"
                          }
                          onClick={(e) => setPageNo(page)}
                        >
                          {page}
                        </a>
                      </li>
                    ))}

                    {lastPage < totalPage && (
                      <li className="page-item">
                        <a
                          className="page-link"
                          onClick={(e) => setPageNo(pageNo + 1)}
                        >
                          <i className="fa-solid fa-arrow-right"></i>
                        </a>
                      </li>
                    )}
                  </ul>
                </nav>
              </div>
            )}
          </div>
        </div>
      </div>
      {loading && <Loading />}
    </main>
  );
}

export default PaymentList;
