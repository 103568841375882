import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// import { $get, $post, $put, $delete, $upload } from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import { $post, $put, $delete, $get, $dateTimezone } from "../utils/common";
// import WorkspaceData from "./Workspace.json";
import Swal from "sweetalert2";
import ShareModal from "../component/ShareModal";
import Loading from "../component/Loading";

function Faq() {
  const navigate = useNavigate();

  const { isLoggedIn, userData, checkLogin } = useAuth();

  const [faqList, setFaqList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [faqModalOpen, setFaqModalOpen] = useState(false);
  const [activeFaq, setActiveFaq] = useState({
    title: "",
    content: "",
  });

  const location = useLocation();
  const scrollRef = useRef(null);

  const getFaqList = async () => {
    setLoading(true);
    const res = await $get(`/api/faq`);
    if (res.status === 200) {
      setFaqList(res.data);
    } else {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "FAQ 조회 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    getFaqList();
  }, []);

  return (
    <main className="container">
      <div className="content">
        <div className="content-body align-items-start" ref={scrollRef}>
          <div
            className="flex justify-content-between align-items-center mb-10"
            style={{ width: "100%" }}
          >
            <div className="sub-title font-nanumMyeongjo">FAQ</div>
          </div>
          <div className="faq">
            {faqList &&
              faqList.map((faq) => (
                <div className="faq-item" key={faq.id}>
                  <div
                    className="faq-item-title"
                    onClick={(e) => {
                      e.target.nextElementSibling.classList.toggle("collapse");
                    }}
                  >
                    Q. {faq.title}
                  </div>
                  <div className="faq-item-content">{faq.content}</div>
                </div>
              ))}
          </div>
        </div>
      </div>

      {loading && <Loading />}
    </main>
  );
}

export default Faq;
