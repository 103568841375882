import React, { useState, useEffect, useRef } from "react";
import { $put, $post, $upload } from "../utils/common";
import Swal from "sweetalert2";

function ReviewModal(props) {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const [review, setReview] = useState({
    paragraph1: "",
    paragraph2: "",
    paragraph3: "",
    reviewer: "",
    img_url: "",
  });
  const fileRef = useRef(null);

  const uploadImage = async (file) => {
    const res = await $upload("/api/upload/image", file);
    if (res.status === 200) {
      console.log(res.data);
      setReview({ ...review, img_url: res.data.filename });
    } else {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "이미지 업로드 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  const addReview = async (e) => {
    if (review.paragraph1 === "") {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "리뷰 첫번째 줄을 입력해주세요.",
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }

    if (review.paragraph2 === "") {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "리뷰 두번째 줄을 입력해주세요.",
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }

    if (review.paragraph3 === "") {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "리뷰 세번째 줄을 입력해주세요.",
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }

    if (review.reviewer === "") {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "리뷰어를 입력해주세요.",
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }

    props.onAdd(review);
    props.onModal(false);
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="modal-header">
          <span className="close" onClick={() => props.onModal(false)}>
            &times;
          </span>
          <h3>리뷰</h3>
        </div>
        <div className="modal-body">
          <div className="form-group">
            <label className="form-label">리뷰 첫번째 줄</label>
            <input
              type="text"
              className="form-control"
              value={review.paragraph1}
              onChange={(e) =>
                setReview({ ...review, paragraph1: e.target.value })
              }
            />
          </div>
          <div className="form-group">
            <label className="form-label">리뷰 두번째 줄</label>
            <input
              type="text"
              className="form-control"
              value={review.paragraph2}
              onChange={(e) =>
                setReview({ ...review, paragraph2: e.target.value })
              }
            />
          </div>
          <div className="form-group">
            <label className="form-label">리뷰 세번째 줄</label>
            <input
              type="text"
              className="form-control"
              value={review.paragraph3}
              onChange={(e) =>
                setReview({ ...review, paragraph3: e.target.value })
              }
            />
          </div>
          <div className="form-group">
            <label className="form-label">리뷰어</label>
            <input
              type="text"
              className="form-control"
              value={review.reviewer}
              onChange={(e) =>
                setReview({ ...review, reviewer: e.target.value })
              }
            />
          </div>
          <div className="form-group">
            <label className="form-label">이미지</label>
            <img
              className="user-avatar"
              src={
                review.img_url
                  ? `${serverUrl}/static/images/${review.img_url}`
                  : "./assets/images/default-profile.jpg"
              }
              alt="avatar"
              onClick={() => fileRef.current.click()}
            />
            <input
              type="file"
              accept="image/png, image/jpeg"
              ref={fileRef}
              style={{ display: "none" }}
              onChange={(e) => uploadImage(e.target.files[0])}
            />
          </div>
        </div>
        <div className="modal-footer flex justify-content-end">
          <button
            type="button"
            className="btn btn-default"
            onClick={() => props.onModal(false)}
          >
            닫기
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => addReview()}
          >
            추가
          </button>
        </div>
      </div>
    </div>
  );
}

export default ReviewModal;
