import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { $put, $get, $post } from "../utils/common";
import Swal from "sweetalert2";
import Loading from "../component/Loading";

function NovelSetEpisode(props) {
  const navigate = useNavigate();
  const [totalEpisode, setTotalEpisode] = useState(1);
  const [loading, setLoading] = useState(false);

  const saveTotalEpisode = async () => {
    if (parseInt(totalEpisode) > 100) {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: `에피소드는 최대 100개까지 설정 가능합니다.`,
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    } else if (parseInt(totalEpisode) < 1) {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: `에피소드는 최소 1개 이상 설정 가능합니다.`,
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }

    const res = await $put(`/api/product/${props.productId}`, {
      total_episode: parseInt(totalEpisode),
      step: 4,
    });

    if (res.status === 200) {
      props.onNext(1);
    } else {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "회차 설정 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  const getTotalEpisode = async () => {
    const res = await $get(`/api/product/${props.productId}`);
    if (res.status === 200) {
      setTotalEpisode(res.data.total_episode);
    } else {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "회차 조회 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  useEffect(() => {
    getTotalEpisode();
  }, []);
  return (
    <div className="register-box">
      <div className="sub-title font-nanumMyeongjo">회차 설정하기</div>
      <div className="description">
        <div>
          <p>
            내 작품의 회차를 설정하세요. 한번에 집필하는 회차 수는 100개를 넘을
            수 없습니다.
          </p>
          <p>더 많은 회차를 고려하는 작품은 시즌제로 나누어서 집필하세요.</p>
        </div>
      </div>
      <div className="form-group">
        <label className="form-label">회차 수</label>
        <input
          type="number"
          className="form-control"
          placeholder="1~100 사이의 숫자를 기입해주세요."
          min={1}
          max={100}
          value={totalEpisode}
          onChange={(e) => {
            setTotalEpisode(parseInt(e.target.value));
          }}
        />
      </div>

      <div className="button-list mt-30">
        <button
          type="button"
          className="btn btn-primary mr-10"
          onClick={() => saveTotalEpisode()}
        >
          회차 설정 완료
        </button>
        <button
          type="button"
          className="btn btn-default"
          onClick={() => props.onNext(-1)}
        >
          이전으로 돌아가기
        </button>
      </div>
      {loading && <Loading />}
    </div>
  );
}

export default NovelSetEpisode;
