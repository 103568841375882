import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../login/AuthProvider";
import { $post, $put, $delete, $get, $dateTimezone } from "../utils/common";
import Swal from "sweetalert2";
// import SummaryAssistantModal from "../component/SummaryAssistantModal";
// import ParagraphAssistantModal from "../component/ParagraphAssistantModal";
import NovelSetBasicView from "../component/NovelSetBasicView";
import NovelSetCharacterView from "../component/NovelSetCharacterView";
import NovelSetEpisodeCardView from "../component/NovelSetEpisodeCardView";
import EpisodeEditor from "../component/EpisodeEditor";
import Loading from "../component/Loading";

function Novel() {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = location.state;
  const scrollNoteRef = useRef();

  const { isLoggedIn, userData, checkLogin } = useAuth();

  const [leftSidebarOpen, setLeftSidebarOpen] = useState(true);
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [showSidebar, setShowSidebar] = useState("menu");
  const [productInfo, setProductInfo] = useState({});
  const [subauthorList, setSubauthorList] = useState([]);
  const [episodeList, setEpisodeList] = useState([]);
  const [memoList, setMemoList] = useState([]);
  const [activeEpisode, setActiveEpisode] = useState(null);
  const [selectedRange, setSelectedRange] = useState(null);
  const [memoStatusDropdown, setMemoStatusDropdown] = useState(false);
  const [memoPosition, setMemoPosition] = useState({ x: 0, y: 0 });
  const [activeMemo, setActiveMemo] = useState(null);

  const openLeftSidebar = () => {
    setLeftSidebarOpen(!leftSidebarOpen);
  };

  const getProductInfo = async () => {
    setLoading(true);
    const res = await $get(`/api/product/${id}`);
    setLoading(false);
    if (res.status === 200) {
      setProductInfo(res.data);
      setContent("basic");
    } else {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "작품 조회 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  const checkStartEpisode = async (id, idx) => {
    if (idx > 0 && episodeList[idx - 1].complete_yn === "N") {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "이전 회차를 완료해야 합니다.",
        showConfirmButton: false,
        timer: 1000,
      });
    } else {
      loadEpisode(id);
    }
  };

  const loadEpisode = async (episodeId) => {
    setLoading(true);
    setContent("episode");
    const res = await $get(`/api/product/episode/detail/${episodeId}`);
    setLoading(false);
    if (res.status === 200) {
      setActiveEpisode(res.data);
      getMemoList(res.data.id);
    } else {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "에피소드 조회 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  const getEpisodeList = async () => {
    setLoading(true);
    const res = await $get(`/api/product/episode/${id}`);
    setLoading(false);
    if (res.status === 200) {
      setEpisodeList(res.data);
    } else {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "에피소드 조회 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  const getMemoList = async (id) => {
    const res = await $get(`/api/product/episode-memo/${id}`);
    if (res.status === 200) {
      setMemoList(res.data);
    } else {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "메모 조회 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  const moveToMemo = (memoId) => {
    scrollNoteRef.current.scrollNote(memoId);
  };

  const toggleMemoStatusDropdown = (memo, e) => {
    // console.log(memo);
    setMemoPosition({ x: e.clientX, y: e.clientY });
    console.log(activeMemo, memo);
    if (activeMemo && memo.id === activeMemo.id) {
      setMemoStatusDropdown(!memoStatusDropdown);
    } else {
      setActiveMemo(memo);
      setMemoStatusDropdown(true);
    }
  };

  const deleteMemo = async () => {
    setMemoStatusDropdown(false);
    const res = await $delete(`/api/product/episode-memo/${activeMemo.id}`);
    if (res.status === 200) {
      getMemoList(activeEpisode.id);
      scrollNoteRef.current.deleteMemo(activeMemo.id);
      setActiveMemo(null);
    } else {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "메모 삭제 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  const changeMemoStatus = async (status) => {
    setMemoStatusDropdown(false);
    const res = await $put(`/api/product/episode-memo/${activeMemo.id}`, {
      status,
    });
    if (res.status === 200) {
      getMemoList(activeEpisode.id);
      // if (status === "C") {
      //   scrollNoteRef.current.deleteMemo(activeMemo.id);
      // }
    } else {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "메모 상태 변경 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  const getSubauthorList = async () => {
    const res = await $get(`/api/product/subauthor/${id}`);
    if (res.status === 200) {
      setSubauthorList(res.data);
    }
  };

  useEffect(() => {
    getProductInfo();
    getSubauthorList();
    getEpisodeList();
  }, []);

  return (
    userData &&
    (userData.role_level === 0 ||
      userData.email === productInfo.email ||
      subauthorList.filter((sa) => sa.email === userData.email).length > 0) && (
      <main className="container full" style={{ height: `calc(100vh - 68px)` }}>
        <div className={`sidebar${leftSidebarOpen ? "" : " toggle"}`}>
          <div className="sidebar-header">
            <button
              type="button"
              className="btn btn-xsm btn-transparent"
              onClick={() => setShowSidebar("menu")}
            >
              <i className="fa-solid fa-bars"></i>
            </button>
            {content === "episode" && (
              <button
                type="button"
                className="btn btn-xsm btn-transparent"
                onClick={() => setShowSidebar("memo")}
              >
                <i className="fa-regular fa-message"></i>
              </button>
            )}
          </div>
          <div className="work-container">
            {showSidebar === "menu" && (
              <ol className="work-list">
                <li>
                  <a className="work-title">작품 정보</a>
                  <ol>
                    <li>
                      <a
                        className={
                          content === "basic" ? "work-item active" : "work-item"
                        }
                        onClick={() => {
                          setActiveEpisode(null);
                          setContent("basic");
                        }}
                      >
                        기본 정보
                      </a>
                    </li>
                    <li>
                      <a
                        className={
                          content === "character"
                            ? "work-item active"
                            : "work-item"
                        }
                        onClick={() => {
                          setActiveEpisode(null);
                          setContent("character");
                        }}
                      >
                        등장 인물
                      </a>
                    </li>
                    <li>
                      <a
                        className={
                          content === "episodeCard"
                            ? "work-item active"
                            : "work-item"
                        }
                        onClick={() => {
                          setActiveEpisode(null);
                          setContent("episodeCard");
                        }}
                      >
                        에피소드 카드
                      </a>
                    </li>
                  </ol>
                </li>
                <li>
                  <a className="work-title">에피소드 목록</a>
                  <ol>
                    {episodeList.map((episode, idx) => (
                      <li key={episode.id}>
                        <a
                          className={
                            activeEpisode && episode.id === activeEpisode.id
                              ? "work-item active"
                              : "work-item"
                          }
                          onClick={(e) => checkStartEpisode(episode.id, idx)}
                        >
                          <i className="fa-regular fa-file mr-5"></i> 에피소드{" "}
                          {episode.episode_no} - {episode.title}
                        </a>
                      </li>
                    ))}
                  </ol>
                </li>
              </ol>
            )}
            {showSidebar === "memo" && (
              <ol className="memo-list">
                {memoList.map((memo) => (
                  <li key={memo.id}>
                    <div className="memo">
                      <div className="memo-header">
                        <div className="memo-user">{memo.email}</div>
                        <button
                          type="button"
                          className="btn btn-transparent"
                          onClick={(e) => {
                            toggleMemoStatusDropdown(memo, e);
                          }}
                        >
                          <i
                            className={`fa-regular fa-circle-check color-${memo.status}`}
                          ></i>
                        </button>
                      </div>
                      <div
                        className="memo-body"
                        onClick={() => moveToMemo(memo.id)}
                      >
                        {memo.memo}
                      </div>
                    </div>
                  </li>
                ))}
              </ol>
            )}
            {activeMemo && (
              <div
                className="user-menu position-none"
                style={{ position: "initial !important" }}
              >
                <div
                  className={
                    memoStatusDropdown ? "dropdown-menu show" : "dropdown-menu"
                  }
                  style={{ top: memoPosition.y, left: memoPosition.x }}
                >
                  <a
                    className="dropdown-item"
                    style={{
                      display:
                        activeMemo.status === "N" || activeMemo.status === "C"
                          ? "block"
                          : "none",
                    }}
                    onClick={() => changeMemoStatus("P")}
                  >
                    <i className={`fa-regular fa-circle-check color-P`}></i>{" "}
                    진행중
                  </a>
                  <a
                    className="dropdown-item"
                    style={{
                      display:
                        activeMemo.status === "N" || activeMemo.status === "P"
                          ? "block"
                          : "none",
                    }}
                    onClick={() => changeMemoStatus("C")}
                  >
                    <i className={`fa-regular fa-circle-check color-C`}></i>{" "}
                    완료
                  </a>
                  <a
                    className="dropdown-item"
                    style={{
                      display:
                        activeMemo.status === "N" ||
                        activeMemo.status === "P" ||
                        activeMemo.status === "C"
                          ? "block"
                          : "none",
                    }}
                    onClick={() => deleteMemo()}
                  >
                    <i className="fa-solid fa-trash-can"></i> 삭제
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
        {content === "basic" && productInfo && (
          <NovelSetBasicView productInfo={productInfo} />
        )}
        {content === "character" && productInfo && (
          <NovelSetCharacterView productId={productInfo.id} />
        )}
        {content === "episodeCard" && productInfo && (
          <NovelSetEpisodeCardView
            productId={productInfo.id}
            onList={() => getEpisodeList()}
          />
        )}
        {content === "episode" && activeEpisode && (
          <EpisodeEditor
            episode={activeEpisode}
            productInfo={productInfo}
            onToggle={() => openLeftSidebar()}
            onComplete={() => loadEpisode(activeEpisode.id)}
            onList={() => getEpisodeList()}
            onMemo={(episodeId) => getMemoList(episodeId)}
            ref={scrollNoteRef}
          />
        )}
        {loading && <Loading />}
      </main>
    )
  );
}

export default Novel;
