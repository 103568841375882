import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// import { $get, $post, $put, $delete, $upload } from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import {
  $post,
  $upload,
  $put,
  $delete,
  $get,
  $dateTimezone,
} from "../utils/common";
// import WorkspaceData from "./Workspace.json";
import Swal from "sweetalert2";
import Loading from "../component/Loading";

function SystemPopupRegistration() {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const navigate = useNavigate();

  const { isLoggedIn, userData, checkLogin } = useAuth();

  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { popupId } = location.state || { popupId: null };
  const [popup, setPopup] = useState({
    title: "",
    from_datetime: "",
    to_datetime: "",
    content: "",
    type: "T",
    image_path: "",
  });

  const fileRef = useRef(null);

  const uploadImage = async (file) => {
    const res = await $upload("/api/upload/image", file);
    if (res.status === 200) {
      setPopup({ ...popup, image_path: `/static/images/${res.data.filename}` });
    } else {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "이미지 업로드 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  const savePopup = async () => {
    if (!popup.title) {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "제목을 입력해주세요.",
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }
    // 기간 선택 필수
    if (!popup.from_datetime || !popup.to_datetime) {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "기간을 선택해주세요.",
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }

    // 타입이 글일 경우 내용 필수
    if (popup.type === "T" && !popup.content) {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "내용을 입력해주세요.",
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }

    // 타입이 이미지일 경우 이미지 필수
    if (popup.type === "I" && !popup.image_path) {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "이미지를 선택해주세요.",
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }

    if (popup.from_datetime > popup.to_datetime) {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "시작일이 종료일보다 늦습니다.",
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }

    Swal.fire({
      iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
      title: "팝업 저장",
      text: "팝업을 저장하시겠습니까?",
      showCancelButton: true,
      confirmButtonText: "예",
      cancelButtonText: "아니오",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = null;

        if (popupId) {
          const tempPopup = { ...popup };
          delete tempPopup.id;
          delete tempPopup.create_datetime;

          res = await $put(`/api/popup/${popupId}`, {
            ...tempPopup,
            from_datetime: $dateTimezone(popup.from_datetime, 0),
            to_datetime: $dateTimezone(popup.to_datetime, 0),
          });
        } else {
          res = await $post("/api/popup", {
            ...popup,
            from_datetime: $dateTimezone(popup.from_datetime, 0),
            to_datetime: $dateTimezone(popup.to_datetime, 0),
          });
        }

        if (res.status === 200) {
          Swal.fire({
            iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
            title: "저장되었습니다.",
            showConfirmButton: false,
            timer: 1000,
          });
          navigate("/system-popup");
        } else {
          Swal.fire({
            iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
            title: "저장 실패",
            text: res.message,
            showConfirmButton: false,
            timer: 1000,
          });
        }
      }
    });
  };

  const getPopupDetail = async () => {
    if (!popupId) return;
    const res = await $get(`/api/popup/${popupId}`);
    if (res.status === 200) {
      setPopup({
        ...res.data,
        from_datetime: $dateTimezone(res.data.from_datetime, 9),
        to_datetime: $dateTimezone(res.data.to_datetime, 9),
      });
    } else {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "팝업 조회 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  useEffect(() => {
    if (userData && userData.role_level !== 0) {
      navigate("/401");
    }
  }, [userData]);

  useEffect(() => {
    getPopupDetail();
  }, []);

  return (
    <main className="container">
      <div className="content">
        <div className="content-body align-items-start">
          <div className="sub-title font-nanumMyeongjo mb-30">팝업 생성</div>
          <div className="form-group">
            <label className="form-label">제목</label>
            <input
              type="text"
              className="form-control"
              value={popup.title}
              onChange={(e) => setPopup({ ...popup, title: e.target.value })}
            />
          </div>
          <div className="form-group">
            <label className="form-label">기간</label>
            <div className="flex align-items-center">
              <input
                type="datetime-local"
                className="form-control"
                value={popup.from_datetime}
                onChange={(e) =>
                  setPopup({ ...popup, from_datetime: e.target.value })
                }
              />
              <span className="ml-5 mr-5">~</span>
              <input
                type="datetime-local"
                className="form-control"
                value={popup.to_datetime}
                onChange={(e) =>
                  setPopup({ ...popup, to_datetime: e.target.value })
                }
              />
            </div>
          </div>
          <div className="form-group">
            <label className="form-label">팝업 형식</label>
            <div className="flex">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  id="typeText"
                  name="type"
                  value="T"
                  checked={popup.type === "T"}
                  onChange={(e) =>
                    setPopup({ ...popup, type: e.target.value, image_path: "" })
                  }
                />
                <label className="form-check-label" htmlFor="typeText">
                  글
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  id="typeImage"
                  name="type"
                  value="I"
                  checked={popup.type === "I"}
                  onChange={(e) => setPopup({ ...popup, type: e.target.value })}
                />
                <label className="form-check-label" htmlFor="typeImage">
                  이미지
                </label>
              </div>
            </div>
          </div>
          {popup.type === "T" && (
            <div className="form-group">
              <label className="form-label">내용</label>
              <textarea
                className="form-control"
                rows="5"
                value={popup.content}
                onChange={(e) =>
                  setPopup({ ...popup, content: e.target.value })
                }
              ></textarea>
            </div>
          )}
          {popup.type === "I" && (
            <div className="form-group">
              <label className="form-label">이미지</label>
              {!popup.image_path && (
                <div
                  className="image-upload"
                  onClick={() => fileRef.current.click()}
                >
                  <i className="fa-regular fa-image fa-2x"></i>
                </div>
              )}

              <input
                type="file"
                accept="image/png, image/jpeg"
                ref={fileRef}
                style={{ display: "none" }}
                onChange={(e) => uploadImage(e.target.files[0])}
              />
              {popup.image_path && (
                <div className="image-preview">
                  <img
                    src={serverUrl + popup.image_path}
                    alt=""
                    onClick={() => fileRef.current.click()}
                  />
                </div>
              )}
            </div>
          )}
          {popup.type === "I" && (
            <div className="form-group">
              <label className="form-label">이미지 링크</label>
              <input
                type="url"
                className="form-control"
                placeholder="이미지 클릭 시 특정 웹사이트로 이동하려면 url 주소를 입력하세요."
                value={popup.link}
                onChange={(e) => setPopup({ ...popup, link: e.target.value })}
              />
            </div>
          )}
          <div className="flex justify-content-center">
            <button className="btn btn-primary" onClick={savePopup}>
              저장
            </button>
          </div>
        </div>
      </div>

      {loading && <Loading />}
    </main>
  );
}

export default SystemPopupRegistration;
