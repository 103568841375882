import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();
  const [navMarginLeft, setNavMarginLeft] = useState("0px"); // [navMarginLeft

  const changeNavMarginLeft = () => {
    // 해상도가 1400px 이상이면 .navbar-nav의 margin-left를 0으로 설정
    if (window.innerWidth >= 1400) {
      let tempMarginLeft = window.innerWidth / 2 - 500 - 230;
      setNavMarginLeft(tempMarginLeft + "px");
    } else {
      setNavMarginLeft("0px");
    }
  };

  useEffect(() => {
    changeNavMarginLeft();
    window.addEventListener("resize", changeNavMarginLeft);
    return () => {
      window.removeEventListener("resize", changeNavMarginLeft);
    };
  });

  return (
    <footer>
      <div className="logo">
        <Link to="/">
          <img src="./assets/images/b-writer-logo-beta.png" />
        </Link>
      </div>
      <div className="footer-menu" style={{ marginLeft: navMarginLeft }}>
        <section className="terms">
          <ul className="link">
            <li>
              <Link to="/story" title="서비스 소개">
                서비스 소개
              </Link>
            </li>
            <li>
              <a
                target="_blank"
                href="https://text-ure.notion.site/844a4f64b2ae4dd18bef9d83b7129195"
                title="이용약관"
              >
                이용약관
              </a>
            </li>

            <li>
              <a
                target="_blank"
                title="개인정보처리방침"
                href="https://text-ure.notion.site/6bfa94f2755544e09ed9fb90da92ac43"
              >
                개인정보처리방침
              </a>
            </li>
          </ul>
        </section>
        <section className="company-info">
          <p>
            <span>
              주식회사 텍스처 (대표 : 김치호), 주소 : 제주특별자치도 서귀포시
              돈내코로 172, 304동
            </span>
          </p>
          <p>
            <span>이메일: ask@texture.kr, 연락처: 010-2438-0690</span>
          </p>
          <p>
            <span>사업자 등록번호: 389-86-02486, 제2022-제주영천-19호</span>
          </p>
          <p>
            <span>ⓒ Texture, Inc., ALL RIGHTS RESERVED.</span>
          </p>
        </section>
      </div>
    </footer>
  );
}

export default Footer;
