import React, { useState, useEffect } from "react";
import { $put, $post } from "../utils/common";
import Swal from "sweetalert2";

function CharacterModal(props) {
  const [character, setCharacter] = useState(props.character);

  const saveCharacter = async (e) => {
    if (props.character.id === undefined) {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "등장 인물 등록",
        text: "인물을 등록하시겠습니까?",
        showCancelButton: true,
        confirmButtonText: "예",
        cancelButtonText: "아니오",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const res = await $post(`/api/product/character`, {
            product_id: props.productId,
            name: character.name,
            gender: character.gender,
            age: character.age,
            job: character.job,
            role: character.role,
            personality: character.personality,
            background: character.background,
          });

          if (res.status === 200) {
            props.onModal(false);
          } else {
            Swal.fire({
              iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
              title: "등장 인물 등록 실패",
              text: res.message,
              showConfirmButton: false,
              timer: 1000,
            });
          }
        }
      });
    } else {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "등장 인물 수정",
        text: "인물을 수정하시겠습니까?",
        showCancelButton: true,
        confirmButtonText: "예",
        cancelButtonText: "아니오",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const res = await $put(
            `/api/product/character/${props.character.id}`,
            {
              name: character.name,
              gender: character.gender,
              age: character.age,
              job: character.job,
              role: character.role,
              personality: character.personality,
              background: character.background,
            }
          );

          if (res.status === 200) {
            props.onModal(false);
          } else {
            Swal.fire({
              iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
              title: "등장 인물 수정 실패",
              text: res.message,
              showConfirmButton: false,
              timer: 1000,
            });
          }
        }
      });
    }
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="modal-header">
          <span className="close" onClick={() => props.onModal(false)}>
            &times;
          </span>
          <h3>등장 인물</h3>
        </div>
        <div className="modal-body">
          <div className="form-group">
            <label className="form-label">이름</label>
            <input
              type="text"
              className="form-control"
              value={character.name}
              placeholder="캐릭터의 이름을 쓰세요."
              onChange={(e) =>
                setCharacter({ ...character, name: e.target.value })
              }
            />
          </div>
          <div className="form-group">
            <label className="form-label">성별</label>
            <input
              type="text"
              className="form-control"
              value={character.gender}
              placeholder="남성, 여성, 동물 등 캐릭터의 성별을 쓰세요."
              onChange={(e) =>
                setCharacter({ ...character, gender: e.target.value })
              }
            />
          </div>
          <div className="form-group">
            <label className="form-label">나이</label>
            <input
              type="text"
              className="form-control"
              value={character.age}
              placeholder="캐릭터의 나이를 쓰세요."
              onChange={(e) =>
                setCharacter({ ...character, age: e.target.value })
              }
            />
          </div>
          <div className="form-group">
            <label className="form-label">직업</label>
            <input
              type="text"
              className="form-control"
              value={character.job}
              placeholder="캐릭터의 직업을 쓰세요."
              onChange={(e) =>
                setCharacter({ ...character, job: e.target.value })
              }
            />
          </div>
          <div className="form-group">
            <label className="form-label">역할</label>
            <input
              type="text"
              className="form-control"
              value={character.role}
              placeholder="캐릭터의 역할을 쓰세요. 주인공 혹은 등장인물과의 관계를 함께 쓰세요. (100자 이내)"
              onChange={(e) =>
                setCharacter({ ...character, role: e.target.value })
              }
            />
          </div>
          <div className="form-group">
            <label className="form-label">성격</label>
            <textarea
              type="text"
              className="form-control"
              value={character.personality}
              placeholder="캐릭터의 성격을 묘사하세요. (200자 이내)"
              rows="3"
              onChange={(e) =>
                setCharacter({ ...character, personality: e.target.value })
              }
            ></textarea>
          </div>
          <div className="form-group">
            <label className="form-label">배경</label>
            <textarea
              type="text"
              className="form-control"
              value={character.background}
              rows="5"
              placeholder="캐릭터를 둘러싸고 있는 맥락, 공간, 시간, 사건, 상황 등을 자세히 쓰세요. (300자 이내)"
              onChange={(e) =>
                setCharacter({ ...character, background: e.target.value })
              }
            ></textarea>
          </div>
        </div>
        <div className="modal-footer flex">
          <button
            type="button"
            className="btn btn-default"
            onClick={() => props.onModal(false)}
          >
            닫기
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => saveCharacter()}
          >
            저장
          </button>
        </div>
      </div>
    </div>
  );
}

export default CharacterModal;
