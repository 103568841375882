import axios from "axios";
const serverUrl = process.env.REACT_APP_SERVER_URL;

axios.defaults.baseURL = serverUrl;
axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
axios.defaults.headers["Access-Control-Allow-Origin"] = "*";
axios.defaults.withCredentials = true;

const $get = async (url, data = {}) => {
  return await axios.get(url).catch((e) => {
    console.log(e);
    if (e.response.status === 401) {
      return { data: { AuthorizationStatus: 0 }, status: 401 };
    } else if (e.response.status === 404) {
      window.location.href = "/404";
    } else if (e.response.status === 500) {
      window.location.href = "/500";
    }
  });
};

const $post = async (url, data) => {
  return await axios.post(url, data).catch((e) => {
    console.log(e);
  });
};

const $put = async (url, data) => {
  return await axios.put(url, data).catch((e) => {
    console.log(e);
  });
};

const $delete = async (url) => {
  return await axios.delete(url).catch((e) => {
    console.log(e);
    if (e.response.status === 401) {
      return { data: { AuthorizationStatus: 0 }, status: 401 };
    } else if (e.response.status === 404) {
      window.location.href = "/404";
    }
  });
};

const $stream = async (url, data) => {
  return await axios
    .post(url, data, {
      responseType: "stream",
    })
    .catch((e) => {
      console.log(e);
    });
};

const $upload = async (url, file) => {
  const formData = new FormData();
  formData.append("attachment", file);
  formData.append("originalname", file.name);

  return await axios
    .post(url, formData, {
      headers: { "Content-Type": "multipart/form-datam" },
    })
    .catch((e) => {
      console.log(e);
    });
};

const $download = async (url, data = {}) => {
  return await axios.get(url, { responseType: "blob" }).catch((e) => {
    console.log(e);
    if (e.response.status === 401) {
      return { data: { AuthorizationStatus: 0 }, status: 401 };
    } else if (e.response.status === 404) {
      window.location.href = "/404";
    } else if (e.response.status === 500) {
      console.log(e);
      // window.location.href = "/500";
    }
  });
};

const $dateTimezone = (utcDate, timeInterval = 9) => {
  const date = new Date(utcDate).getTime() + timeInterval * 60 * 60 * 1000;
  // date format : yyyy-MM-dd hh:mm:ss
  return new Date(date).toISOString().slice(0, 19).replace("T", " ");
};

const $dateFormat = (date) => {
  // 2024-01-31T17:00:14+09:00 => 2024-01-31 17:00:14
  if (!date) return;
  if (date.length < 19) return date;
  return date.replace("T", " ").slice(0, 19);
};

const $checkOpenAIAvailable = async () => {
  await $get(`/api/prompt/check`);
};

const $convertNumberFormat = (amount, format = "#,###") => {
  let currencySymbol = "";
  let lastSymbol = "";

  if (format.substr(0, 1) !== "#") {
    currencySymbol = format.substr(0, 1);
  }

  if (format.slice(-1) !== "#" && format.slice(-1) !== "0") {
    lastSymbol = format.slice(-1);
    // '#,###.#0%'
    format = format.substring(0, format.length - 1);
  }

  let groupingSeparator = ""; // 숫자 3자리마다 구분자 기호
  let decimalSeparator = ""; // 소수점 구분자 기호
  let maxFractionDigits = 0; // 소수점 몇자리까지 표기할건지

  if (format.indexOf(".") === -1) {
    // #,###
    groupingSeparator = ",";
  } else if (format.indexOf(",") === -1) {
    groupingSeparator = ".";
  } else if (format.indexOf(",") < format.indexOf(".")) {
    // #,###.##
    groupingSeparator = ",";
    decimalSeparator = ".";
    maxFractionDigits = format.length - format.indexOf(".") - 1;
  } else {
    // #.###,##
    groupingSeparator = ".";
    decimalSeparator = ",";
    maxFractionDigits = format.length - format.indexOf(",") - 1;
  }

  let sign = ""; // amount가 음수일 때
  let dec = 1;
  for (let i = 0; i < maxFractionDigits; i++) {
    // i=0, dec = 10
    // i=1, dec = 100
    dec = dec * 10;
  }

  // amount = -3500.2345
  // format = #,###.#0
  // -3500.2345 * 100 = -350023.45
  // Math.round() = -350023
  // -350023 / 100 = -3500.23

  // amount = -3500.2375
  // format = #,###.#0
  // -3500.2375 * 100 = -350023.75
  // Math.round() = -350024
  // -350024 / 100 = -3500.24

  let v = String(Math.round(parseFloat(amount) * dec) / dec);

  if (v.startsWith("-")) {
    sign = "-";
    v = v.substring(1);
  }

  // 정수든, 부동소수점이든 상관없이 무조건 소수점이하 자리수 맞춰주는 곳
  if (maxFractionDigits > 0 && format.slice(-1) === "0") {
    v = parseFloat(v).toFixed(maxFractionDigits);
  }

  let d = ""; // 소수점이하만
  if (maxFractionDigits > 0 && v.indexOf(".") > -1) {
    d = v.substring(v.indexOf(".")); // .24
    // format = #.###,##
    // #,###.##
    d = d.replace(".", decimalSeparator); // .24 => ,24
    v = v.substring(0, v.indexOf(".")); // 3500
  }

  // 3500 => 3,500
  // 8,281,300 =>

  const regexp = /(\d+)(\d{3})/;

  // v = 3524500
  while (regexp.test(v)) {
    // $1 = 3524
    // $2 = 500
    // 3524,500

    // $1 = 3
    // $2 = 524
    // 3,524,500
    v = v.replace(regexp, "$1" + groupingSeparator + "$2");
  }

  return sign + currencySymbol + v + d + lastSymbol;
};

const $convertMarkdownToHtml = (data) => {
  if (data.indexOf("\n") === -1) {
    data = data + "\n";
  }
  // 마크다운 문법을 HTML로 변환
  // "## 내용"을 찾아서 <h2>내용</h2>으로 바꾸기
  data = data.replace(/## (.*)/g, "<h2>$1</h2>");
  // "# 내용"을 찾아서 <h1>내용</h1>으로 바꾸기
  data = data.replace(/# (.*)/g, "<h1>$1</h1>");
  // "### 내용"을 찾아서 <h3>내용</h3>으로 바꾸기
  data = data.replace(/### (.*)/g, "<h3>$1</h3>");
  // "#### 내용"을 찾아서 <h4>내용</h4>으로 바꾸기
  data = data.replace(/#### (.*)/g, "<h4>$1</h4>");
  // "##### 내용"을 찾아서 <h5>내용</h5>으로 바꾸기
  data = data.replace(/##### (.*)/g, "<h5>$1</h5>");
  // "###### 내용"을 찾아서 <h6>내용</h6>으로 바꾸기
  data = data.replace(/###### (.*)/g, "<h6>$1</h6>");

  // "```내용```"을 찾아서 <pre><code>내용</code></pre>으로 바꾸기
  data = data.replace(/```(.*)```/g, "<pre><code>$1</code></pre>");

  // "![이미지](url)"을 찾아서 <img src="url" alt="이미지" />로 바꾸기
  data = data.replace(
    /!\[(.*)\]\((.*)\)/g,
    '<img src="$2" alt="$1" style="max-width:100%;" />'
  );

  // "내용\r\n"을 찾아서 <p>내용</p>으로 바꾸기
  // data = data.replace(/(.*\r\n)/g, "<p>$1</p>");

  // "내용\n\n"을 찾아서 <p>내용</p>으로 바꾸기
  data = data.replace(/(.*\n\n)/g, "<p>$1</p><p></p>");

  // "내용\n"을 찾아서 <p>내용</p>으로 바꾸기
  data = data.replace(/(.*\n)/g, "<p>$1</p>");

  // "내용\r"을 찾아서 <p>내용</p>으로 바꾸기
  // data = data.replace(/(.*\r)/g, "<p>$1</p>");

  // "* *내용*"를 찾아서 <em>내용</em>으로 바꾸기
  data = data.replace(/\* \*(.*)\*/g, "<em>$1</em>");
  // "*내용*"를 찾아서 <em>내용</em>으로 바꾸기
  data = data.replace(/\*(.*)\*/g, "<em>$1</em>");

  // "* **내용**"를 찾아서 <strong>내용</strong>으로 바꾸기
  data = data.replace(/\* \*\*(.*)\*\*/g, "<strong><em>$1</em></strong>");
  // "**내용**"를 찾아서 <strong>내용</strong>으로 바꾸기
  data = data.replace(/\*\*(.*)\*\*/g, "<strong>$1</strong>");

  // "~~내용~~"를 찾아서 <del>내용</del>으로 바꾸기
  data = data.replace(/~~(.*)~~/g, "<del>$1</del>");

  // "[내용](url)"을 찾아서 <a href="url">내용</a>로 바꾸기
  data = data.replace(
    /\[(.*)\]\((.*)\)/g,
    '<a href="$2" target="_blank">$1</a>'
  );

  data = data.replace(/(<p>)+/g, "<p>");
  data = data.replace(/(<\/p>)+/g, "</p>");

  // 마지막 <p> 태그 이후 텍스트가 있으면 <p> 태그로 감싸기
  const lastP = data.lastIndexOf("</p>");
  if (lastP !== data.length - 4) {
    data =
      data.substring(0, lastP + 4) + "<p>" + data.substring(lastP + 4) + "</p>";
  }

  return data;
};

export {
  $get,
  $post,
  $put,
  $delete,
  $upload,
  $download,
  $stream,
  $dateTimezone,
  $dateFormat,
  $checkOpenAIAvailable,
  $convertNumberFormat,
  $convertMarkdownToHtml,
};
