import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// import { $get, $post, $put, $delete, $upload } from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import { $post, $put, $delete, $get, $dateTimezone } from "../utils/common";
// import WorkspaceData from "./Workspace.json";
import Swal from "sweetalert2";
import ShareModal from "../component/ShareModal";
import Loading from "../component/Loading";

function NovelListAdmin() {
  const navigate = useNavigate();

  const { isLoggedIn, userData, checkLogin } = useAuth();

  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [paletteModalOpen, setPaletteModalOpen] = useState(false);
  const [sortKey, setSortKey] = useState("title");
  const [sortOrder, setSortOrder] = useState("asc");
  const [productList, setProductList] = useState([]);
  const [sharedProduct, setSharedProduct] = useState({});
  const [searchText, setSearchText] = useState("");
  // 한달전 날짜
  const beforeMonth = new Date();
  beforeMonth.setMonth(beforeMonth.getMonth() - 1);
  beforeMonth.setDate(beforeMonth.getDate() + 1);

  const [searchFromDate, setSearchFromDate] = useState(
    beforeMonth.toISOString().slice(0, 10)
  );
  // 오늘 날짜
  const [searchToDate, setSearchToDate] = useState(
    `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${new Date().getDate().toString().padStart(2, "0")}`
  );
  const [pageLimit, setPageLimit] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageRange, setPageRange] = useState([]);
  const [lastPage, setLastPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const scrollRef = useRef(null);

  const goToProduct = (id, step) => () => {
    if (step < 5) {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "아직 작품 에피소드 작성이 시작되지 않았습니다.",
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }

    navigate("/novel", { state: { id: id } });
  };

  const paging = () => {
    const pages = [];

    const pageFirst =
      pageNo % 5 === 0 ? pageNo - 4 : Math.floor(pageNo / 5) * 5 + 1;
    let pageLast = pageFirst + 4;

    if (pageLast > totalPage) pageLast = totalPage;
    setLastPage(pageLast);

    for (let i = pageFirst; i <= pageLast; i++) {
      pages.push(i);
    }
    setPageRange(pages);
  };

  const getProductList = async () => {
    setLoading(true);
    const res = await $get(
      `/api/product/list-all/${pageNo}/${pageLimit}?fromDate=${searchFromDate}&toDate=${searchToDate}&search=${searchText}`
    );
    if (res.status === 200) {
      setProductList(res.data.products);
      setTotalPage(Math.ceil(res.data.total_count / pageLimit));
      paging();
    } else {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "작품 조회 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
    setLoading(false);
  };

  const changePagination = async () => {
    const res = await $get(
      `/api/product/list-all/${pageNo}/${pageLimit}?fromDate=${searchFromDate}&toDate=${searchToDate}&search=${searchText}`
    );
    if (res.status === 200) {
      setProductList(res.data.products);
      setTotalPage(Math.ceil(res.data.total_count / pageLimit));
      paging();
    } else {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "작품 조회 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  const deleteProduct = async (id) => {
    Swal.fire({
      iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
      title: "작품 삭제",
      text: "작품을 삭제하시겠습니까?",
      showCancelButton: true,
      confirmButtonText: "예",
      cancelButtonText: "아니오",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $delete(`/api/product/${id}`);
        if (res.status === 200) {
          Swal.fire({
            iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
            title: "작품 삭제 성공",
            text: "작품이 삭제되었습니다.",
            showConfirmButton: false,
            timer: 1000,
          });
          getProductList();
        } else {
          Swal.fire({
            iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
            title: "작품 삭제 실패",
            text: res.message,
            showConfirmButton: false,
            timer: 1000,
          });
        }
      }
    });
  };

  const goToRegister = () => {
    navigate("/novel-register", { state: { step: 1 } });
  };

  useEffect(() => {
    paging();
  }, [totalPage]);

  useEffect(() => {
    changePagination(pageNo);
  }, [pageNo]);

  useEffect(() => {
    // checkLogin();
    // getProductList();
  }, []);

  return (
    <main className="container">
      <div className="content">
        <div className="content-body align-items-start" ref={scrollRef}>
          <div className="search-box mb-10">
            <div className="input-group-sm">
              <input
                type="date"
                className="form-control"
                value={searchFromDate}
                onChange={(e) => setSearchFromDate(e.target.value)}
              />
              ~
              <input
                type="date"
                className="form-control"
                value={searchToDate}
                onChange={(e) => setSearchToDate(e.target.value)}
              />
              <input
                type="text"
                className="form-control"
                placeholder="작품명을 입력하세요."
                style={{ width: `240px` }}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => {
                  e.key === "Enter" && getProductList();
                }}
              />
              <button
                className="btn btn-default btn-sm"
                type="button"
                onClick={() => getProductList()}
              >
                <i className="fa-solid fa-search"></i> 조회
              </button>
            </div>
            <button
              className="btn btn-primary btn-sm"
              type="button"
              onClick={() => goToRegister()}
            >
              작품 등록
            </button>
          </div>
          <div className="table-responsive">
            <table className="table table-hover table-nowrap">
              <thead>
                <tr>
                  <th>작품명</th>
                  <th>작가</th>
                  <th width="120px">카테고리</th>
                  <th width="70px">회차수</th>
                  <th width="80px">작품상태</th>
                  <th width="180px">등록일</th>
                </tr>
              </thead>
              <tbody>
                {productList &&
                  productList.map((product) => (
                    <tr key={product.id}>
                      <td>
                        <a onClick={goToProduct(product.id, product.step)}>
                          {product.title}
                        </a>
                      </td>
                      <td>
                        {product.nickname}({product.email})
                      </td>
                      <td>{product.category_title}</td>
                      <td>{product.total_episode}</td>
                      <td>{product.complete_yn === "N" ? "작성중" : "완료"}</td>
                      <td>{$dateTimezone(product.create_datetime)}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {totalPage > 1 && (
              <div className="d-flex justify-content-center mt-10">
                <nav aria-label="Page navigation example">
                  <ul className="pagination">
                    {pageNo > 5 && (
                      <li className="page-item">
                        <a
                          className="page-link"
                          onClick={(e) => setPageNo(pageNo - 1)}
                        >
                          <i className="fa-solid fa-arrow-left"></i>
                        </a>
                      </li>
                    )}

                    {pageRange.map((page) => (
                      <li key={page}>
                        <a
                          className={
                            pageNo === page ? "page-link active" : "page-link"
                          }
                          onClick={(e) => setPageNo(page)}
                        >
                          {page}
                        </a>
                      </li>
                    ))}

                    {lastPage < totalPage && (
                      <li className="page-item">
                        <a
                          className="page-link"
                          onClick={(e) => setPageNo(pageNo + 1)}
                        >
                          <i className="fa-solid fa-arrow-right"></i>
                        </a>
                      </li>
                    )}
                  </ul>
                </nav>
              </div>
            )}
          </div>
        </div>
      </div>

      {loading && <Loading />}

      {shareModalOpen && (
        <ShareModal onModal={setShareModalOpen} product={sharedProduct} />
      )}
    </main>
  );
}

export default NovelListAdmin;
