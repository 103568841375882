import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { $get, $post, $put, $convertNumberFormat } from "../utils/common";
import Swal from "sweetalert2";
import Loading from "../component/Loading";
import EpisodeCardModal from "../component/EpisodeCardModal";

function NovelSetEpisodeCardView(props) {
  const navigate = useNavigate();
  const [episodeCard, setEpisodeCard] = useState([]);
  const [episodeList, setEpisodeList] = useState([]);
  const [basicInfo, setBasicInfo] = useState({});
  const [episodeCardModalOpen, setEpisodeCardModalOpen] = useState(false);
  const [activeEpisode, setActiveEpisode] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");

  const getEpisodeCardList = async () => {
    const res = await $get(`/api/product/episode-card/${props.productId}`);
    if (res.status === 200) {
      setEpisodeCard(res.data);
      console.log(res.data);
      return res.data;
    } else {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "에피소드 조회 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  const getEpisodeList = async () => {
    const res = await $get(`/api/product/episode/${props.productId}`);
    if (res.status === 200) {
      setEpisodeList(res.data);
    }
  };

  const getBasicInfo = async (id) => {
    const res = await $get(`/api/product/${id}`);
    if (res.status === 200) {
      setBasicInfo(res.data);
    } else {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "작품 조회 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  const getUserPoints = async () => {
    const res = await $get("/api/user/points");
    if (res.status === 200) {
      return res.data.points;
    } else {
      return 0;
    }
  };

  const getDeductionPoints = async (funcCategory) => {
    const res = await $get(`/api/user/func/points/${funcCategory}`);
    if (res.status === 200) {
      return res.data.deduction_points;
    } else {
      return 0;
    }
  };

  const completeEpisodeCard = async () => {
    if (episodeCard.length === 0) {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "에피소드 구조 완료 실패",
        text: "에피소드가 없습니다.",
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }

    const notCompleteMessages = [];

    episodeCard.forEach((item) => {
      if (!item.title) {
        notCompleteMessages.push(`${item.episode_no}회차 제목`);
      }
      if (!item.content) {
        notCompleteMessages.push(`${item.episode_no}회차 내용`);
      }
    });

    if (notCompleteMessages.length > 0) {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "에피소드 구조 완료 실패",
        text: `${notCompleteMessages.join(
          ", "
        )}을(를) 입력해주세요. 에피소드 구조를 완료할 수 없습니다.`,
        showConfirmButton: true,
        timer: 2000,
      });
      return;
    }

    Swal.fire({
      iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
      title: "에피소드 구조 완료",
      text: "에피소드 구조를 완료하시겠습니까?",
      showCancelButton: true,
      confirmButtonText: "네, 완료합니다",
      cancelButtonText: "아니오",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $put(`/api/product/${props.productId}`, {
          step: 5,
        });

        const res2 = await $post("/api/product/episodes", {
          productId: props.productId,
          totalEpisode: episodeCard.length,
          episodeCardList: episodeCard,
        });

        if (res2.status === 200) {
          props.onList();
        } else {
          Swal.fire({
            iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
            title: "에피소드 구조 완료 실패",
            text: res2.message,
            showConfirmButton: false,
            timer: 1000,
          });
        }
      }
    });
  };

  const autoGenerateEpisodeCard = async (bRe, startEpisodeNo) => {
    if (bRe && startEpisodeNo) {
      const numberRegex = /^[0-9]*$/;
      if (!numberRegex.test(startEpisodeNo)) {
        Swal.fire({
          iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
          title: "에피소드 제안 실패",
          text: "숫자만 입력해주세요.",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }

      if (startEpisodeNo < 1) {
        Swal.fire({
          iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
          title: "에피소드 제안 실패",
          text: "1회차 보다 작을 수 없습니다.",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }

      if (startEpisodeNo > basicInfo.total_episode) {
        Swal.fire({
          iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
          title: "에피소드 제안 실패",
          text: "총 에피소드 회차보다 큰 회차 번호를 입력하셨습니다.",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }
    }

    let startIdx = episodeCard.length;
    if (bRe) {
      startIdx = startEpisodeNo - 1;
      // episodeCard에서 startIdx보다 큰 회차의 카드를 모두 삭제
      // const newEpisodeCard = [...episodeCard];
      // newEpisodeCard.splice(startIdx, episodeCard.length - startIdx);
      // setEpisodeCard([...newEpisodeCard]);
      // const res = await $post("/api/product/episode-cards", {
      //   productId: props.productId,
      //   episodeCardList: [...newEpisodeCard],
      // });
    }

    // 10개 단위로 제안 받기, startIdx가 0이면 0~9, 10이면 10~19, 20이면 20~29,
    // endIdx는 startIdx + 9이고, endIdx가 9보다 크면 9, 19보다 크면 19, 29보다 크면 29, endIdx가 total_episode보다 크면 total_episode로 설정
    let endIdx = Math.floor(startIdx / 10) * 10 + 9;

    if (endIdx > basicInfo.total_episode) {
      endIdx = basicInfo.total_episode - 1;
    }

    const deductionPoints = await getDeductionPoints("B");
    const userPoints = await getUserPoints();
    const needPoints = deductionPoints * (endIdx - startIdx + 1);
    if (userPoints < needPoints) {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: `잼이 부족합니다.`,
        text: `잼을 충전하시겠습니까?(나의 잼: ${$convertNumberFormat(
          userPoints
        )}, 필요 잼: ${$convertNumberFormat(needPoints)})`,
        showCancelButton: true,
        confirmButtonText: "네, 충전하겠습니다.",
        cancelButtonText: "아니오.",
      }).then(async (result) => {
        if (result.isConfirmed) {
          navigate("/payment");
        }
      });
      return;
    } else {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: `AI 에피소드 제안 받기`,
        text: `총${
          endIdx - startIdx + 1
        }회차 자동 생성에 ${$convertNumberFormat(
          needPoints
        )}잼이 사용됩니다. AI 에피소드 제안을 받으시겠어요?`,
        showCancelButton: true,
        confirmButtonText: "네, 제안해주세요",
        cancelButtonText: "아니오",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true);
          if (bRe) {
            // episodeCard에서 startIdx보다 큰 회차의 카드를 모두 삭제
            const newEpisodeCard = [...episodeCard];
            newEpisodeCard.splice(startIdx, episodeCard.length - startIdx);
            setEpisodeCard([...newEpisodeCard]);
            const res = await $post("/api/product/episode-cards", {
              productId: props.productId,
              episodeCardList: [...newEpisodeCard],
            });
          }
          for (let i = startIdx; i <= endIdx; i++) {
            setLoadingMessage(`${i + 1}회차 에피소드 제안 중...`);
            const res = await $post("/api/prompt/episode", {
              productId: props.productId,
              episodeNo: i + 1,
              totalEpisode: basicInfo.total_episode,
              step: 4,
            });
            if (res.status === 200) {
              prompt = res.data.prompt;
              getEpisodeCardList();
            } else {
              Swal.fire({
                iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
                title: `${i + 1}회차 에피소드 제안 실패`,
                text: res.message,
                showConfirmButton: false,
                timer: 1000,
              });
            }
          }
          setLoading(false);
        }
      });
    }
  };

  // const autoGenerateEpisodeCard = async (bRe) => {
  //   setLoading(true);

  //   let startIdx = bRe ? 0 : episodeCard.length;

  //   // 10개 단위로 제안 받기, startIdx가 0이면 0~9, 10이면 10~19, 20이면 20~29,
  //   // endIdx는 startIdx + 9이고, endIdx가 9보다 크면 9, 19보다 크면 19, 29보다 크면 29, endIdx가 total_episode보다 크면 total_episode로 설정
  //   let endIdx = Math.floor(startIdx / 10) * 10 + 9;

  //   if (endIdx > basicInfo.total_episode) {
  //     endIdx = basicInfo.total_episode - 1;
  //   }

  //   for (let i = startIdx; i <= endIdx; i++) {
  //     setLoadingMessage(`${i + 1}회차 에피소드 제안 중...`);
  //     const res = await $post("/api/prompt/episode", {
  //       productId: props.productId,
  //       episodeNo: i + 1,
  //       totalEpisode: parseInt(basicInfo.total_episode),
  //       step: 5,
  //     });
  //     // episode 생성 추가
  //     if (res.status === 200) {
  //       const res2 = await $post("/api/product/episode", {
  //         product_id: props.productId,
  //         episode_no: i + 1,
  //         title: res.data.title,
  //       });

  //       getEpisodeCardList();
  //       props.onList();
  //     } else {
  //       Swal.fire({
  //         iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
  //         title: `${i + 1}회차 에피소드 제안 실패`,
  //         text: res.message,
  //         showConfirmButton: false,
  //         timer: 1000,
  //       });
  //     }
  //   }

  //   setLoading(false);
  // };

  useEffect(() => {
    getBasicInfo(props.productId);
    getEpisodeList();
    getEpisodeCardList();
  }, []);

  return (
    <div className="content p-30 overflow-y">
      <div className="sub-title font-nanumMyeongjo mb-20">에피소드 카드</div>
      <div className="description">
        <div>
          <p>
            총 에피소드 {basicInfo.total_episode}개 중 {episodeCard.length}
            개를 생성하고 있습니다.
          </p>
          <p>에피소드 카드 생성 이후 카드 내용을 수정할 수 있습니다.</p>
        </div>
      </div>
      <div className="row">
        {episodeCard.map((episode, idx) => (
          <div className="col-xlg-2 col-lg-3 col-md-4" key={episode.id}>
            <div className="episode-card" key={episode.id}>
              <div className="episode-card-header">
                <div className="episode-card-header-title">
                  {episode.episode_no}회차
                  {episodeList &&
                  episodeList.filter(
                    (ep) =>
                      ep.episode_no === episode.episode_no &&
                      ep.complete_yn === "Y"
                  ).length > 0 ? (
                    ""
                  ) : (
                    <button
                      type="button"
                      className="btn btn-transparent btn-sm"
                      onClick={() => {
                        setActiveEpisode(episodeCard[idx]);
                        setEpisodeCardModalOpen(true);
                      }}
                    >
                      <i className="fa-solid fa-pen"></i>
                    </button>
                  )}
                </div>
                <div className="episode-card-header-subtitle">
                  {episode.title}
                </div>
              </div>
              <div className="episode-card-body">{episode.content}</div>
            </div>
          </div>
        ))}
        {!loading &&
          episodeCard.length > 0 &&
          episodeCard.length < basicInfo.total_episode && (
            <div className="col-xlg-2 col-lg-3 col-md-4 flex-column justify-content-center">
              <div className="episode-card">
                <div
                  className="episode-card-body-add"
                  onClick={() => {
                    autoGenerateEpisodeCard();
                  }}
                >
                  <i className="fa-solid fa-plus"></i>
                  <p>에피소드 이어서 계속 제안받기</p>
                </div>
              </div>
            </div>
          )}
      </div>
      <div className="button-list mt-30">
        {/* <button
          type="button"
          className="btn btn-default mr-10"
          onClick={() => props.onNext(-1)}
        >
          <i className="fa-solid fa-arrow-left"></i> 뒤로가기
        </button> */}
        {/* {!loading && episodeCard.length < basicInfo.total_episode && (
          <button
            type="button"
            className="btn btn-default mr-10"
            onClick={() => {
              autoGenerateEpisodeCard();
            }}
          >
            <i className="fa-solid fa-arrow-rotate-right"></i> 이어서 제안 받기
          </button>
        )} */}

        {!loading && episodeCard.length > 0 && (
          <button
            type="button"
            className="btn btn-default mr-10"
            onClick={() => {
              Swal.fire({
                iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
                title: "제안 받을 회차 번호를 입력하세요.",
                text: "입력된 회차 번호부터 다시 제안 받습니다.",
                input: "number",
                showCancelButton: true,
                confirmButtonText: "확인",
                cancelButtonText: "취소",
              }).then(async (result) => {
                if (result.isConfirmed) {
                  autoGenerateEpisodeCard(true, result.value);
                }
              });
            }}
          >
            <i className="fa-solid fa-arrow-rotate-right"></i> 다시 제안 받기
          </button>
        )}

        {!loading && episodeCard.length > 0 && (
          <button
            type="button"
            className="btn btn-primary mr-10"
            onClick={() => completeEpisodeCard()}
          >
            에피소드 설정 완료
          </button>
        )}
      </div>
      {episodeCardModalOpen && (
        <EpisodeCardModal
          episode={activeEpisode}
          onModal={() => {
            setEpisodeCardModalOpen(false);
            getEpisodeCardList();
          }}
        />
      )}
      {loading && <Loading message={loadingMessage} />}
    </div>
  );
}

export default NovelSetEpisodeCardView;
