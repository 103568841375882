import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// import { $get, $post, $put, $delete, $upload } from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import { $post, $put, $delete, $get, $dateTimezone } from "../utils/common";
// import WorkspaceData from "./Workspace.json";
import Swal from "sweetalert2";
import ShareModal from "../component/ShareModal";
import NovelSetBasic from "../component/NovelSetBasic";
import NovelSetCharacter from "../component/NovelSetCharacter";
import NovelSetTotalEpisode from "../component/NovelSetTotalEpisode";
import NovelSetEpisodeCard from "../component/NovelSetEpisodeCard";
import NovelStartGuide from "../component/NovelStartGuide";

function NovelCreation() {
  const navigate = useNavigate();
  const location = useLocation();
  const step = location.state.step;
  const id = location.state.id;

  const { isLoggedIn, userData } = useAuth();

  if (!isLoggedIn || !userData) {
    navigate("/");
  }

  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [productId, setProductId] = useState(null);

  const scrollRef = useRef(null);

  useEffect(() => {
    if (id === undefined) return;

    setProductId(id);
    setCurrentStep(step);
  }, [id]);

  return (
    <main className="container">
      <div className="content">
        <nav aria-label="breadcrumb" className="wizards mt-10">
          <ol className="breadcrumb" type="1">
            <li
              className={`breadcrumb-item ${
                currentStep === 1
                  ? "active"
                  : currentStep > 1
                  ? "completed"
                  : ""
              }`}
            >
              <a>
                <span className="circle mr-5">1</span> 작품 기본 정보 등록
              </a>
            </li>
            <li
              className={`breadcrumb-item ${
                currentStep === 2
                  ? "active"
                  : currentStep > 2
                  ? "completed"
                  : ""
              }`}
            >
              <a>
                <span className="circle mr-5">2</span> 캐릭터 설정
              </a>
            </li>
            <li
              className={`breadcrumb-item ${
                currentStep === 3
                  ? "active"
                  : currentStep > 3
                  ? "completed"
                  : ""
              }`}
            >
              <a>
                <span className="circle mr-5">3</span> 회차 설정
              </a>
            </li>
            <li
              className={`breadcrumb-item ${
                currentStep === 4
                  ? "active"
                  : currentStep > 4
                  ? "completed"
                  : ""
              }`}
            >
              <a>
                <span className="circle mr-5">4</span> 에피소드 구조 설정
              </a>
            </li>
            <li
              className={`breadcrumb-item ${
                currentStep === 5
                  ? "active"
                  : currentStep > 5
                  ? "completed"
                  : ""
              }`}
            >
              <a>
                <span className="circle mr-5">5</span> 원고 집필 시작
              </a>
            </li>
          </ol>
        </nav>
        <div className="content-body align-items-center" ref={scrollRef}>
          {currentStep === 1 && (
            <NovelSetBasic
              productId={productId}
              onSetProductId={(pId) => setProductId(pId)}
              onNext={(e) => setCurrentStep(currentStep + 1)}
            />
          )}
          {currentStep === 2 && (
            <NovelSetCharacter
              productId={productId}
              onNext={(step) => setCurrentStep(currentStep + step)}
            />
          )}
          {currentStep === 3 && (
            <NovelSetTotalEpisode
              productId={productId}
              onNext={(step) => setCurrentStep(currentStep + step)}
            />
          )}
          {currentStep === 4 && (
            <NovelSetEpisodeCard
              productId={productId}
              onNext={(step) => setCurrentStep(currentStep + step)}
            />
          )}
          {currentStep === 5 && (
            <NovelStartGuide
              productId={productId}
              onNext={(step) => setCurrentStep(currentStep + step)}
            />
          )}
        </div>

        <div className="content-foot"></div>
      </div>
    </main>
  );
}

export default NovelCreation;
