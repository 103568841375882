import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../login/AuthProvider";
import {
  $post,
  $upload,
  $put,
  $delete,
  $get,
  $dateTimezone,
} from "../utils/common";
import Swal from "sweetalert2";
import Loading from "../component/Loading";

function FaqRegistration() {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const navigate = useNavigate();

  const { isLoggedIn, userData, checkLogin } = useAuth();

  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { faqId } = location.state || { faqId: null };
  const [faq, setFaq] = useState({
    title: "",
    content: "",
    category: "",
    active_yn: "Y",
  });
  const [categoryList, setCategoryList] = useState([]);

  const getCategoryList = async () => {
    const res = await $get(`/api/faq/category`);
    if (res.status === 200) {
      setCategoryList(res.data);
      // setFaq({ ...faq, category: res.data[0].id });
      getFaqDetail(res.data);
    } else {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "카테고리 조회 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  const saveFaq = async () => {
    if (!faq.title) {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "제목을 입력해주세요.",
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }

    if (!faq.content) {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "내용을 입력해주세요.",
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }

    let res = null;
    Swal.fire({
      iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
      title: "FAQ 저장",
      text: "FAQ을 저장하시겠습니까?",
      showCancelButton: true,
      confirmButtonText: "예",
      cancelButtonText: "아니오",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (faqId) {
          const tempFaq = { ...faq };
          delete tempFaq.id;
          delete tempFaq.create_datetime;
          res = await $put(`/api/faq/${faqId}`, {
            ...tempFaq,
            // content: faq.content.replace(/\n/g, "<br>"),
          });
        } else {
          res = await $post("/api/faq", {
            ...faq,
            // content: faq.content.replace(/\n/g, "<br>"),
          });
        }

        if (res.status === 200) {
          Swal.fire({
            iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
            title: "저장되었습니다.",
            showConfirmButton: false,
            timer: 1000,
          });
          navigate("/faq");
        } else {
          Swal.fire({
            iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
            title: "저장 실패",
            text: res.message,
            showConfirmButton: false,
            timer: 1000,
          });
        }
      }
    });
  };

  const getFaqDetail = async (categories) => {
    if (faqId === null) {
      setFaq({ ...faq, category: categories[0].id });
      return;
    }

    const res = await $get(`/api/faq/${faqId}`);
    if (res.status === 200) {
      // const tempFaq = res.data;
      // setFaq({ ...tempFaq, content: res.data.content.replace(/<br>/g, "\n") });
      setFaq(res.data);
    } else {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "팝업 조회 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  useEffect(() => {
    if (userData && userData.role_level !== 0) {
      navigate("/401");
    }
  }, [userData]);

  useEffect(() => {
    getCategoryList();
  }, []);

  return (
    <main className="container">
      <div className="content">
        <div className="content-body align-items-start">
          <div className="sub-title font-nanumMyeongjo mb-30">FAQ 추가</div>
          <div className="form-group">
            <label className="form-label">제목</label>
            <input
              type="text"
              className="form-control"
              value={faq.title}
              onChange={(e) => setFaq({ ...faq, title: e.target.value })}
            />
          </div>
          {/* 카테고리 */}
          <div className="form-group">
            <label className="form-label">분류</label>
            <select
              className="form-control"
              value={faq.category}
              onChange={(e) => setFaq({ ...faq, category: e.target.value })}
            >
              {categoryList.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.category_title}
                </option>
              ))}
            </select>
          </div>
          {/* 공개 여부 */}
          <div className="form-group">
            <label className="form-label">공개 여부</label>
            <select
              className="form-control"
              value={faq.active_yn}
              onChange={(e) => setFaq({ ...faq, active_yn: e.target.value })}
            >
              <option value="Y">공개</option>
              <option value="N">비공개</option>
            </select>
          </div>
          <div className="form-group">
            <label className="form-label">내용</label>
            <textarea
              className="form-control"
              rows="10"
              value={faq.content}
              onChange={(e) => setFaq({ ...faq, content: e.target.value })}
            />
            {/* <div
              contentEditable={true}
              style={{ width: "100%", height: "100px" }}
            >
              <p>
                <br />
              </p>
            </div> */}
          </div>

          <div
            className="flex justify-content-center"
            style={{ width: "100%" }}
          >
            <button className="btn btn-primary mr-10" onClick={saveFaq}>
              저장
            </button>
            <button
              className="btn btn-default"
              onClick={() => navigate("/faq")}
            >
              목록
            </button>
          </div>
        </div>
      </div>

      {loading && <Loading />}
    </main>
  );
}

export default FaqRegistration;
