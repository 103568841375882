import "./App.css";
import "./404.css";

import React, { useState, useRef } from "react";
import Nav from "./layout/Nav";
import Footer from "./layout/Footer";
import Home from "./pages/Home";
import HomeMobile from "./pages/HomeMobile";
import Story from "./pages/Story";
import Novel from "./pages/Novel";
import NovelList from "./pages/NovelList";
import NovelListAdmin from "./pages/NovelListAdmin";
import NovelRegistration from "./pages/NovelRegistration";
import Profile from "./pages/Profile";
import Question from "./pages/Question";
import QuestionAdmin from "./pages/QuestionAdmin";
import QuestionAnswer from "./pages/QuestionAnswer";
import ReviewAdmin from "./pages/ReviewAdmin";
import SystemPopup from "./pages/SystemPopup";
import SystemPopupRegistration from "./pages/SystemPopupRegistration";
import Faq from "./pages/Faq";
import FaqAdmin from "./pages/FaqAdmin";
import FaqRegistration from "./pages/FaqRegistration";
import TokenUsage from "./pages/TokenUsage";
import Payment from "./pages/Payment";
import Order from "./pages/Order";
import OrderSuccess from "./pages/OrderSuccess";
import OrderFail from "./pages/OrderFail";
import UserList from "./pages/UserList";
import UserDetail from "./pages/UserDetail";
import PointAdmin from "./pages/PointAdmin";
import PaymentAdmin from "./pages/PaymentAdmin";
import PaymentList from "./pages/PaymentList";
import WaitingList from "./pages/WaitingList";
import PageNotFound from "./pages/404";
import NotAuthorized from "./pages/401";
import InternalServerError from "./pages/500";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AuthProvider from "./login/AuthProvider";

function App() {
  const navRef = useRef(null);

  const handleRegisterButton = () => {
    // alert("회원가입 버튼 클릭");
    navRef.current.doRegister();
  };

  const mobileCheck = () => {
    if (
      /iPhone|Android/i.test(navigator.userAgent) &&
      window.innerWidth < 1280
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <BrowserRouter>
      <AuthProvider>
        <div className="App">
          {
            /* 모바일 기기 체크 */
            // userAgent: navigator.userAgent,
            // isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
          }
          {mobileCheck() && <HomeMobile />}

          {!mobileCheck() && <Nav ref={navRef} />}

          {!mobileCheck() && (
            <Routes>
              <Route
                path="/"
                element={<Home onRegisterButtonClick={handleRegisterButton} />}
              />
              <Route
                path="/story"
                element={<Story onRegisterButtonClick={handleRegisterButton} />}
              />
              <Route path="/novel-list" element={<NovelList />} />
              <Route path="/novel-list-admin" element={<NovelListAdmin />} />
              <Route path="/novel-register" element={<NovelRegistration />} />
              <Route path="/novel" element={<Novel />} />
              <Route path="/question" element={<Question />} />
              <Route path="/question-admin" element={<QuestionAdmin />} />
              <Route path="/question-answer" element={<QuestionAnswer />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/faq-admin" element={<FaqAdmin />} />
              <Route path="/faq-register" element={<FaqRegistration />} />
              <Route path="/review-admin" element={<ReviewAdmin />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/payment" element={<Payment />} />
              <Route path="/order/:price" element={<Order />} />
              <Route path="/order-success" element={<OrderSuccess />} />
              <Route path="/order-fail" element={<OrderFail />} />
              <Route path="/payment-list" element={<PaymentList />} />
              <Route path="/user-list" element={<UserList />} />
              <Route path="/user-detail" element={<UserDetail />} />
              <Route path="/system-popup" element={<SystemPopup />} />
              <Route path="/point-admin" element={<PointAdmin />} />
              <Route path="/payment-admin" element={<PaymentAdmin />} />
              <Route
                path="/system-popup-register"
                element={<SystemPopupRegistration />}
              />
              <Route path="/token-usage" element={<TokenUsage />} />
              <Route path="/waiting-list" element={<WaitingList />} />
              <Route path="/404" element={<PageNotFound />} />
              <Route path="/401" element={<NotAuthorized />} />
              <Route path="/500" element={<InternalServerError />} />
            </Routes>
          )}
          {!mobileCheck() && <Footer />}
        </div>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
