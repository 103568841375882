import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  $post,
  $put,
  $delete,
  $get,
  $dateTimezone,
  $convertNumberFormat,
} from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import SystemPopupModal from "../component/SystemPopupModal";
import Swal from "sweetalert2";
import GLightbox from "glightbox";
import "glightbox/dist/css/glightbox.min.css";

function Home({ onRegisterButtonClick }) {
  const navigate = useNavigate();
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const { isLoggedIn, userData, login, logout } = useAuth();
  const [popupModalOpen, setPopupModalOpen] = useState(false);
  const [activePopup, setActivePopup] = useState({});
  const [totalActiveUserCount, setTotalActiveUserCount] = useState(0);
  const [totalProductCount, setTotalProductCount] = useState(0);
  const [totalEpisodeCount, setTotalEpisodeCount] = useState(0);
  const [reviewList, setReviewList] = useState([]);

  const slickSettings = {
    fade: true,
    // dots: true,
    infinite: true,
    autoplay: true,
    // speed: 5000,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
    centerMode: true,
    // width: 600,
    className: "carousel",
    // adaptiveHeight: true,
  };

  const getSystemModalList = async () => {
    const currentDateTime = new Date()
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    const res = await $get(`/api/popup/now/${currentDateTime}`);
    if (res.status === 200) {
      if (res.data.length > 0) {
        const localStorageKey = `popup-${res.data[0].id}`;
        const localStorageValue = localStorage.getItem(localStorageKey);
        if (
          localStorageValue !== null &&
          localStorageValue !== undefined &&
          localStorageValue === "false"
        )
          return;
        setActivePopup(res.data[0]);
        setPopupModalOpen(true);
      }
    }
  };

  const getStatus = async () => {
    const res = await $get(`/api/bwriter-status`);
    if (res.status === 200) {
      setTotalActiveUserCount(res.data.userCount);
      setTotalProductCount(res.data.productCount);
      setTotalEpisodeCount(res.data.episodeCount);
    }
  };

  const mobileCheck = () => {
    const mobileCheck =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );

    // 해상도 모바일 체크
    if (mobileCheck || window.innerWidth < 768) {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "모바일 사용 불가",
        text: "모바일에서는 사용이 불가합니다. PC에서 사용하세요.",
        showConfirmButton: true,
      });
      return true;
    } else {
      return false;
    }
  };

  const moveToNovelList = () => {
    if (mobileCheck()) return;

    if (userData && userData.email) {
      navigate("/novel-list");
    } else {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "로그인이 필요합니다.",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const getReviewList = async () => {
    const res = await $get(`/api/review`);
    if (res.status === 200) {
      setReviewList(res.data);
    } else {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "리뷰 조회 실패",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  useEffect(() => {
    getStatus();
    getSystemModalList();
    getReviewList();

    const lightbox = GLightbox({
      selector: ".glightbox",
    });

    return () => lightbox.destroy();
  }, []);
  return (
    // <div className="home"></div>
    <main className="container">
      <div className="content">
        {/* <section className="mb-20"> */}
        <div className="position-relative mb-20">
          <img
            src="./assets/images/b-writer-video.png"
            alt="video"
            className="img-fluid"
            width="100%"
          />
          <a
            href="https://youtu.be/_iQYvvTd2mM?si=HGuN01OmzQGeMaAl"
            className="play-btn glightbox position-absolute top-50 start-50 translate-middle icon-shape icon-xl rounded-circle text-primary"
            style={{ backgroundColor: "var(--bread-primary-dark1)" }}
          >
            <i className="fa-solid fa-play"></i>
          </a>
        </div>
        {/* </section> */}
        <section
          style={{ backgroundColor: "rgb(250,249,247)" }}
          className="mb-20"
        >
          <div className="section-title font-nanumMyeongjo">
            현재 {$convertNumberFormat(totalActiveUserCount)}명의 작가님들이
            작품을 집필하고 있어요
          </div>
          <div className="section-description">
            <p>
              비·라이터 안에서 {$convertNumberFormat(totalProductCount)}개의
              이야기 작품과 {$convertNumberFormat(totalEpisodeCount)}개의
              에피소드가 정성껏 집필되고 있으며,
            </p>
            {/* <p>
              에피소드 한 편 당 집필 시작부터 완료까지 평균 10.4분의 시간이
              소요되고 있어요.
            </p> */}
            <p>
              기술의 도움을 받아, 많은 작가님들의 글쓰는 시간이 단축되고
              있답니다.
            </p>
          </div>
          <div className="section-button">
            <button
              className="btn btn-lg btn-default"
              onClick={() => moveToNovelList()}
            >
              작품 집필하기
            </button>
          </div>
        </section>
        <section
          style={{ backgroundColor: "rgb(254,250,250)" }}
          className="mb-20"
        >
          <div className="section-title font-nanumMyeongjo">
            이제 비·라이터를 통해 작가가 되세요
          </div>
          <div className="section-description">
            <p>아이디어만 있으면 누구나 작가가 될 수 있어요.</p>
            <p>비·라이터는 아이디어가 있는 사람들이 보다 쉽게 글로 이야기를</p>
            <p>풀어내도록 돕는 저작 툴입니다.</p>
            <p>자신만의 글을 써내려가세요, 기술은 그저 거들 뿐입니다.</p>
          </div>
          <div className="section-button">
            <button
              className="btn btn-lg btn-default"
              onClick={() => navigate("/story")}
            >
              자세히 알아보기
            </button>
            <button
              className="btn btn-lg btn-default"
              onClick={onRegisterButtonClick}
            >
              작가 가입하기
            </button>
          </div>
        </section>

        <section className="mb-20" style={{ position: "relative" }}>
          {userData && userData.role_level === 0 && (
            <div className="section-admin-button">
              <button
                className="btn btn btn-transparent"
                onClick={() => navigate("/review-admin")}
              >
                <i className="fa-regular fa-pen-to-square"></i>
              </button>
            </div>
          )}
          <Slider {...slickSettings}>
            {reviewList.map((review, index) => (
              <div className="carousel-item" key={review.id}>
                <div className="carousel-item-img">
                  <img
                    className="user-avatar"
                    src={
                      review.img_url
                        ? `${serverUrl}/static/images/${review.img_url}`
                        : "./assets/images/default-profile.jpg"
                    }
                    alt="avatar"
                  />
                </div>
                <div className="carousel-item-message font-nanumMyeongjo">
                  <p>{review.paragraph1}</p>
                  <p>{review.paragraph2}</p>
                  <p>{review.paragraph3}</p>
                </div>
                <div className="carousel-item-name">- {review.reviewer}</div>
              </div>
            ))}
          </Slider>
        </section>
        <section className="mb-20">
          <div className="section-title2 font-nanumMyeongjo">
            <div className="title">바로 자신만의 글을 써내려가세요</div>
            <div className="sub-title">기술은 그저 거들 뿐!</div>
          </div>
          <div className="section-item">
            <div className="section-item-desc">
              <div className="section-item-title">
                1. 아이디어를 간단히 기록합니다
              </div>
              <div className="section-item-description">
                <p>초기 아이디어를 간단한 글로 옮깁니다.</p>
                <p>
                  이야기의 제목, 캐릭터, 세계관, 시놉시스 등을 기록하여 첫 발을
                  내딛어보세요.
                </p>
                <p>
                  당장 구체적인 생각이 없어도 제안받을 수 있으니 괜찮습니다.
                </p>
              </div>
            </div>
            <div className="section-item-buttons">
              {/* <img
                src="./assets/images/home_1_1.png"
                alt="캐릭터를 제안해줘요!"
                style={{ width: "100%" }}
              /> */}
              <button className="btn btn-main">
                <span>캐릭터를</span>
                <span>제안해줘요!</span>
                <img
                  src="./assets/images/character-icon.png"
                  className="mt-20"
                  style={{ width: "50px", height: "auto" }}
                />
              </button>
              <button className="btn btn-main">
                <span>에피소드를</span>
                <span>자동생성 해줘요!</span>
                <img
                  src="./assets/images/episode-icon.png"
                  className="mt-20"
                  style={{ width: "50px", height: "auto" }}
                />
                {/* <i className="fa-solid fa-clapperboard"></i> */}
              </button>
            </div>
          </div>
          <div className="section-item">
            <div className="section-item-desc">
              <div className="section-item-title">
                2. 에피소드 구성을 다듬습니다
              </div>
              <div className="section-item-description">
                <p>비·라이터가 제안하는 에피소드 초안에</p>
                <p>작가님의 터치를 더해 완성도를 높일 수 있습니다.</p>
              </div>
            </div>
            <div className="section-item-buttons">
              <button
                className="btn btn-main"
                style={{ backgroundColor: "#fefcf9", borderColor: "#e5d8c8" }}
              >
                <span>에피소드를</span>
                <span>다듬어요!</span>
                <img
                  src="./assets/images/episode-icon-2.png"
                  className="mt-20"
                  style={{
                    width: "46px",
                    height: "auto",
                  }}
                />
                {/* <i className="fa-solid fa-file-pen"></i> */}
              </button>
              <button className="btn btn-main">
                <span>줄거리를</span>
                <span>제안해줘요!</span>
                <img
                  src="./assets/images/episode-icon-3.png"
                  className="mt-20"
                  style={{ width: "42px", height: "auto" }}
                />
                {/* <i className="fa-solid fa-book"></i> */}
              </button>
            </div>
          </div>
          <div className="section-item">
            <div className="section-item-desc">
              <div className="section-item-title">
                3. 각 에피소드의 원고를 집필합니다
              </div>
              <div className="section-item-description">
                <p>
                  각 에피소드마다 하나하나 써 내려가며 이야기를 완성시키세요.
                </p>
                <p>
                  지루한 과정일 수 있지만 비·라이터는 작가님 혼자 두지 않습니다.
                </p>
                <p>인공지능이 보조하거나 다른 작가와 협업도 가능하답니다.</p>
              </div>
            </div>
            <div className="section-item-buttons">
              <button
                className="btn btn-main"
                style={{ backgroundColor: "#fefcf9", borderColor: "#e5d8c8" }}
              >
                <span>다른 사람과</span>
                <span>공동집필 가능해요</span>
                <img
                  src="./assets/images/together-icon.png"
                  className="mt-20"
                  style={{ width: "46px", height: "auto" }}
                />
                {/* <i className="fa-solid fa-comments"></i> */}
              </button>
              <button className="btn btn-main">
                <span>막히면</span>
                <span>이어서 써줘요</span>
                <img
                  src="./assets/images/write-icon.png"
                  className="mt-20"
                  style={{ width: "46px", height: "auto" }}
                />
                {/* <i className="fa-solid fa-wand-magic-sparkles"></i> */}
              </button>
            </div>
          </div>
          <div className="section-item" style={{ borderBottom: "none" }}>
            <div className="section-item-desc">
              <div className="section-item-title">
                4. 작품을 완성하고 발행합니다
              </div>
              <div className="section-item-description">
                <p>글을 마무리하고 세상에 소개합니다.</p>
                <p>
                  완성된 글을 도큐먼트 파일로 저장하거나 PDF로 다운로드할 수
                  있고,
                </p>
                <p>
                  바로 브레드 플랫폼에 작품을 게시하여 독자를 모을 수 있습니다.
                </p>
              </div>
            </div>
            <div className="section-item-buttons">
              <button
                className="btn btn-main"
                style={{ backgroundColor: "#fefcf9", borderColor: "#e5d8c8" }}
              >
                <span>원고를</span>
                <span>PDF로 저장해요</span>
                <img
                  src="./assets/images/pdf-icon.png"
                  className="mt-20"
                  style={{ width: "40px", height: "auto" }}
                />
                {/* <i className="fa-solid fa-file-pdf"></i> */}
              </button>
              <button
                className="btn btn-main"
                style={{ backgroundColor: "#fefcf9", borderColor: "#e5d8c8" }}
              >
                <span>작품을</span>
                <span>바로 발행해요</span>
                <img
                  src="./assets/images/publish-icon.png"
                  className="mt-20"
                  style={{ width: "46px", height: "auto" }}
                />
                {/* <i className="fa-solid fa-desktop"></i> */}
              </button>
            </div>
          </div>
        </section>
        <section
          style={{ backgroundColor: "rgb(166,107,56)" }}
          className="mb-20"
        >
          <div
            className="section-title font-nanumMyeongjo"
            style={{ color: "var(--bread-white)" }}
          >
            텍스처와 작가 계약을 하세요
          </div>
          <div
            className="section-description"
            style={{ color: "var(--bread-white)" }}
          >
            <p style={{ lineHeight: "2.1rem" }}>
              이미 이야기 작품을 보유하고 있거나 작품의 집필을 완료하신 분은
              시놉시스와 원고를 보내주세요. 텍스처와 계약을 한 이후, 작품에 맞는
              출판사 혹은 '브레드'를 비롯한 콘텐츠 플랫폼을 연결해드립니다.
              작가들의 소중한 IP자산의 케어는 물론, 2차 저작 연결까지
              책임집니다.
            </p>
          </div>
          <div className="section-button">
            <a
              className="btn btn-lg btn-default"
              href="https://form.typeform.com/to/KSl9oAKs"
              target="_blank"
            >
              작가 신청하기
            </a>
            <a
              className="btn btn-lg btn-default"
              href="https://www.b-read.kr/"
              target="_blank"
            >
              브레드 알아보기
            </a>
          </div>
        </section>
      </div>

      {popupModalOpen && (
        <SystemPopupModal
          popupId={activePopup.id}
          preview={false}
          onModal={setPopupModalOpen}
        />
      )}
    </main>
  );
}

export default Home;
