import React, { useState, useEffect, useRef } from "react";
import { $get, $put, $post, $convertNumberFormat } from "../utils/common";
import { useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import BeatLoader from "react-spinners/BeatLoader";

function SummaryModal(props) {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const navigate = useNavigate();
  const [assistantSummary, setAssistantSummary] = useState(
    props.episode.suggest_summary
  );
  const [assistantSummarize, setAssistantSummarize] = useState(
    props.episode.summary
  );
  const [loading, setLoading] = useState(false);
  const assistantScrollRef = useRef(null);

  let bProgress = false;

  const getUserPoints = async () => {
    const res = await $get("/api/user/points");
    if (res.status === 200) {
      return res.data.points;
    } else {
      return 0;
    }
  };

  const getDeductionPoints = async (funcCategory) => {
    const res = await $get(`/api/user/func/points/${funcCategory}`);
    if (res.status === 200) {
      return res.data.deduction_points;
    } else {
      return 0;
    }
  };

  const generateSummary = async () => {
    const deductionPoints = await getDeductionPoints("C");
    Swal.fire({
      iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
      title: "줄거리 제안 받기",
      text: `${$convertNumberFormat(
        deductionPoints
      )}잼이 사용됩니다. 줄거리를 제안 받으시겠습니까?`,
      showCancelButton: true,
      confirmButtonText: "예",
      cancelButtonText: "아니오",
    }).then(async (result) => {
      if (result.isConfirmed) {
        getSummary();
      }
    });
  };

  const getSummary = async () => {
    if (bProgress) return;

    const deductionPoints = await getDeductionPoints("C");
    const userPoints = await getUserPoints();
    if (userPoints < deductionPoints) {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: `잼이 부족합니다.`,
        text: `잼을 충전하시겠습니까?(나의 잼: ${$convertNumberFormat(
          userPoints
        )}, 필요 잼: ${$convertNumberFormat(deductionPoints)})`,
        showCancelButton: true,
        confirmButtonText: "네, 충전하겠습니다.",
        cancelButtonText: "아니오.",
      }).then(async (result) => {
        if (result.isConfirmed) {
          navigate("/payment");
        }
      });
      return;
    } else {
      bProgress = true;
      setLoading(true);
      setAssistantSummary("");
      sessionStorage.setItem("stopAssistant", false);

      let url = `${serverUrl}/api/prompt/summary`;

      const res = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          productId: props.episode.product_id,
          episodeNo: props.episode.episode_no,
        }),
      });

      const reader = res.body.pipeThrough(new TextDecoderStream()).getReader();
      const tempAssistantMessage = [];
      const finishRegexp = /(\[\[.*?\]\])/;
      let finishReason = "";
      while (true) {
        if (sessionStorage.getItem("stopAssistant") === "true") {
          reader.cancel();
          reader.releaseLock();
          break;
        }

        let { value, done } = await reader.read();
        if (done) break;

        value = value.replace(/(?:\r\n|\r|\n)/g, "<br />");
        if (finishRegexp.test(value)) {
          finishReason = value.match(finishRegexp)[0];
          value = "";
        }

        setAssistantSummary((prev) => prev + value);
        tempAssistantMessage.push(value);
      }

      sessionStorage.setItem("stopAssistant", false);

      const result = tempAssistantMessage.join("").split("<br />");
      const resHtml = [];
      result.forEach((item) => {
        resHtml.push(`<p>${item}</p>`);
      });

      setAssistantSummary(resHtml.join(""));

      bProgress = false;
      setLoading(false);
    }
  };

  const getSummarize = async () => {
    if (bProgress) return;
    bProgress = true;
    setLoading(true);
    setAssistantSummarize("");
    sessionStorage.setItem("stopAssistant", false);

    let url = `${serverUrl}/api/prompt/summarize`;

    const res = await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        productId: props.episode.product_id,
        episodeNo: props.episode.episode_no,
      }),
    });

    const reader = res.body.pipeThrough(new TextDecoderStream()).getReader();
    const tempAssistantMessage = [];
    const finishRegexp = /(\[\[.*?\]\])/;
    let finishReason = "";
    while (true) {
      if (sessionStorage.getItem("stopAssistant") === "true") {
        reader.cancel();
        reader.releaseLock();
        break;
      }

      let { value, done } = await reader.read();
      if (done) break;

      value = value.replace(/(?:\r\n|\r|\n)/g, "<br />");
      if (finishRegexp.test(value)) {
        finishReason = value.match(finishRegexp)[0];
        value = "";
      }

      setAssistantSummarize((prev) => prev + value);
      tempAssistantMessage.push(value);
    }

    sessionStorage.setItem("stopAssistant", false);

    const result = tempAssistantMessage.join("").split("<br />");
    const resHtml = [];
    result.forEach((item) => {
      resHtml.push(`<p>${item}</p>`);
    });

    setAssistantSummarize(resHtml.join(""));
    saveSummarize(resHtml.join(""));

    bProgress = false;
    setLoading(false);
  };

  const saveSummary = async () => {
    const res = await $put(`/api/product/episode/${props.episode.id}`, {
      suggest_summary: assistantSummary,
    });

    if (res.status === 200) {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "저장 완료",
        showConfirmButton: false,
        timer: 1000,
      });
      props.onSummary(assistantSummary);
    } else {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "저장 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  const saveSummarize = async (episodeSummarize) => {
    const res = await $put(`/api/product/episode/${props.episode.id}`, {
      summary: episodeSummarize,
    });

    if (res.status === 200) {
      // props.onSummarize(episodeSummarize);
    } else {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "저장 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  useEffect(() => {
    if (!props.episode.suggest_summary && props.episode.complete_yn === "N") {
      getSummary();
    }
    if (!props.episode.summary && props.episode.complete_yn === "Y") {
      getSummarize();
    }
  }, []);

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="modal-header">
          {!loading && props.episode.complete_yn === "N" && (
            <span className="close" onClick={() => props.onModal(false)}>
              &times;
            </span>
          )}

          {!loading && props.episode.complete_yn === "Y" && (
            <span
              className="close"
              onClick={() => props.onSummarize(assistantSummarize)}
            >
              &times;
            </span>
          )}
          <h3>
            {props.episode.complete_yn === "Y" ? "실제 줄거리" : "줄거리 제안"}
          </h3>
        </div>

        {props.episode.complete_yn === "N" && (
          <div
            className="modal-body"
            ref={assistantScrollRef}
            dangerouslySetInnerHTML={{ __html: assistantSummary }}
          ></div>
        )}

        {props.episode.complete_yn === "Y" && (
          <div
            className="modal-body"
            dangerouslySetInnerHTML={{ __html: assistantSummarize }}
          ></div>
        )}

        <div className="modal-footer flex justify-content-end">
          {loading && (
            <div className="pl-20">
              <BeatLoader color="#ae7e55" />
            </div>
          )}
          {!loading && props.episode.complete_yn === "N" && (
            <button
              type="button"
              className="btn btn-default"
              onClick={() => generateSummary()}
            >
              줄거리 제안 받기
            </button>
          )}

          {!loading && props.episode.complete_yn === "Y" && (
            <button
              type="button"
              className="btn btn-default"
              onClick={() => getSummarize()}
            >
              실제 줄거리 요약
            </button>
          )}

          {!loading && props.episode.complete_yn === "N" && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={(e) => saveSummary()}
            >
              저장
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default SummaryModal;
