import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// import { $get, $post, $put, $delete, $upload } from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import {
  $post,
  $put,
  $delete,
  $get,
  $dateTimezone,
  $dateFormat,
  $convertNumberFormat,
} from "../utils/common";
// import WorkspaceData from "./Workspace.json";
import Swal from "sweetalert2";
import Loading from "../component/Loading";
import PointUsageHistoryModal from "../component/PointUsageHistoryModal";

function UserDetail() {
  const navigate = useNavigate();
  const location = useLocation();

  const { isLoggedIn, userData, checkLogin } = useAuth();
  const { email } = location.state || { email: null };

  const [user, setUser] = useState(null);
  const [tokenList, setTokenList] = useState([]);
  const [pageLimit, setPageLimit] = useState(5);
  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageRange, setPageRange] = useState([]);
  const [lastPage, setLastPage] = useState(1);

  const [pageLimit2, setPageLimit2] = useState(10);
  const [pageNo2, setPageNo2] = useState(1);
  const [totalPage2, setTotalPage2] = useState(1);
  const [pageRange2, setPageRange2] = useState([]);
  const [lastPage2, setLastPage2] = useState(1);
  const [loading, setLoading] = useState(false);
  const [paymentList, setPaymentList] = useState([]);
  const [pointUsageList, setPointUsageList] = useState([]);
  const [userPoints, setUserPoints] = useState(0);
  const [showPointUsageHistoryModal, setShowPointUsageHistoryModal] =
    useState(false);
  const [activeDate, setActiveDate] = useState("");

  const scrollRef = useRef(null);

  const paging = () => {
    const pages = [];

    const pageFirst =
      pageNo % 5 === 0 ? pageNo - 4 : Math.floor(pageNo / 5) * 5 + 1;
    let pageLast = pageFirst + 4;

    if (pageLast > totalPage) pageLast = totalPage;
    setLastPage(pageLast);

    for (let i = pageFirst; i <= pageLast; i++) {
      pages.push(i);
    }
    setPageRange(pages);
  };

  const paging2 = () => {
    const pages = [];

    const pageFirst =
      pageNo2 % 5 === 0 ? pageNo2 - 4 : Math.floor(pageNo2 / 5) * 5 + 1;
    let pageLast = pageFirst + 4;

    if (pageLast > totalPage2) pageLast = totalPage2;
    setLastPage2(pageLast);

    for (let i = pageFirst; i <= pageLast; i++) {
      pages.push(i);
    }
    setPageRange2(pages);
  };

  const changePaginationPayment = async () => {
    const res = await $get(`/api/payment/${email}/${pageNo}/${pageLimit}`);
    if (res.status === 200) {
      setPaymentList(res.data.list);
      setTotalPage(Math.ceil(res.data.total_count / pageLimit));
      paging();
    }
  };

  const changePaginationPointUsage = async () => {
    const res = await $get(`/api/point/${email}/${pageNo2}/${pageLimit2}`);
    if (res.status === 200) {
      setPointUsageList(res.data.points);
      setTotalPage2(Math.ceil(res.data.total_count / pageLimit2));
      paging2();
    }
  };

  const getUserDetail = async () => {
    setLoading(true);
    const res = await $get(`/api/user/${email}`);
    if (res.status === 200) {
      setUser(res.data);
    } else {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "사용자 조회 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
    setLoading(false);
  };

  const updateUser = async () => {
    setLoading(true);
    const res = await $put(`/api/user/status/${email}`, {
      active_yn: user.active_yn,
    });
    if (res.status === 200) {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "사용자 수정 성공",
        showConfirmButton: false,
        timer: 1000,
      });
    } else {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "사용자 수정 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
    setLoading(false);
  };

  const updatePoints = async () => {
    Swal.fire({
      iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
      title: "잼(포인트) 수정",
      text: "잼(포인트)을 수정하시겠습니까?",
      showCancelButton: true,
      confirmButtonText: "네, 수정하겠습니다.",
      cancelButtonText: "아니오",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const res = await $put(`/api/user/${email}`, {
          points: user.points,
        });
        if (res.status === 200) {
          Swal.fire({
            iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
            title: "잼(포인트) 수정 성공",
            showConfirmButton: false,
            timer: 1000,
          });
        } else {
          Swal.fire({
            iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
            title: "잼(포인트) 수정 실패",
            text: res.message,
            showConfirmButton: false,
            timer: 1000,
          });
        }
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    paging();
  }, [totalPage]);

  useEffect(() => {
    changePaginationPayment(pageNo);
  }, [pageNo]);

  useEffect(() => {
    paging2();
  }, [totalPage2]);

  useEffect(() => {
    changePaginationPointUsage(pageNo2);
  }, [pageNo2]);

  useEffect(() => {
    if (userData && userData.role_level !== 0) {
      navigate("/401");
    }
  }, [userData]);

  useEffect(() => {
    // checkLogin();
    getUserDetail();
  }, []);

  return (
    <main className="container">
      {user && (
        <div className="content">
          <div className="content-body align-items-start">
            <div
              className="flex justify-content-between align-items-center mb-10"
              style={{ width: "100%" }}
            >
              <div className="sub-title font-nanumMyeongjo">작가 정보</div>
            </div>
            <div className="form-group">
              <label className="form-label">닉네임</label>
              <input
                type="text"
                className="form-control"
                value={user.nickname}
                disabled={true}
              />
            </div>
            <div className="form-group">
              <label className="form-label">이름</label>
              <input
                type="text"
                className="form-control"
                value={user.name}
                disabled={true}
              />
            </div>
            <div className="form-group">
              <label className="form-label">이메일</label>
              <input
                type="text"
                className="form-control"
                value={user.email}
                disabled={true}
              />
            </div>
            <div className="form-group">
              <label className="form-label">가입일</label>
              <input
                type="text"
                className="form-control"
                value={
                  user.create_datetime
                    ? $dateTimezone(user.create_datetime)
                    : ""
                }
                disabled={true}
              />
            </div>
            <div className="form-group">
              <label className="form-label">메인 작품수</label>
              <input
                type="text"
                className="form-control"
                value={user.main_product_count}
                disabled={true}
              />
            </div>
            <div className="form-group">
              <label className="form-label">보조 작품수</label>
              <input
                type="text"
                className="form-control"
                value={user.sub_product_count}
                disabled={true}
              />
            </div>
            <div className="form-group">
              <label className="form-label">잼(포인트)</label>
              <input
                type="text"
                className="form-control mr-5"
                value={user.points}
                onChange={(e) => setUser({ ...user, points: e.target.value })}
              />
              <button
                className="btn btn-primary btn-sm"
                onClick={() => updatePoints()}
              >
                저장
              </button>
            </div>
            <div className="form-group">
              <label className="form-label">상태</label>
              <select
                className="form-control mr-5"
                style={{ width: "100px" }}
                value={user.active_yn}
                onChange={(e) =>
                  setUser({ ...user, active_yn: e.target.value })
                }
              >
                <option value="Y">활성</option>
                <option value="N">비활성</option>
              </select>
              <button
                className="btn btn-primary btn-sm"
                onClick={() => updateUser()}
              >
                저장
              </button>
            </div>
            <div
              className="flex justify-content-between align-items-center mb-10 mt-40"
              style={{ width: "100%" }}
            >
              <div className="sub-title font-nanumMyeongjo">결제 내역</div>
            </div>
            <div className="table-responsive">
              <table className="table table-hover table-nowrap">
                <thead>
                  <tr>
                    <th>결제일</th>
                    <th>주문명</th>
                    <th>결제금액</th>
                  </tr>
                </thead>
                <tbody>
                  {paymentList &&
                    paymentList.map((payment, idx) => (
                      <tr key={`payment-${idx}`}>
                        <td>{$dateFormat(payment.approved_at)}</td>
                        <td>{payment.order_name}</td>
                        <td>{$convertNumberFormat(payment.total_amount)}원</td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {totalPage > 1 && (
                <div className="d-flex justify-content-center mt-10">
                  <nav aria-label="Page navigation example">
                    <ul className="pagination">
                      {pageNo > 5 && (
                        <li className="page-item">
                          <a
                            className="page-link"
                            onClick={(e) => setPageNo(pageNo - 1)}
                          >
                            <i className="fa-solid fa-arrow-left"></i>
                          </a>
                        </li>
                      )}

                      {pageRange.map((page) => (
                        <li key={page}>
                          <a
                            className={
                              pageNo === page ? "page-link active" : "page-link"
                            }
                            onClick={(e) => setPageNo(page)}
                          >
                            {page}
                          </a>
                        </li>
                      ))}

                      {lastPage < totalPage && (
                        <li className="page-item">
                          <a
                            className="page-link"
                            onClick={(e) => setPageNo(pageNo + 1)}
                          >
                            <i className="fa-solid fa-arrow-right"></i>
                          </a>
                        </li>
                      )}
                    </ul>
                  </nav>
                </div>
              )}
            </div>

            <div
              className="flex justify-content-between align-items-center mb-10 mt-40"
              style={{ width: "100%" }}
            >
              <div className="sub-title font-nanumMyeongjo">사용 내역</div>
            </div>
            <div className="table-responsive">
              <table className="table table-hover table-nowrap">
                <thead>
                  <tr>
                    <th>년월</th>
                    <th>총 사용 잼</th>
                    <th>총 사용 수</th>
                  </tr>
                </thead>
                <tbody>
                  {pointUsageList &&
                    pointUsageList.map((pointUsage, idx) => (
                      <tr key={`pointUsage-${idx}`}>
                        <td>{pointUsage.date}</td>
                        <td>{$convertNumberFormat(pointUsage.point)}</td>
                        <td>
                          <a
                            onClick={() => {
                              setActiveDate(pointUsage.date);
                              setShowPointUsageHistoryModal(true);
                            }}
                            style={{ textDecoration: "underline" }}
                          >
                            {$convertNumberFormat(pointUsage.count)}
                          </a>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {totalPage2 > 1 && (
                <div className="d-flex justify-content-center mt-10">
                  <nav aria-label="Page navigation example">
                    <ul className="pagination">
                      {pageNo2 > 5 && (
                        <li className="page-item">
                          <a
                            className="page-link"
                            onClick={(e) => setPageNo2(pageNo2 - 1)}
                          >
                            <i className="fa-solid fa-arrow-left"></i>
                          </a>
                        </li>
                      )}

                      {pageRange2.map((page) => (
                        <li key={page}>
                          <a
                            className={
                              pageNo2 === page
                                ? "page-link active"
                                : "page-link"
                            }
                            onClick={(e) => setPageNo2(page)}
                          >
                            {page}
                          </a>
                        </li>
                      ))}

                      {lastPage2 < totalPage2 && (
                        <li className="page-item">
                          <a
                            className="page-link"
                            onClick={(e) => setPageNo2(pageNo2 + 1)}
                          >
                            <i className="fa-solid fa-arrow-right"></i>
                          </a>
                        </li>
                      )}
                    </ul>
                  </nav>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {showPointUsageHistoryModal && (
        <PointUsageHistoryModal
          activeDate={activeDate}
          roleLevel={userData.role_level}
          userEmail={email}
          onModal={() => setShowPointUsageHistoryModal(false)}
        />
      )}

      {loading && <Loading />}
    </main>
  );
}

export default UserDetail;
