import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// import { $get, $post, $put, $delete, $upload } from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import {
  $post,
  $put,
  $delete,
  $get,
  $dateTimezone,
  $dateFormat,
  $convertNumberFormat,
} from "../utils/common";
// import WorkspaceData from "./Workspace.json";
import Swal from "sweetalert2";
import Loading from "../component/Loading";
import PaymentModal from "../component/PyamentModal";
import PaymentInsertModal from "../component/PyamentInsertModal";

function PaymentAdmin() {
  const navigate = useNavigate();
  const location = useLocation();

  const { isLoggedIn, userData, checkLogin } = useAuth();
  const [loading, setLoading] = useState(false);
  const [pointPriceList, setPointPriceList] = useState([]);
  const [pointFunctionList, setPointFunctionList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [activePayment, setActivePayment] = useState({});
  const [showInsertModal, setShowInsertModal] = useState(false);

  const getPointPriceList = async () => {
    setLoading(true);
    const res = await $get(`/api/point/price`);
    if (res.status === 200) {
      setPointPriceList(res.data);
    }
    setLoading(false);
  };

  const getPointFunctionList = async () => {
    setLoading(true);
    const res = await $get(`/api/point/func`);
    if (res.status === 200) {
      setPointFunctionList(res.data);
    }
    setLoading(false);
  };

  const deletePayment = async (id) => {
    Swal.fire({
      iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
      title: "결제 상품 삭제",
      text: "삭제하시겠습니까?",
      showCancelButton: true,
      confirmButtonText: "예",
      cancelButtonText: "아니오",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $delete(`/api/point/price/${id}`);

        if (res.status === 200) {
          getPointPriceList();
        } else {
          Swal.fire({
            iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
            title: "결제 상품 삭제 실패",
            text: res.message,
            showConfirmButton: false,
            timer: 1000,
          });
        }
      }
    });
  };

  useEffect(() => {
    getPointPriceList();
    getPointFunctionList();
  }, []);
  return (
    <main className="container" style={{ height: `calc(100vh - 68px)` }}>
      {userData && (
        <div className="content">
          <div className="content-body align-items-start">
            <div
              className="flex justify-content-between align-items-center mb-10"
              style={{ width: "100%" }}
            >
              <div className="sub-title font-nanumMyeongjo">결제 정책</div>
            </div>
            <div className="table-responsive">
              <table className="table table-hover table-nowrap">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>결제금액</th>
                    <th>잼(포인트)</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {pointPriceList &&
                    pointPriceList.map((pointPrice, idx) => (
                      <tr key={`pointPrice-${idx}`}>
                        <td>{pointPrice.id}</td>
                        <td>{$convertNumberFormat(pointPrice.price)}원</td>
                        <td>{$convertNumberFormat(pointPrice.point)}</td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-default btn-sm mr-10"
                            onClick={() => {
                              setActivePayment(pointPrice);
                              setShowModal(true);
                            }}
                          >
                            수정
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger btn-sm mr-10"
                            onClick={() => {
                              deletePayment(pointPrice.id);
                            }}
                          >
                            삭제
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <div className="flex justify-content-center mt-20">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => setShowInsertModal(true)}
                >
                  결제 상품 추가
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showModal && (
        <PaymentModal
          showModal={showModal}
          onModal={setShowModal}
          activePayment={activePayment}
          onSave={() => {
            setShowModal(false);
            getPointPriceList();
          }}
        />
      )}
      {showInsertModal && (
        <PaymentInsertModal
          showModal={showInsertModal}
          onModal={setShowInsertModal}
          onSave={() => {
            setShowInsertModal(false);
            getPointPriceList();
          }}
        />
      )}
      {loading && <Loading />}
    </main>
  );
}

export default PaymentAdmin;
