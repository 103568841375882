import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { $post } from "../utils/common";
import Swal from "sweetalert2";

function OrderSuccess() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const confirm = async () => {
    const requestData = {
      orderId: searchParams.get("orderId"),
      amount: searchParams.get("amount"),
      paymentKey: searchParams.get("paymentKey"),
    };

    const res = await $post("/api/payment/confirm", requestData);
    if (res.status === 200) {
      navigate("/profile");
    } else {
      // Swal confirm 하면 navigate("/")로 이동
      Swal.fire({
        title: "결제 실패",
        text: "결제에 실패하였습니다.",
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        confirmButtonText: "확인",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        stopKeydownPropagation: false,
        showConfirmButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/payment");
        }
      });
    }
  };

  useEffect(() => {
    confirm();
  }, []);

  return <div className="result wrapper"></div>;
}

export default OrderSuccess;
