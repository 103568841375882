import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// import { $get, $post, $put, $delete, $upload } from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import { $post, $put, $delete, $get, $dateTimezone } from "../utils/common";
// import WorkspaceData from "./Workspace.json";
import Swal from "sweetalert2";
import Loading from "../component/Loading";
import SystemPopupModal from "../component/SystemPopupModal";

function SystemPopup() {
  const navigate = useNavigate();

  const { isLoggedIn, userData, checkLogin } = useAuth();

  const [popupList, setPopupList] = useState([]);
  const [pageLimit, setPageLimit] = useState(15);
  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageRange, setPageRange] = useState([]);
  const [lastPage, setLastPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [popupModalOpen, setPopupModalOpen] = useState(false);
  const [activePopup, setActivePopup] = useState({});

  const location = useLocation();
  const scrollRef = useRef(null);

  const paging = () => {
    const pages = [];

    const pageFirst =
      pageNo % 5 === 0 ? pageNo - 4 : Math.floor(pageNo / 5) * 5 + 1;
    let pageLast = pageFirst + 4;

    if (pageLast > totalPage) pageLast = totalPage;
    setLastPage(pageLast);

    for (let i = pageFirst; i <= pageLast; i++) {
      pages.push(i);
    }
    setPageRange(pages);
  };

  const getPopupList = async () => {
    setLoading(true);
    const res = await $get(`/api/popup/list/${pageNo}/${pageLimit}`);
    if (res.status === 200) {
      setPopupList(res.data.popups);
      setTotalPage(Math.ceil(res.data.total_count / pageLimit));
      paging();
    } else {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "팝업 조회 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
    setLoading(false);
  };

  const changePagination = async () => {
    const res = await $get(`/api/popup/list/${pageNo}/${pageLimit}`);
    if (res.status === 200) {
      setPopupList(res.data.popups);
      setTotalPage(Math.ceil(res.data.total_count / pageLimit));
      paging();
    } else {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "팝업 조회 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  const deletePopup = async (id) => {
    Swal.fire({
      iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
      title: "팝업 삭제",
      text: "팝업을 삭제하시겠습니까?",
      showCancelButton: true,
      confirmButtonText: "예",
      cancelButtonText: "아니오",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $delete(`/api/popup/${id}`);
        if (res.status === 200) {
          Swal.fire({
            iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
            title: "팝업 삭제 성공",
            text: "팝업가 삭제되었습니다.",
            showConfirmButton: false,
            timer: 1000,
          });
          getPopupList();
        } else {
          Swal.fire({
            iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
            title: "팝업 삭제 실패",
            text: res.message,
            showConfirmButton: false,
            timer: 1000,
          });
        }
      }
    });
  };

  const goToRegistration = (popupId) => {
    if (popupId) navigate(`/system-popup-register`, { state: { popupId } });
    else navigate("/system-popup-register");
  };

  useEffect(() => {
    paging();
  }, [totalPage]);

  useEffect(() => {
    changePagination(pageNo);
  }, [pageNo]);

  // useEffect(() => {
  //   if (userData && userData.role_level !== 0) {
  //     navigate("/401");
  //   }
  // }, [userData]);

  useEffect(() => {
    // checkLogin();
    // getPopupList();
  }, []);

  return (
    <main className="container">
      <div className="content">
        <div className="content-body align-items-start" ref={scrollRef}>
          <div
            className="flex justify-content-between align-items-center mb-10"
            style={{ width: "100%" }}
          >
            <div className="sub-title font-nanumMyeongjo">팝업 관리</div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => goToRegistration()}
            >
              <i className="fa-regular fa-plust"></i> 팝업 추가
            </button>
          </div>
          <div className="table-responsive">
            <table className="table table-hover table-nowrap">
              <thead>
                <tr>
                  <th>제목</th>
                  <th width="*">팝업 기간</th>
                  <th width="90px">팝업 형식</th>
                  <th width="90px">미리보기</th>
                  <th width="80px"></th>
                </tr>
              </thead>
              <tbody>
                {popupList &&
                  popupList.map((popup) => (
                    <tr key={popup.id}>
                      <td>
                        <a onClick={() => goToRegistration(popup.id)}>
                          {popup.title}
                        </a>
                      </td>
                      <td>
                        {$dateTimezone(popup.from_datetime)} ~{" "}
                        {$dateTimezone(popup.to_datetime)}
                      </td>
                      <td>{popup.type === "T" ? "글" : "이미지"}</td>
                      <td>
                        <button
                          className="btn btn-default btn-sm"
                          onClick={() => {
                            setActivePopup(popup);
                            setPopupModalOpen(true);
                          }}
                        >
                          미리보기
                        </button>
                      </td>
                      <td>
                        <button
                          className="btn btn-transparent btn-sm"
                          onClick={() => deletePopup(popup.id)}
                        >
                          <i className="fa-solid fa-trash-can"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {totalPage > 1 && (
              <div className="d-flex justify-content-center mt-10">
                <nav aria-label="Page navigation example">
                  <ul className="pagination">
                    {pageNo > 5 && (
                      <li className="page-item">
                        <a
                          className="page-link"
                          onClick={(e) => setPageNo(pageNo - 1)}
                        >
                          <i className="fa-solid fa-arrow-left"></i>
                        </a>
                      </li>
                    )}

                    {pageRange.map((page) => (
                      <li key={page}>
                        <a
                          className={
                            pageNo === page ? "page-link active" : "page-link"
                          }
                          onClick={(e) => setPageNo(page)}
                        >
                          {page}
                        </a>
                      </li>
                    ))}

                    {lastPage < totalPage && (
                      <li className="page-item">
                        <a
                          className="page-link"
                          onClick={(e) => setPageNo(pageNo + 1)}
                        >
                          <i className="fa-solid fa-arrow-right"></i>
                        </a>
                      </li>
                    )}
                  </ul>
                </nav>
              </div>
            )}
          </div>
        </div>
      </div>

      {loading && <Loading />}
      {popupModalOpen && (
        <SystemPopupModal
          popupId={activePopup.id}
          preview={true}
          onModal={setPopupModalOpen}
        />
      )}
    </main>
  );
}

export default SystemPopup;
