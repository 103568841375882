import React, { useState, useEffect } from "react";
import { $put } from "../utils/common";
import Swal from "sweetalert2";

function EpisodeCardModal(props) {
  const [episodeTitle, setEpisodeTitle] = useState(props.episode.title);
  const [episodeContent, setEpisodeContent] = useState(props.episode.content);

  const saveEpisode = async (e) => {
    Swal.fire({
      iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
      title: "에피소드 저장",
      text: "에피소드를 저장하시겠습니까?",
      showCancelButton: true,
      confirmButtonText: "예",
      cancelButtonText: "아니오",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $put(
          `/api/product/episode-card/${props.episode.id}`,
          {
            title: episodeTitle,
            content: episodeContent,
          }
        );

        if (res.status === 200) {
          props.onModal(false);
        } else {
          Swal.fire({
            iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
            title: "에피소드 저장 실패",
            text: res.message,
            showConfirmButton: false,
            timer: 1000,
          });
        }
      }
    });
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="modal-header">
          <span className="close" onClick={() => props.onModal(false)}>
            &times;
          </span>
          <h3>에피소드 {props.episode.episode_no}</h3>
        </div>
        <div className="modal-body">
          <div className="form-group">
            <label className="form-label">에피소드 제목</label>
            <input
              type="text"
              className="form-control"
              value={episodeTitle}
              placeholder="해당 에피소드를 압축하거나 상징하는 제목을 쓰세요."
              onChange={(e) => setEpisodeTitle(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label className="form-label">에피소드 내용</label>
            <textarea
              className="form-control"
              rows="10"
              value={episodeContent}
              placeholder="해당 에피소드의 내용을 600자 이내로 적으세요."
              onChange={(e) => setEpisodeContent(e.target.value)}
            ></textarea>
          </div>
        </div>
        <div className="modal-footer flex">
          <button
            type="button"
            className="btn btn-default"
            onClick={() => props.onModal(false)}
          >
            닫기
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => saveEpisode()}
          >
            저장
          </button>
        </div>
      </div>
    </div>
  );
}

export default EpisodeCardModal;
