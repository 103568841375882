import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// import { $get, $post, $put, $delete, $upload } from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import { $post, $put, $delete, $get, $dateTimezone } from "../utils/common";
// import WorkspaceData from "./Workspace.json";
import Swal from "sweetalert2";
import ShareModal from "../component/ShareModal";
import Loading from "../component/Loading";
import QuestionModal from "../component/QuestionModal";

function Question() {
  const navigate = useNavigate();

  const { isLoggedIn, userData, checkLogin } = useAuth();

  const [questionList, setQuestionList] = useState([]);
  const [pageLimit, setPageLimit] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageRange, setPageRange] = useState([]);
  const [lastPage, setLastPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [questionModalOpen, setQuestionModalOpen] = useState(false);
  const [activeQuestion, setActiveQuestion] = useState({
    title: "",
    content: "",
  });

  const location = useLocation();
  const scrollRef = useRef(null);

  const paging = () => {
    const pages = [];

    const pageFirst =
      pageNo % 5 === 0 ? pageNo - 4 : Math.floor(pageNo / 5) * 5 + 1;
    let pageLast = pageFirst + 4;

    if (pageLast > totalPage) pageLast = totalPage;
    setLastPage(pageLast);

    for (let i = pageFirst; i <= pageLast; i++) {
      pages.push(i);
    }
    setPageRange(pages);
  };

  const getQuestionList = async () => {
    setLoading(true);
    const res = await $get(`/api/question/list-all/${pageNo}/${pageLimit}`);
    if (res.status === 200) {
      setQuestionList(res.data.questions);
      setTotalPage(Math.ceil(res.data.total_count / pageLimit));
      paging();
    } else {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "문의 조회 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
    setLoading(false);
  };

  const changePagination = async () => {
    const res = await $get(`/api/question/list/${pageNo}/${pageLimit}`);
    if (res.status === 200) {
      setQuestionList(res.data.questions);
      setTotalPage(Math.ceil(res.data.total_count / pageLimit));
      paging();
    } else {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "문의 조회 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  const deleteQuestion = async (id) => {
    Swal.fire({
      iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
      title: "문의 삭제",
      text: "문의을 삭제하시겠습니까?",
      showCancelButton: true,
      confirmButtonText: "예",
      cancelButtonText: "아니오",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $delete(`/api/question/${id}`);
        if (res.status === 200) {
          Swal.fire({
            iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
            title: "문의 삭제 성공",
            text: "문의가 삭제되었습니다.",
            showConfirmButton: false,
            timer: 1000,
          });
          getQuestionList();
        } else {
          Swal.fire({
            iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
            title: "문의 삭제 실패",
            text: res.message,
            showConfirmButton: false,
            timer: 1000,
          });
        }
      }
    });
  };

  const goToAnswer = (id) => {
    navigate(`/question-answer`, { state: { questionId: id } });
  };

  useEffect(() => {
    paging();
  }, [totalPage]);

  useEffect(() => {
    changePagination(1);
  }, [pageNo]);

  useEffect(() => {
    // checkLogin();
    // getQuestionList();
  }, []);

  return (
    <main className="container">
      <div className="content">
        <div className="content-body align-items-start" ref={scrollRef}>
          <div
            className="flex justify-content-between align-items-center mb-10"
            style={{ width: "100%" }}
          >
            <div className="sub-title font-nanumMyeongjo">접수 문의 목록</div>
          </div>
          <div className="table-responsive">
            <table className="table table-hover table-nowrap">
              <thead>
                <tr>
                  <th>문의 제목</th>
                  <th>상태</th>
                  <th>문의 등록 일시</th>
                  <th>답변 등록 일시</th>
                  <th width="80px"></th>
                </tr>
              </thead>
              <tbody>
                {questionList &&
                  questionList.map((question) => (
                    <tr key={question.id}>
                      <td>
                        <a onClick={() => goToAnswer(question.id)}>
                          {question.title}
                        </a>
                      </td>
                      <td>
                        {question.reply_yn === "Y" ? "답변완료" : "접수완료"}
                      </td>
                      <td>{$dateTimezone(question.modify_datetime)}</td>
                      <td>
                        {question.reply_yn === "Y"
                          ? $dateTimezone(question.reply_datetime)
                          : ""}
                      </td>
                      <td>
                        <button
                          className="btn btn-transparent btn-sm"
                          onClick={() => deleteQuestion(question.id)}
                        >
                          <i className="fa-solid fa-trash-can"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {totalPage > 1 && (
              <div className="d-flex justify-content-center mt-10">
                <nav aria-label="Page navigation example">
                  <ul className="pagination">
                    {pageNo > 5 && (
                      <li className="page-item">
                        <a
                          className="page-link"
                          onClick={(e) => setPageNo(pageNo - 1)}
                        >
                          <i className="fa-solid fa-arrow-left"></i>
                        </a>
                      </li>
                    )}

                    {pageRange.map((page) => (
                      <li key={page}>
                        <a
                          className={
                            pageNo === page ? "page-link active" : "page-link"
                          }
                          onClick={(e) => setPageNo(page)}
                        >
                          {page}
                        </a>
                      </li>
                    ))}

                    {lastPage < totalPage && (
                      <li className="page-item">
                        <a
                          className="page-link"
                          onClick={(e) => setPageNo(pageNo + 1)}
                        >
                          <i className="fa-solid fa-arrow-right"></i>
                        </a>
                      </li>
                    )}
                  </ul>
                </nav>
              </div>
            )}
          </div>
        </div>
      </div>

      {loading && <Loading />}
      {questionModalOpen && (
        <QuestionModal
          question={activeQuestion}
          onModal={setQuestionModalOpen}
          onSave={() => {
            getQuestionList();
            setQuestionModalOpen(false);
          }}
        />
      )}
    </main>
  );
}

export default Question;
