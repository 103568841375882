import React, { useState, useEffect, useRef } from "react";
import { $get, $put, $post, $convertNumberFormat } from "../utils/common";
import { useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import BeatLoader from "react-spinners/BeatLoader";

function ParagraphAssistantModal(props) {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const navigate = useNavigate();
  const [assistantParagraph, setAssistantParagraph] = useState("");
  const [loading, setLoading] = useState(false);
  const assistantScrollRef = useRef(null);

  let bProgress = false;

  const getUserPoints = async () => {
    const res = await $get("/api/user/points");
    if (res.status === 200) {
      return res.data.points;
    } else {
      return 0;
    }
  };

  const getDeductionPoints = async (funcCategory) => {
    const res = await $get(`/api/user/func/points/${funcCategory}`);
    if (res.status === 200) {
      return res.data.deduction_points;
    } else {
      return 0;
    }
  };

  const generateParagraph = async () => {
    const deductionPoints = await getDeductionPoints("E");
    Swal.fire({
      iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
      title: "문장 제안 받기",
      text: `${$convertNumberFormat(
        deductionPoints
      )}잼이 사용됩니다. 문장을 제안 받으시겠습니까?`,
      showCancelButton: true,
      confirmButtonText: "예",
      cancelButtonText: "아니오",
    }).then(async (result) => {
      if (result.isConfirmed) {
        getSuggestParagraph();
      }
    });
  };

  const getSuggestParagraph = async () => {
    if (bProgress) return;

    const deductionPoints = await getDeductionPoints("E");
    const userPoints = await getUserPoints();
    if (userPoints < deductionPoints) {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: `잼이 부족합니다.`,
        text: `잼을 충전하시겠습니까?(나의 잼: ${$convertNumberFormat(
          userPoints
        )}, 필요 잼: ${$convertNumberFormat(deductionPoints)})`,
        showCancelButton: true,
        confirmButtonText: "네, 충전하겠습니다.",
        cancelButtonText: "아니오.",
      }).then(async (result) => {
        if (result.isConfirmed) {
          navigate("/payment");
        }
      });
      return;
    } else {
      bProgress = true;
      setLoading(true);
      setAssistantParagraph("");
      sessionStorage.setItem("stopAssistant", false);

      let url = `${serverUrl}/api/prompt/paragraph`;

      const res = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          productId: props.episode.product_id,
          paragraph: props.selectedText,
        }),
      });

      const reader = res.body.pipeThrough(new TextDecoderStream()).getReader();
      const tempAssistantMessage = [];
      const finishRegexp = /(\[\[.*?\]\])/;
      let finishReason = "";
      while (true) {
        if (sessionStorage.getItem("stopAssistant") === "true") {
          reader.cancel();
          reader.releaseLock();
          break;
        }

        let { value, done } = await reader.read();
        if (done) break;

        value = value.replace(/(?:\r\n|\r|\n)/g, "<br />");
        if (finishRegexp.test(value)) {
          finishReason = value.match(finishRegexp)[0];
          value = "";
        }

        setAssistantParagraph((prev) => prev + value);
        tempAssistantMessage.push(value);
      }

      sessionStorage.setItem("stopAssistant", false);

      const result = tempAssistantMessage.join("").split("<br />");
      const resHtml = [];
      result.forEach((item) => {
        resHtml.push(`${item}`);
      });

      setAssistantParagraph(resHtml.join(""));

      bProgress = false;
      setLoading(false);
    }
  };

  const changeParagraph = async () => {
    props.onModal(false);
    props.onChange(assistantParagraph);
  };

  const copyParagraph = async () => {
    navigator.clipboard.writeText(assistantParagraph);
    // Swal.fire({
    //   icon: "success",
    //   title: "복사 완료",
    //   text: "클립보드에 복사되었습니다.",
    //   timer: 1000,
    // });
    props.onModal(false);
  };

  useEffect(() => {
    generateParagraph();
  }, []);

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="modal-header">
          {!loading && (
            <span className="close" onClick={() => props.onModal(false)}>
              &times;
            </span>
          )}
          <h3>선택된 텍스트</h3>
          <p className="bg-gray text-white p-10">{props.selectedText}</p>
        </div>

        <div
          className="modal-body no-select"
          ref={assistantScrollRef}
          dangerouslySetInnerHTML={{ __html: assistantParagraph }}
        ></div>
        <div className="modal-footer flex justify-content-end">
          {loading && (
            <div className="pl-20">
              <BeatLoader color="#ae7e55" />
            </div>
          )}

          {!loading && assistantParagraph !== "" && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={(e) => changeParagraph()}
            >
              바꾸기
            </button>
          )}

          {!loading && (
            <button
              type="button"
              className="btn btn-default"
              onClick={() => generateParagraph()}
            >
              다시 제안 받기
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default ParagraphAssistantModal;
