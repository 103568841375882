function WaitingList() {
  return (
    <div className="container">
      <h1>Waiting List</h1>
      <p>
        관리자가 사용 심사를 진행합니다. 심사가 완료되면 가입하신 이메일로 사용
        안내 메시지가 전송됩니다.
      </p>
    </div>
  );
}

export default WaitingList;
