import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../login/AuthProvider";
import {
  $post,
  $put,
  $delete,
  $get,
  $dateTimezone,
  $convertNumberFormat,
} from "../utils/common";
import Swal from "sweetalert2";

function Payment() {
  const navigate = useNavigate();
  const { isLoggedIn, userData, login, logout } = useAuth();
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [point, setPoint] = useState(0);
  const [payments, setPayments] = useState([]);

  const goToOrder = (price) => {
    navigate(`/order/${price}`);
  };

  const getUserPoints = async () => {
    const res = await $get("/api/user/points");
    if (res.status === 200) {
      setPoint(res.data.points);
    }
  };

  const getPayments = async () => {
    const res = await $get("/api/payment/point-price");
    if (res.status === 200) {
      setPayments(res.data);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      getUserPoints();
      getPayments();
    }
  }, []);

  return (
    <div className="container">
      <div className="flex-column align-items-center">
        <div className="sub-title font-nanumMyeongjo">잼 구매</div>
        {userData && (
          <p className="point-remain">나의 잼: {$convertNumberFormat(point)}</p>
        )}
        <div className="payment-container">
          <div className="payment-list">
            {payments.map((payment) => (
              <div
                className="payment-item"
                key={payment.id}
                // onClick={() => navigate(`/payment/${payment.id}`)}
              >
                <div className="payment-item-name">
                  {$convertNumberFormat(payment.point)}잼
                </div>
                <div className="payment-item-price">
                  {$convertNumberFormat(payment.price)}원
                </div>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={(e) => goToOrder(payment.price)}
                >
                  구매하기
                </button>
              </div>
            ))}
          </div>
        </div>
        <div className="payment-container">
          <ul>
            <li>잼 포인트는 비·라이터 서비스 내에서만 사용 가능합니다.</li>
            <li>포인트의 가격은 부가가치세가 포함된 가격입니다.</li>
            <li>
              포인트는 마지막 이용일로부터 5년이 경과한 후, 1년 동안 비·라이터
              서비스에 접속하지 않은 경우 소멸합니다.
            </li>
            <li>
              포인트는 무료 포인트부터 유효기간이 임박한 순으로 먼저 사용됩니다.
            </li>
            <li>
              포인트 구매 내역은 마이페이지의 포인트 구입내역에서 확인
              가능합니다.
            </li>
            <li>
              이미 실행한 AI 기능에 대해서는 취소되거나 환불되지 않습니다.
            </li>
            <li>이미 구매한 포인트는 환불되지 않습니다.</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Payment;
