import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Loading from "../component/Loading";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  $post,
  $put,
  $delete,
  $get,
  $dateTimezone,
  $convertNumberFormat,
} from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import Swal from "sweetalert2";
import ReviewModal from "../component/ReviewModal";

function ReviewAdmin() {
  const navigate = useNavigate();
  const serverUrl = process.env.REACT_APP_SERVER_URL;

  const { isLoggedIn, userData, checkLogin } = useAuth();
  const location = useLocation();
  const [reviewList, setReviewList] = useState([]);
  const [showReviewModal, setShowReviewModal] = useState(false);

  const slickSettings = {
    fade: true,
    // dots: true,
    infinite: true,
    autoplay: true,
    // speed: 5000,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
    centerMode: true,
    // width: 600,
    className: "carousel",
    // adaptiveHeight: true,
  };

  const getReviewList = async () => {
    const res = await $get(`/api/review`);
    if (res.status === 200) {
      setReviewList(res.data);
    } else {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "리뷰 조회 실패",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const saveReview = async () => {
    Swal.fire({
      iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
      title: "리뷰 등록",
      text: "리뷰를 등록하시겠습니까?",
      showCancelButton: true,
      confirmButtonText: "네, 등록합니다.",
      cancelButtonText: "아니오",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // reviewList 에서 id, create_datetime 제거
        // const review = reviewList.map((review) => {
        //   const { id, create_datetime, ...rest } = review;
        //   return rest;
        // });

        // reviewInsertMultiple
        const tempReviewList = [];
        reviewList.forEach((review) => {
          const tempReview = [];
          tempReview.push(review.paragraph1);
          tempReview.push(review.paragraph2);
          tempReview.push(review.paragraph3);
          tempReview.push(review.reviewer);
          tempReview.push(review.img_url);
          tempReviewList.push(tempReview);
        });

        const res = await $post(`/api/review`, tempReviewList);
        if (res.status === 200) {
          setShowReviewModal(false);
          getReviewList();
          Swal.fire({
            iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
            title: "리뷰 등록 성공",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
            title: "리뷰 등록 실패",
            text: res.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    });
  };

  const deleteReview = async (index) => {
    setReviewList(reviewList.filter((review, i) => i !== index));
  };

  const changeIndex = (index, direction) => {
    const newReviewList = [...reviewList];
    const temp = newReviewList[index];
    if (direction === -1) {
      newReviewList[index] = newReviewList[index - 1];
      newReviewList[index - 1] = temp;
    } else {
      newReviewList[index] = newReviewList[index + 1];
      newReviewList[index + 1] = temp;
    }

    setReviewList([...newReviewList]);
  };

  useEffect(() => {
    getReviewList();
  }, []);

  return (
    <main className="container">
      <div className="content">
        <div className="content-body align-items-start">
          <div
            className="flex justify-content-between align-items-center mb-10"
            style={{ width: "100%" }}
          >
            <div className="sub-title font-nanumMyeongjo">리뷰 관리</div>
          </div>
        </div>
        <section className="mb-20" style={{ position: "relative" }}>
          <Slider {...slickSettings}>
            {reviewList.map((review, index) => (
              <div className="carousel-item" key={`review-${review.id}`}>
                <div className="carousel-item-img">
                  <img
                    className="user-avatar"
                    src={
                      review.img_url
                        ? `${serverUrl}/static/images/${review.img_url}`
                        : "./assets/images/default-profile.jpg"
                    }
                    alt="avatar"
                  />
                </div>
                <div className="carousel-item-message font-nanumMyeongjo">
                  <p>{review.paragraph1}</p>
                  <p>{review.paragraph2}</p>
                  <p>{review.paragraph3}</p>
                </div>
                <div className="carousel-item-name">- {review.reviewer}</div>
              </div>
            ))}
          </Slider>
        </section>
        <div>
          <table className="table table-hover table-nowrap">
            <thead>
              <tr>
                <th width="100px">이미지</th>
                <th>내용</th>
                <th width="240px">리뷰어</th>
                <th width="140px"></th>
              </tr>
            </thead>
            <tbody>
              {reviewList &&
                reviewList.map((review, index) => (
                  <tr key={`tr-${index}`}>
                    <td>
                      <img
                        src={
                          review.img_url
                            ? `${serverUrl}/static/images/${review.img_url}`
                            : "./assets/images/default-profile.jpg"
                        }
                        className="user-avatar"
                      />
                    </td>
                    <td>
                      <p>{review.paragraph1}</p>
                      <p>{review.paragraph2}</p>
                      <p>{review.paragraph3}</p>
                    </td>
                    <td>{review.reviewer}</td>
                    <td>
                      {index > 0 && (
                        <button
                          className="btn btn-xsm btn-transparent"
                          onClick={() => changeIndex(index, -1)}
                        >
                          <i className="fa-solid fa-arrow-up"></i>
                        </button>
                      )}
                      {index < reviewList.length - 1 && (
                        <button
                          className="btn btn-xsm btn-transparent"
                          onClick={() => changeIndex(index, 1)}
                        >
                          <i className="fa-solid fa-arrow-down"></i>
                        </button>
                      )}
                      <button
                        className="btn btn-xsm btn-transparent"
                        onClick={() => deleteReview(index)}
                      >
                        <i className="fa-regular fa-trash-can"></i>
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="flex justify-content-center">
          <button
            className="btn btn-default mr-10"
            onClick={() => setShowReviewModal(true)}
          >
            <i className="fa-solid fa-plus"></i> 추가하기
          </button>
          <button className="btn btn-primary" onClick={() => saveReview()}>
            <i className="fa-solid fa-save"></i> 저장하기
          </button>
        </div>
      </div>
      {showReviewModal && (
        <ReviewModal
          onModal={(show) => setShowReviewModal(show)}
          onAdd={(review) => {
            setReviewList([...reviewList, review]);
          }}
          review={{}}
        />
      )}
    </main>
  );
}

export default ReviewAdmin;
