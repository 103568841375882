import React, { useState, useEffect, useRef } from "react";
import NavData from "./Nav.json";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../login/AuthProvider";
import { $post, $get } from "../utils/common";
import Loading from "../component/Loading";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import Swal from "sweetalert2";

const Nav = React.forwardRef((props, ref) => {
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const naverClientID = process.env.REACT_APP_NAVER_CLIENT_ID;
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const navigate = useNavigate();
  const { isLoggedIn, userData, login, logout } = useAuth();
  const location = useLocation();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [loginStep, setLoginStep] = useState(0);
  const [agreeAll, setAgreeAll] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [agreePrivacy, setAgreePrivacy] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navMarginLeft, setNavMarginLeft] = useState("0px"); // [navMarginLeft
  const naverRef = useRef();

  const appUrl = process.env.REACT_APP_URL;

  React.useImperativeHandle(ref, () => ({
    doRegister() {
      if (mobileCheck()) {
        setLoginModalOpen(false);
        return;
      }
      openLoginModal(0);
    },
    doLogin() {
      if (mobileCheck()) {
        setLoginModalOpen(false);
        return;
      }
      openLoginModal(1);
    },
  }));

  const loginWithKakao = () => {
    window.Kakao.Auth.authorize({
      redirectUri: appUrl,
      scope: "profile_nickname, profile_image, account_email",
      //   state: "userme",
      //   serviceTerms: "account_email",
    });
  };

  const requestUserInfo = async (tokenInfo) => {
    const res = await fetch("https://kapi.kakao.com/v2/user/me", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.Kakao.Auth.getAccessToken()}`,
      },
    });

    if (res.status === 401) {
      return;
    }

    const resJson = await res.json();

    const res2 = await $post("/api/kakao-login", {
      email: resJson.kakao_account.email,
      nickname: resJson.kakao_account.profile.nickname,
      profile_image_url: resJson.kakao_account.profile.profile_image_url,
      access_token: tokenInfo.access_token,
      access_token_expires_in: tokenInfo.expires_in,
      refresh_token: tokenInfo.refresh_token,
      refresh_token_expires_in: tokenInfo.refresh_token_expires_in,
    });

    const userInfo = res2.data[0];
    setLoading(false);

    if (res2.status === 200) {
      login({
        ...userInfo,
      });

      setLoginModalOpen(false);

      if (userInfo.role_level === 1) {
        if (userInfo.active_yn === "N") navigate("/waiting-list");
        else navigate("/novel-list");
      } else if (userInfo.role_level === 0) {
        navigate("/novel-list-admin");
      }
    }
  };

  const getToken = async (code) => {
    const res = await fetch("https://kauth.kakao.com/oauth/token", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
      },
      body: `grant_type=authorization_code&client_id=${process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY}&redirect_uri=${appUrl}&code=${code}`,
    });

    const resJson = await res.json();

    if (resJson.access_token) {
      window.Kakao.Auth.setAccessToken(resJson.access_token);
      requestUserInfo(resJson);
    }
  };

  const requestKaKaoInfo = async () => {
    const params = new URL(document.location.toString()).searchParams;
    const code = params.get("code");
    if (code) {
      setLoading(true);
      getToken(code);
    }
  };

  const logoutWithKakao = async (accessToken) => {
    // const res = (await $get("/api/login-check")).data;
    // if (res.isLoggedIn) {
    fetch("https://kapi.kakao.com/v1/user/logout", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        window.Kakao.Auth.setAccessToken(null);
        logout();
      })
      .catch((err) => {
        console.log(err);
      });
    // } else {
    //   console.log("Not logged in.");
    //   return;
    // }
  };

  const unlinkKakao = async () => {
    const res = (await $get("/api/login-check")).data;
    if (res.isLoggedIn) {
      fetch("https://kapi.kakao.com/v1/user/unlink", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${res.accessToken}`,
        },
      })
        .then((res) => {
          window.Kakao.Auth.setAccessToken(null);
          logout();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("Not logged in.");
      return;
    }
  };

  const initKaKao = () => {
    if (!window.Kakao.isInitialized()) {
      window.Kakao.init(process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY);
    }
  };

  const openLoginModal = (step) => {
    setLoginStep(step);
    setLoginModalOpen(true);
  };

  const checkAgreeAll = (e) => {
    setAgreeAll(e.target.checked);
    if (e.target.checked) {
      setAgreeTerms(true);
      setAgreePrivacy(true);
    } else {
      setAgreeTerms(false);
      setAgreePrivacy(false);
    }
  };

  const checkTerms = (e) => {
    setAgreeTerms(e.target.checked);
    if (e.target.checked && agreePrivacy) setAgreeAll(true);
    else setAgreeAll(false);
  };

  const checkPrivacy = (e) => {
    setAgreePrivacy(e.target.checked);
    if (e.target.checked && agreeTerms) setAgreeAll(true);
    else setAgreeAll(false);
  };

  const onGoogleSuccess = async (response) => {
    console.log(onGoogleSuccess);
    setLoginModalOpen(false);

    const resData = jwtDecode(response.credential);

    const res = await $post("/api/google-login", {
      // tokenId: resData.azp,
      email: resData.email,
      nickname: resData.name,
      profile_image_url: resData.picture,
      access_token_expires_in: resData.exp,
    });
    // 서버로 토큰 전송

    const userInfo = res.data[0];
    // setLoading(false);

    if (res.status === 200) {
      login({
        ...userInfo,
      });

      if (userInfo.role_level === 1) {
        if (userInfo.active_yn === "N") navigate("/waiting-list");
        else navigate("/novel-list");
      } else if (userInfo.role_level === 0) {
        navigate("/novel-list-admin");
      }
    }
  };

  const onGoogleFailure = (response) => {
    setLoginModalOpen(false);
    console.log("Login Failed:", response);
  };

  // const loginWithGoogle = useGoogleLogin({
  //   onSuccess: onGoogleSuccess,
  //   onFailure: onGoogleFailure,
  //   clientId: googleClientId,
  // });

  const initializeNaverLogin = async () => {
    const naverLogin = new window.naver.LoginWithNaverId({
      clientId: naverClientID,
      callbackUrl: appUrl,
      isPopup: false /* 팝업을 통한 연동처리 여부, true 면 팝업 */,
      loginButton: {
        color: "green",
        type: 3,
        // height: 45,
        width: 300,
      } /* 로그인 버튼의 타입을 지정 */,
    });
    naverLogin.init();
    naverLogin.logout();

    await naverLogin.getLoginStatus(async (status) => {
      if (status) {
        const res = await $post("/api/naver-login", {
          // tokenId: resData.azp,
          email: naverLogin.user.email,
          nickname: naverLogin.user.nickname,
          profile_image_url: naverLogin.user.profile_image,
          access_token: naverLogin.accessToken.accessToken,
          access_token_expires_in: naverLogin.accessToken.expires,
        });
        // 서버로 토큰 전송

        const userInfo = res.data[0];
        // setLoading(false);

        if (res.status === 200) {
          login({
            ...userInfo,
          });

          if (userInfo.role_level === 1) {
            if (userInfo.active_yn === "N") navigate("/waiting-list");
            else navigate("/novel-list");
          } else if (userInfo.role_level === 0) {
            navigate("/novel-list-admin");
          }
        }
      }
    });
  };

  const socialLogout = async () => {
    const res = (await $get("/api/login-check")).data;
    if (res.isLoggedIn) {
      if (res.loginType === "KAKAO") {
        logoutWithKakao(res.accessToken);
      } else if (res.loginType === "GOOGLE") {
        logout();
      } else if (res.loginType === "NAVER") {
        logout();
      }
    }
  };

  const mobileCheck = () => {
    const mobileCheck =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );

    // 해상도 모바일 체크
    if (mobileCheck || window.innerWidth < 768) {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "모바일 사용 불가",
        text: "모바일에서는 사용이 불가합니다. PC에서 사용하세요.",
        showConfirmButton: true,
        confirmButtonText: "확인",
      });
      return true;
    } else {
      return false;
    }
  };

  const changeNavMarginLeft = () => {
    // 해상도가 1400px 이상이면 .navbar-nav의 margin-left를 0으로 설정
    if (window.innerWidth >= 1400) {
      let tempMarginLeft = window.innerWidth / 2 - 500 - 230;
      setNavMarginLeft(tempMarginLeft + "px");
    } else {
      setNavMarginLeft("0px");
    }
  };

  const loginWithGoogle = async () => {
    console.log(`${serverUrl}/api/google-login`);
    let url = "https://accounts.google.com/o/oauth2/v2/auth";
    // client_id는 위 스크린샷을 보면 발급 받았음을 알 수 있음
    // 단, 스크린샷에 있는 ID가 아닌 당신이 직접 발급 받은 ID를 사용해야 함.
    url += `?client_id=${googleClientId}`;
    // 아까 등록한 redirect_uri
    // 로그인 창에서 계정을 선택하면 구글 서버가 이 redirect_uri로 redirect 시켜줌
    url += `&redirect_uri=${serverUrl}/api/google-login`;
    // 필수 옵션.
    url += "&response_type=code";
    // 구글에 등록된 유저 정보 email, profile을 가져오겠다 명시
    url += "&scope=email profile";
    // 완성된 url로 이동
    // 이 url이 위에서 본 구글 계정을 선택하는 화면임.

    // 브라우저 url을 이동
    document.location.href = url;
  };

  useEffect(() => {
    initKaKao();
    initializeNaverLogin();
    requestKaKaoInfo();
    changeNavMarginLeft();

    window.addEventListener("resize", changeNavMarginLeft);
    return () => {
      window.removeEventListener("resize", changeNavMarginLeft);
    };
  }, []);

  useEffect(() => {
    setMobileMenuOpen(false);
  }, [location]);

  return (
    <>
      <div className="topnav">
        <div className="mobile-left-menu">
          <button
            className="mobile-menu-button"
            onClick={() => {
              setMobileMenuOpen(true);
            }}
          >
            <i className="fa-solid fa-bars"></i>
          </button>
          <div className="mobile-logo">
            <Link to="/">
              <img src="./assets/images/b-writer-logo-beta.png" />
            </Link>
          </div>
        </div>

        <div className="left-menu">
          <div className="logo">
            <Link to="/">
              <img src="./assets/images/b-writer-logo-beta.png" />
            </Link>
          </div>
          <div className="desktop-menu">
            <ul className="navbar-nav" style={{ marginLeft: navMarginLeft }}>
              {NavData.menus.map((menu, index) => {
                if (
                  menu.childrens &&
                  userData &&
                  userData.role_level == menu.role_level
                ) {
                  return (
                    <li
                      className={
                        location.pathname.startsWith("/novel") &&
                        menu.link.startsWith("/novel")
                          ? "nav-item dropdown active"
                          : "nav-item dropdown"
                      }
                      key={`menu-${index}`}
                    >
                      <a
                        className="nav-link dropdown-toggle"
                        onClick={(e) => {
                          e.target.classList.toggle("show");
                          e.target.nextElementSibling.classList.toggle("show");
                        }}
                      >
                        {menu.name}
                      </a>
                      <ul className="dropdown-menu">
                        {menu.childrens.map((subMenu, index2) => (
                          <li key={`submenu-${index2}`}>
                            <Link
                              className="dropdown-item"
                              to={subMenu.link}
                              state={{
                                auth: subMenu.auth || false,
                                role_level: subMenu.role_level,
                              }}
                              onClick={(e) => {
                                e.target.parentElement.parentElement.previousElementSibling.classList.remove(
                                  "show"
                                );
                                e.target.parentElement.parentElement.classList.remove(
                                  "show"
                                );
                                subMenu.auth &&
                                  !isLoggedIn &&
                                  e.preventDefault();
                              }}
                              style={{
                                opacity:
                                  subMenu.auth && !isLoggedIn ? "0.5" : "",
                              }}
                            >
                              {subMenu.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                  );
                } else {
                  if (
                    menu.role_level === 2 ||
                    (userData &&
                      userData.role_level == menu.role_level &&
                      userData.active_yn === "Y")
                  ) {
                    return (
                      <li
                        className={
                          location.pathname === menu.link
                            ? "nav-item active"
                            : "nav-item"
                        }
                        key={`menu-${index}`}
                      >
                        <Link
                          className="nav-link"
                          to={menu.link}
                          state={{
                            auth: menu.auth || false,
                            role_level: menu.role_level,
                          }}
                          onClick={(e) => {
                            menu.auth && !isLoggedIn && e.preventDefault();
                          }}
                          style={{
                            opacity: menu.auth && !isLoggedIn ? "0.5" : "",
                          }}
                        >
                          {menu.name}
                        </Link>
                      </li>
                    );
                  }
                }
              })}
            </ul>
          </div>
        </div>

        <div className="right-menu">
          {isLoggedIn ? (
            <div className="flex align-items-center">
              <div className="user-menu">
                {/* <div> */}
                <img
                  className="user-avatar"
                  src={
                    userData &&
                    userData.profile_image_url.startsWith("/static/images")
                      ? `${serverUrl}${userData.profile_image_url}`
                      : userData.profile_image_url !== null &&
                        userData.profile_image_url !== ""
                      ? userData.profile_image_url
                      : "./assets/images/default-profile.jpg"
                  }
                  alt="avatar"
                  onClick={() => setUserMenuOpen(!userMenuOpen)}
                />
                {/* </div> */}
                <div
                  className={
                    userMenuOpen ? "dropdown-menu show" : "dropdown-menu"
                  }
                >
                  <a
                    onClick={() => {
                      setUserMenuOpen(false);
                      navigate("/profile");
                    }}
                  >
                    프로필
                  </a>
                  <a onClick={() => socialLogout()}>로그아웃</a>
                  {/* <a onClick={() => unlinkKakao()}>연결끊기</a> */}
                </div>
              </div>
              <div className="user-nickname ml-5">{userData.nickname} 작가</div>
            </div>
          ) : (
            <div className="user-menu">
              <button
                onClick={() => {
                  // if (mobileCheck()) {
                  //   setLoginModalOpen(false);
                  //   return;
                  // }
                  openLoginModal(0);
                }}
                className="btn btn-sm btn-transparent"
              >
                작가 가입
              </button>
              /
              <button
                onClick={() => {
                  // if (mobileCheck()) {
                  //   setLoginModalOpen(false);
                  //   return;
                  // }
                  openLoginModal(1);
                }}
                className="btn btn-sm btn-transparent"
              >
                로그인
              </button>
            </div>
          )}
        </div>
      </div>
      <div className={`mobile-menu ${mobileMenuOpen ? "open" : "close"}`}>
        <div className="mobile-menu-header">
          <a
            className="menu-icon text-white"
            onClick={() => setMobileMenuOpen(false)}
          >
            <i className="fa fa-times"></i>
          </a>
        </div>
        <div className="mobile-menu-container">
          {NavData.menus.map((menu, index) => {
            if (
              menu.role_level === 2 ||
              (userData &&
                userData.role_level == menu.role_level &&
                userData.active_yn === "Y")
            ) {
              return (
                <Link
                  key={`m-menu-${index}`}
                  className={`menu-item ${
                    location.pathname === menu.link ? "active" : ""
                  }`}
                  to={menu.link}
                  state={{ auth: menu.auth || false }}
                >
                  {menu.name}
                </Link>
              );
            }
          })}
        </div>
      </div>
      <div
        className="modal"
        style={{ display: loginModalOpen ? "flex" : "none" }}
      >
        <div className="modal-content modal-sm">
          <div className="modal-header">
            <span className="close" onClick={() => setLoginModalOpen(false)}>
              &times;
            </span>
            <h2>
              {loginStep === 0
                ? "브레드 AI 웹소설 에디터 이용 약관"
                : "환영합니다!"}
            </h2>
          </div>
          <div className="modal-body flex-column align-items-center">
            <div className={loginStep === 0 ? "flex-column" : "display-none"}>
              <div className="flex justify-content-center logo2 mb-10">
                <img src="./assets/images/B_Logo_writer_Beta2.png" alt="logo" />
              </div>
              <div className="flex justify-content-start mb-10">
                <input
                  type="checkbox"
                  id="agreeAll"
                  name="agreeAll"
                  checked={agreeAll}
                  onChange={(e) => checkAgreeAll(e)}
                />
                <label htmlFor="agreeAll">다음 모든 항목에 동의합니다.</label>
              </div>
              <div className="flex justify-content-start mb-10">
                <input
                  type="checkbox"
                  id="agree1"
                  name="agree1"
                  checked={agreeTerms}
                  onChange={(e) => checkTerms(e)}
                />
                <label htmlFor="agree1">
                  (필수) 브레드 AI 웹소설 에디터{" "}
                  <a
                    className="underline"
                    href="https://text-ure.notion.site/844a4f64b2ae4dd18bef9d83b7129195"
                    target="_blank"
                  >
                    이용약관
                  </a>
                  에 동의합니다.
                </label>
              </div>
              <div className="flex justify-content-start mb-20">
                <input
                  type="checkbox"
                  id="agree2"
                  name="agree2"
                  checked={agreePrivacy}
                  onChange={(e) => checkPrivacy(e)}
                />
                <label htmlFor="agree2">
                  (필수){" "}
                  <a
                    className="underline"
                    target="_blank"
                    title="개인정보처리방침"
                    href="https://text-ure.notion.site/6bfa94f2755544e09ed9fb90da92ac43"
                  >
                    개인정보
                  </a>
                  의 수집 및 사용에 동의합니다.
                </label>
              </div>
              <button
                className={
                  agreeAll ? "btn btn-primary" : "btn btn-gray disabled"
                }
                onClick={() => setLoginStep(1)}
                disabled={!agreeAll}
              >
                동의 및 계속
              </button>
            </div>
            <div
              className={
                loginStep === 1
                  ? "flex-column align-items-center"
                  : "display-none"
              }
            >
              <div className="flex justify-content-center logo2 mb-50">
                <img src="./assets/images/B_Logo_writer_Beta2.png" alt="logo" />
              </div>
              <a
                onClick={() => {
                  loginWithKakao();
                }}
                className="mb-10"
              >
                <img
                  src="./assets/images/kakao-login-button.png"
                  width="300px"
                  height="auto"
                  alt="카카오 로그인 버튼"
                />
              </a>
              <a
                onClick={() => {
                  document.querySelector("#naverIdLogin_loginButton").click();
                }}
                className="mb-10"
              >
                <img
                  src="./assets/images/naver-login-button.png"
                  width="300px"
                  height="auto"
                  alt="네이버 로그인 버튼"
                />
              </a>
              <div
                id="naverIdLogin"
                className="mb-10"
                style={{ display: "none" }}
              ></div>
              <div className="google-login mb-30">
                {
                  // 브라우저가 safari인 경우
                  !navigator.userAgent.includes("Safari") && (
                    <GoogleOAuthProvider clientId={googleClientId}>
                      <GoogleLogin
                        onSuccess={onGoogleSuccess}
                        onError={onGoogleFailure}
                        size="large"
                        shape="rectangular"
                        logo_alignment="left"
                        type="standard"
                        width="300px"
                        height="52px"
                        useOneTap={false}
                      />
                    </GoogleOAuthProvider>
                  )
                }

                <img
                  className="google-login-button"
                  src="./assets/images/google-login-button.png"
                  width="300px"
                  height="auto"
                  alt="구글 로그인 버튼"
                  onClick={() => {
                    if (navigator.userAgent.includes("Safari"))
                      loginWithGoogle();
                  }}
                />
              </div>

              <div className="mt-50">
                계속하면 "브레드 AI 웹소설 에디터"의{" "}
                <a
                  className="underline"
                  href="https://text-ure.notion.site/text-ure/844a4f64b2ae4dd18bef9d83b7129195"
                  target="_blank"
                >
                  이용 약관
                </a>
                에 동의하는 것입니다. "브레드 AI 웹소설 에디터"의{" "}
                <a
                  className="underline"
                  href="https://text-ure.notion.site/text-ure/6bfa94f2755544e09ed9fb90da92ac43"
                  target="_blank"
                >
                  개인정보 처리방침
                </a>
                을 확인하세요.
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && <Loading />}
    </>
  );
});

export default Nav;
