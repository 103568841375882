import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { $get, $post, $put, $checkOpenAIAvailable } from "../utils/common";
import Swal from "sweetalert2";
import Loading from "../component/Loading";
import { useAuth } from "../login/AuthProvider";
import BreadProductModal from "./BreadProductModal";

function NovelSetBasic(props) {
  const navigate = useNavigate();

  const { isLoggedIn, userData } = useAuth();
  const [loading, setLoading] = useState(false);
  const [productId, setProductId] = useState(null);
  const [breadUser, setBreadUser] = useState(null);
  const [basicInfo, setBasicInfo] = useState({
    title: "",
    category: 0,
    keyword: "",
    plot: "",
    bread_yn: "N",
    adult_yn: "N",
    bread_product_id: "",
  });
  // const [basicInfo, setBasicInfo] = useState({
  //   title: "두 번째 기회",
  //   category: 9,
  //   keyword: "회귀, 기회, 인생, 성공, 실패, 인간관계",
  //   plot: "50대의 주인공은 치열한 경쟁을 통해 대기업의 사장이 되었지만, 그 경쟁 속에서 때로는 올바르지 않은 행동을 했고, 그 결과 어떤 사람은 큰 피해를 입기도 했어. 어느날 자신의 잘못된 결정으로 인해 회사의 직원이 큰 사고를 당하게 되고, 주인공은 그동안 자신의 삶을 후회하면서 술을 마시게 돼. 술에 취해 거리를 걷던 중 빠르게 달려오는 자동차에 치어서 죽게돼. 그런데 다시 눈을 떠보니, 자신은 20대에 처음으로 회사에 입사한 날 아침으로 돌아가게 돼. 앞으로 일어날 미래를 모두 알고 있는 주인공은 자신의 잘못된 과오를 올바로 잡기 위한 삶을 살게 돼",
  //   bread_yn: "N",
  //   adult_yn: "N",
  //   bread_product_id: "",
  // });
  const [categoryList, setCategoryList] = useState([]);
  const [showBreadProductModal, setShowBreadProductModal] = useState(false);
  const [breadInfo, setBreadInfo] = useState(null);
  const getCategoris = async () => {
    const res = await $get("/api/product/category");
    if (res.status === 200) {
      setCategoryList(res.data);
    } else {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "카테고리 조회 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  const changeBasicInfo = (key, value) => {
    if (key === "bread_yn" && value === "Y") {
      setBasicInfo({ ...basicInfo, bread_yn: "Y" });
      setShowBreadProductModal(true);
    } else if (key === "bread_yn" && value === "N") {
      setBasicInfo({ ...basicInfo, bread_product_id: "", bread_yn: "N" });
      setShowBreadProductModal(false);
    } else {
      setBasicInfo({
        ...basicInfo,
        [key]: value,
      });
    }
  };

  const saveBasicInfo = () => {
    if (basicInfo.title === "") {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "작품명을 입력하세요.",
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }

    if (basicInfo.category === "") {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "카테고리를 선택하세요.",
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }

    if (basicInfo.keyword === "") {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "키워드를 입력하세요.",
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }

    if (basicInfo.plot === "") {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "개요를 입력하세요.",
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }

    if (basicInfo.bread_yn === "Y" && basicInfo.bread_product_id === "") {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "Bread(브레드)와 연동할 작품을 선택하세요.",
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }

    Swal.fire({
      iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
      title: "작품 생성",
      text: "작품을 생성하시겠습니까?",
      showCancelButton: true,
      confirmButtonText: "네, 생성합니다",
      cancelButtonText: "아니오",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $post("/api/product", basicInfo);
        if (res.status === 200) {
          setProductId(res.data.insertId);
          props.onSetProductId(res.data.insertId);
          Swal.fire({
            iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
            title: "작품 생성 성공",
            text: "작품 생성에 성공했습니다.",
            showConfirmButton: false,
            timer: 1000,
          });
        } else {
          Swal.fire({
            iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
            title: "작품 생성 실패",
            text: res.message,
            showConfirmButton: false,
            timer: 1000,
          });
        }
      }
    });
  };

  const updateBasicInfo = () => {
    Swal.fire({
      iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
      title: "작품 수정",
      text: "작품을 수정하시겠습니까?",
      showCancelButton: true,
      confirmButtonText: "예",
      cancelButtonText: "아니오",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let updateInfo = { ...basicInfo };
        delete updateInfo.id;
        delete updateInfo.create_datetime;
        delete updateInfo.category_title;

        const res = await $put(`/api/product/${basicInfo.id}`, updateInfo);
        if (res.status === 200) {
          Swal.fire({
            iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
            title: "작품 수정 성공",
            text: "작품을 수정했습니다.",
            showConfirmButton: false,
            timer: 1000,
          });
        } else {
          Swal.fire({
            iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
            title: "작품 수정 실패",
            text: res.message,
            showConfirmButton: false,
            timer: 1000,
          });
        }
      }
    });
  };

  let bProgress = false;

  const getBasicInfo = async (id) => {
    const res = await $get(`/api/product/${id}`);
    if (res.status === 200) {
      setBasicInfo(res.data);
    } else {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "작품 조회 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  const setConnectedBreadInfo = (breadInfo) => {
    setBasicInfo({
      ...basicInfo,
      bread_product_id: breadInfo.bread_product_id,
      title: breadInfo.title,
      plot: breadInfo.plot,
      category: breadInfo.category,
    });

    setBreadInfo(breadInfo);
  };

  const checkBreadUser = async () => {
    const res = await $get("/api/bread/check-user");
    if (res.status === 200) {
      setBreadUser(res.data);
    }
  };

  const showPlotGuide = () => {
    // Swal width 600px
    Swal.fire({
      iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
      title: "개요 작성 가이드",
      html: `<div style="text-align:left !important;"><p>이야기 아이디어의 개요를 글로 설명해주세요.</p>
      <p>전체 줄거리나 세계관, 혹은 시놉시스나 로그라인 등 이야기의 설정을 보다 구체적으로 작성하면,
      원하는 에피소드에 더 가까이 도달할 수 있습니다.</p>
      <p>(2,000자 이내로 작성하세요)</p>
      </br>
      <p>예시)</p>
      <p><죠스> 로그라인 :</p>
      <p>작은 해안마을에 나타난 식인상어를 처치하기 위해 싸우는 경찰서장의 이야기</p>
      <p><죠스> 시놉시스 :</p>
      <p>롱아일랜드 남부 해안의 애미티 마을에서, 거대한 백상아리가 젊은 여성을 공격해 죽임으로써 이야기가 시작된다. 애미티 경찰서장 마틴 브로디는 해변 폐쇄를 명령하지만, 관광 수입을 우려한 시장과 의회가 이를 거부하며 사건을 은폐한다. 이후 상어는 더 많은 사람들을 죽이고, 브로디는 해양생물학자 매트 후퍼와 상어 사냥꾼 퀸트와 함께 상어 사냥에 나선다. 한편, 브로디의 아내 엘렌은 좋았던 과거를 회상하며 과거에 인연이 있던 후퍼와 관계를 맺는다.</p>
      <p>상어 공격 소식이 퍼지자 구경꾼들이 몰려들고, 브로디는 해변을 폐쇄하고 상어를 사냥하기로 결심한다. 브로디, 후퍼, 퀸트는 바다로 나가지만, 서로 갈등을 지닌채 상어와 맞서 싸운다. 후퍼는 상어에게 잡아먹히고, 퀸트도 상어와의 싸움에서 살아남지 못한다. 결국 브로디는 상어를 죽이고, 혼자 해변으로 돌아오며, 상어의 위협에서 마을을 구한다. 이 과정에서 마을의 부패와 개인적 갈등이 드러나지만, 브로디는 자신의 가족과 마을에 대한 상처를 수습하고 화해의 삶에 대한 결심을 하게 된다.</p></div>`,
      showConfirmButton: false,
      showCancelButton: false,
      showCloseButton: true,
      width: "800px",
    });
  };

  useEffect(() => {
    getCategoris();
    $checkOpenAIAvailable();
    // checkBreadUser();
  }, []);

  useEffect(() => {
    if (props.productId !== null) {
      setProductId(props.productId);
      getBasicInfo(props.productId);
    }
  }, [props.productId]);

  return (
    <div className="register-box">
      <div className="sub-title font-nanumMyeongjo">기본 정보 설정하기</div>
      <div className="description">
        <div>
          <p>기본 아이디어만 있으면 이야기를 시작할 수 있습니다.</p>
          <p>
            작품 이름, 카테고리, 키워드, 그리고 작품 개요를 상세히 작성하여
            첫번째 단계를 완료하세요.
          </p>
        </div>
      </div>
      <div className="form-group">
        <label className="form-label">작품 이름</label>
        <input
          type="text"
          className="form-control"
          placeholder="작품 이름을 적으세요."
          value={basicInfo.title}
          onChange={(e) => {
            changeBasicInfo("title", e.target.value);
          }}
        />
      </div>
      {/* 카테고리(select), 키워드(input text), 개요(textarea) */}
      <div className="form-group">
        <label className="form-label">작품 카테고리</label>
        <select
          className="form-control mr-10"
          style={{ width: "200px" }}
          value={basicInfo.category}
          onChange={(e) => {
            changeBasicInfo("category", e.target.value);
          }}
        >
          <option value="" style={{ color: "#aaaaaa" }}>
            카테고리를 선택하세요.
          </option>
          {categoryList.map((category) => (
            <option key={category.id} value={category.id}>
              {category.category_title}
            </option>
          ))}
        </select>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            value={basicInfo.adult_yn}
            checked={basicInfo.adult_yn === "Y"}
            onChange={(e) => {
              e.target.value === "Y"
                ? changeBasicInfo("adult_yn", "N")
                : changeBasicInfo("adult_yn", "Y");
            }}
            id="adultYN"
          />
          <label className="form-check-label" htmlFor="adultYN">
            19세 이상
          </label>
        </div>
      </div>
      <div className="form-group">
        <label className="form-label">키워드</label>
        <input
          type="text"
          className="form-control"
          placeholder="작품을 상징하는 키워드를 여러 개 적어보세요. 예)로맨스, 판타지, 학원물, 복수극"
          value={basicInfo.keyword}
          onChange={(e) => {
            changeBasicInfo("keyword", e.target.value);
          }}
        />
      </div>
      <div className="form-group">
        <label className="form-label">
          작품 개요{" "}
          <i
            className="fa-solid fa-circle-info"
            style={{ cursor: "pointer", color: "var(--bread-primary-main)" }}
            onClick={(e) => showPlotGuide()}
          ></i>
        </label>
        <textarea
          className="form-control color-gray2"
          rows="10"
          placeholder="이야기 아이디어의 개요를 글로 설명해주세요. 전체 줄거리나 세계관, 혹은 시놉시스나 로그라인 등 이야기의 설정을 보다 구체적으로 작성하면
          원하는 에피소드에 더 가까이 도달할 수 있습니다. 자세한 가이드는 왼쪽 작품 개요 가이드 아이콘을 클릭하면 확인할 수 있습니다."
          value={basicInfo.plot}
          onChange={(e) => {
            changeBasicInfo(
              "plot",
              e.target.value.length > 2000
                ? e.target.value.substring(0, 2000)
                : e.target.value
            );
          }}
        ></textarea>
      </div>
      {/* 해당 작품에 대한 정보를 Bread(브레드)와 연동 하시겠습니까? - input type=radio */}
      {userData && userData.bread_user_yn === "Y" && (
        <div className="row flex-column justify-content-center align-items-center mt-20">
          <label className="label">
            해당 작품에 대한 정보를 Bread(브레드)와 연동 하시겠습니까?
          </label>

          <div className="row flex justify-content-center">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="bread"
                id="breadY"
                value="Y"
                checked={basicInfo.bread_yn === "Y"}
                onChange={(e) => {
                  changeBasicInfo("bread_yn", e.target.value);
                }}
              />
              <label className="form-check-label" htmlFor="breadY">
                연동함
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="bread"
                id="breadN"
                value="N"
                checked={basicInfo.bread_yn === "N"}
                onChange={(e) => {
                  changeBasicInfo("bread_yn", e.target.value);
                }}
              />
              <label className="form-check-label" htmlFor="breadN">
                연동하지 않음
              </label>
            </div>
          </div>
          {basicInfo.bread_yn === "Y" && (
            <div className="row flex justify-content-center align-items-center">
              연결된 작품ID : {basicInfo.bread_product_id}{" "}
              <button
                type="button"
                className="btn btn-primary btn-sm ml-10"
                onClick={() => setShowBreadProductModal(true)}
              >
                변경
              </button>
            </div>
          )}
        </div>
      )}

      <div className="form-group">
        <label className="form-label"></label>
        <div className="button-list mt-30">
          {productId === null && (
            <button
              type="button"
              className="btn btn-primary mr-5"
              onClick={() => saveBasicInfo()}
            >
              <i className="fa-solid fa-save"></i> 작품 생성하기
            </button>
          )}

          {productId === null && (
            <button
              type="button"
              className="btn btn-default"
              onClick={() => {
                navigate("/novel-list");
              }}
            >
              <i className="fa-solid fa-list"></i> 작품 목록
            </button>
          )}

          {productId !== null && (
            <button
              type="button"
              className="btn btn-default mr-5"
              onClick={() => updateBasicInfo()}
            >
              <i className="fa-solid fa-save"></i> 작품 수정하기
            </button>
          )}

          {productId !== null && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => props.onNext()}
            >
              캐릭터 설정하러가기 <i className="fa-solid fa-arrow-right"></i>
            </button>
          )}
        </div>
      </div>

      {showBreadProductModal && (
        <BreadProductModal
          onModal={(show) => {
            setBasicInfo({ ...basicInfo, bread_yn: "N" });
            setShowBreadProductModal(show);
          }}
          basicInfo={basicInfo}
          breadId={basicInfo.bread_product_id}
          onConnect={(breadInfo) => {
            setConnectedBreadInfo(breadInfo);
            setShowBreadProductModal(false);
          }}
        />
      )}
      {loading && <Loading />}
    </div>
  );
}

export default NovelSetBasic;
