import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../login/AuthProvider";
import {
  $get,
  $put,
  $upload,
  $delete,
  $dateFormat,
  $convertNumberFormat,
} from "../utils/common";
import Swal from "sweetalert2";
import Loading from "../component/Loading";

function PointUsageHistoryModal(props) {
  const [pageLimit, setPageLimit] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageRange, setPageRange] = useState([]);
  const [lastPage, setLastPage] = useState(1);
  const [pointUsageHistory, setPointUsageHistory] = useState([]);
  const [loading, setLoading] = useState(false);

  const paging = () => {
    const pages = [];

    const pageFirst =
      pageNo % 5 === 0 ? pageNo - 4 : Math.floor(pageNo / 5) * 5 + 1;
    let pageLast = pageFirst + 4;

    if (pageLast > totalPage) pageLast = totalPage;
    setLastPage(pageLast);

    for (let i = pageFirst; i <= pageLast; i++) {
      pages.push(i);
    }
    setPageRange(pages);
  };

  const changePagination = async () => {
    const res = await $get(
      `/api/point/${props.userEmail}/${props.activeDate}/${pageNo}/${pageLimit}`
    );
    if (res.status === 200) {
      setPointUsageHistory(res.data.points);
      setTotalPage(Math.ceil(res.data.total_count / pageLimit));
      paging();
    }
  };

  useEffect(() => {
    paging();
  }, [totalPage]);

  useEffect(() => {
    changePagination(pageNo);
  }, [pageNo]);

  return (
    <div className="modal">
      <div className="modal-content modal-lg">
        <div className="modal-header">
          <span className="close" onClick={() => props.onModal(false)}>
            &times;
          </span>
          <h3>사용 내역</h3>
        </div>
        <div className="modal-body">
          <div className="table-responsive">
            <table className="table table-hover table-nowrap">
              <thead>
                <tr>
                  <th>작품명</th>
                  <th>사용기능</th>
                  <th>잼(포인트)</th>
                  {props.roleLevel === 0 && <th>인풋토큰</th>}
                  {props.roleLevel === 0 && <th>아웃풋토큰</th>}
                </tr>
              </thead>
              <tbody>
                {pointUsageHistory &&
                  pointUsageHistory.map((pointUsage, idx) => (
                    <tr key={`payment-${idx}`}>
                      <td>{$dateFormat(pointUsage.title)}</td>
                      <td>{pointUsage.func_title}</td>
                      <td>{$convertNumberFormat(pointUsage.point)}</td>
                      {props.roleLevel === 0 && (
                        <td>
                          {$convertNumberFormat(pointUsage.prompt_tokens)}
                        </td>
                      )}
                      {props.roleLevel === 0 && (
                        <td>
                          {$convertNumberFormat(pointUsage.completion_tokens)}
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>
            {totalPage > 1 && (
              <div className="d-flex justify-content-center mt-10">
                <nav aria-label="Page navigation example">
                  <ul className="pagination">
                    {pageNo > 5 && (
                      <li className="page-item">
                        <a
                          className="page-link"
                          onClick={(e) => setPageNo(pageNo - 1)}
                        >
                          <i className="fa-solid fa-arrow-left"></i>
                        </a>
                      </li>
                    )}

                    {pageRange.map((page) => (
                      <li key={page}>
                        <a
                          className={
                            pageNo === page ? "page-link active" : "page-link"
                          }
                          onClick={(e) => setPageNo(page)}
                        >
                          {page}
                        </a>
                      </li>
                    ))}

                    {lastPage < totalPage && (
                      <li className="page-item">
                        <a
                          className="page-link"
                          onClick={(e) => setPageNo(pageNo + 1)}
                        >
                          <i className="fa-solid fa-arrow-right"></i>
                        </a>
                      </li>
                    )}
                  </ul>
                </nav>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PointUsageHistoryModal;
