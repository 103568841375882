import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { $get } from "../utils/common";
import Swal from "sweetalert2";

function NovelSetBasicView(props) {
  //   const [productId, setProductId] = useState(props.productId);
  const [basicInfo, setBasicInfo] = useState(props.productInfo);

  //   const getBasicInfo = async () => {
  //     const res = await $get(`/api/product/${props.productId}`);
  //     if (res.status === 200) {
  //       setBasicInfo(res.data);
  //     } else {
  //       Swal.fire({
  //         icon: "error",
  //         title: "작품 조회 실패",
  //         text: res.message,
  //         timer: 1000,
  //       });
  //     }
  //   };

  useEffect(() => {
    // getBasicInfo();
  }, []);

  return (
    <div className="content p-30 overflow-y">
      {/* <div className="register-box"> */}
      <div className="sub-title font-nanumMyeongjo mb-30">기본 정보</div>
      {basicInfo && (
        <>
          <div className="form-group">
            <label className="form-label">작품 이름</label>
            <input
              type="text"
              className="form-control"
              value={basicInfo.title}
              disabled
            />
          </div>
          {basicInfo.bread_product_id && (
            <div className="form-group">
              <label className="form-label">브레드 작품 ID</label>
              <input
                type="text"
                className="form-control"
                value={basicInfo.bread_product_id}
                disabled
              />
            </div>
          )}

          <div className="form-group">
            <label className="form-label">작품 카테고리</label>
            <input
              type="text"
              className="form-control"
              value={basicInfo.category_title}
              disabled
            />
          </div>
          <div className="form-group">
            <label className="form-label">키워드</label>
            <input
              type="text"
              className="form-control"
              value={basicInfo.keyword}
              disabled
            />
          </div>
          <div className="form-group">
            <label className="form-label">개요</label>
            <textarea
              className="form-control"
              rows="10"
              placeholder="개요를 입력하세요."
              value={basicInfo.plot}
              disabled
            ></textarea>
          </div>
        </>
      )}
      {/* </div> */}
    </div>
  );
}

export default NovelSetBasicView;
