import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  $get,
  $put,
  $post,
  $checkOpenAIAvailable,
  $delete,
  $convertNumberFormat,
} from "../utils/common";
import Swal from "sweetalert2";
import CharacterModal from "../component/CharacterModal";
import Loading from "../component/Loading";
import { useAuth } from "../login/AuthProvider";

function NovelSetCharacter(props) {
  const navigate = useNavigate();
  const { isLoggedIn, userData, setUserData } = useAuth();
  const [loading, setLoading] = useState(false);
  const [characters, setCharacters] = useState([]);
  const [characterModalOpen, setCharacterModalOpen] = useState(false);
  const [activeCharacter, setActiveCharacter] = useState(null);

  const saveCharacter = () => {
    props.onNext(1);
  };

  const deleteCharacter = async (id) => {
    Swal.fire({
      iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
      title: "등장 인물 삭제",
      text: "인물을 삭제하시겠습니까?",
      showCancelButton: true,
      confirmButtonText: "예",
      cancelButtonText: "아니오",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $delete(`/api/product/character/${id}`);
        if (res.status === 200) {
          getCharacterList();
        } else {
          Swal.fire({
            iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
            title: "등장 인물 삭제 실패",
            text: res.message,
            showConfirmButton: false,
            timer: 1000,
          });
        }
      }
    });
  };

  const addCharacter = () => {
    setActiveCharacter({
      name: "",
      gender: "",
      age: "",
      job: "",
      role: "",
      personality: "",
      background: "",
    });
    setCharacterModalOpen(true);
  };

  const getUserPoints = async () => {
    const res = await $get("/api/user/points");
    if (res.status === 200) {
      return res.data.points;
    } else {
      return 0;
    }
  };

  const getDeductionPoints = async (funcCategory) => {
    const res = await $get(`/api/user/func/points/${funcCategory}`);
    if (res.status === 200) {
      return res.data.deduction_points;
    } else {
      return 0;
    }
  };

  let bProgress = false;

  const generateCharacter = async () => {
    if (bProgress) return;

    const deductionPoints = await getDeductionPoints("A");
    const userPoints = await getUserPoints();
    if (userPoints < deductionPoints) {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: `잼이 부족합니다.`,
        text: `잼을 충전하시겠습니까?(나의 잼: ${$convertNumberFormat(
          userPoints
        )}, 필요 잼: ${$convertNumberFormat(deductionPoints)})`,
        showCancelButton: true,
        confirmButtonText: "네, 충전하겠습니다.",
        cancelButtonText: "아니오.",
      }).then(async (result) => {
        if (result.isConfirmed) {
          navigate("/payment");
        }
      });
      return;
    } else {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: `AI 캐릭터 제안 받기`,
        text: `${$convertNumberFormat(
          deductionPoints
        )}잼이 사용됩니다. AI 캐릭터 제안을 받으시겠어요?`,
        showCancelButton: true,
        confirmButtonText: "네, 제안해주세요",
        cancelButtonText: "아니오",
      }).then(async (result) => {
        if (result.isConfirmed) {
          bProgress = true;
          setLoading(true);
          const basicInfo = await $get(`/api/product/${props.productId}`);

          const res = await $post("/api/prompt/characters", {
            productId: props.productId,
            title: basicInfo.data.title,
            category: basicInfo.data.category_title,
            keyword: basicInfo.data.keyword,
            plot: basicInfo.data.plot,
          });

          bProgress = false;
          setLoading(false);
          if (res.status === 200) {
            getCharacterList();
          } else {
            Swal.fire({
              iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
              title: "AI 캐릭터 제안 실패",
              text: res.message,
              showConfirmButton: false,
              timer: 1000,
            });
          }
        }
      });
    }
  };

  const getCharacterList = async () => {
    const res = await $get(`/api/product/character/${props.productId}`);
    if (res.status === 200) {
      setCharacters(res.data);
    } else {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "등장 인물 조회 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  useEffect(() => {
    $checkOpenAIAvailable();
  }, []);

  useEffect(() => {
    getCharacterList();
  }, [props.productId]);
  return (
    <div className="register-box">
      <div className="sub-title font-nanumMyeongjo">캐릭터 설정하기</div>
      <div className="description">
        <div>
          <p>직접 캐릭터를 설정할 수도 있고, AI에게 추천받을 수 있습니다.</p>
          <p>
            AI에게 추천받은 이후 캐릭터를 수정하거나 직접 추가할 수도 있습니다.
          </p>
        </div>
        <button
          type="button"
          className="btn btn-main"
          onClick={() => generateCharacter()}
        >
          <span>
            캐릭터
            <br />
            제안 받기
          </span>{" "}
          <i className="fa-regular fa-lightbulb"></i>
        </button>
      </div>

      {/* <div className="table-responsive"> */}
      <table className="table table-hover">
        <thead>
          <tr>
            <th width="120px">이름</th>
            <th width="70px">성별</th>
            <th width="70px">나이</th>
            <th width="100px">직업</th>
            <th width="100px">역할</th>
            <th>성격</th>
            <th>배경</th>
            <th width="140px"></th>
          </tr>
        </thead>
        <tbody>
          {characters.map((character, i) => (
            <tr key={`character-${i}`}>
              <td>{character.name}</td>
              <td>{character.gender}</td>
              <td>{character.age}</td>
              <td>{character.job}</td>
              <td>{character.role}</td>
              <td>{character.personality}</td>
              <td>{character.background}</td>
              <td>
                <button
                  className="btn btn-transparent btn-sm tooltip"
                  onClick={() => {
                    setActiveCharacter(characters[i]);
                    setCharacterModalOpen(true);
                  }}
                >
                  <i className="fa-solid fa-edit"></i>
                  <div className="tooltiptext">수정</div>
                </button>
                <button
                  className="btn btn-transparent btn-sm tooltip"
                  onClick={() => deleteCharacter(character.id)}
                >
                  <i className="fa-solid fa-trash-can"></i>
                  <div className="tooltiptext">삭제</div>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* </div> */}
      <div>
        <button
          type="button"
          className="btn btn-default btn-block btn-rect"
          onClick={() => addCharacter()}
        >
          <i className="fa-solid fa-plus"></i> 캐릭터 직접 추가하기
        </button>
      </div>
      <div className="button-list mt-30">
        {characters.length > 0 && (
          <button
            type="button"
            className="btn btn-primary mr-10"
            onClick={() => props.onNext(1)}
          >
            캐릭터 설정 완료
          </button>
        )}
        <button
          type="button"
          className="btn btn-default"
          onClick={() => props.onNext(-1)}
        >
          이전으로 돌아가기
        </button>
      </div>
      {characterModalOpen && (
        <CharacterModal
          productId={props.productId}
          character={activeCharacter}
          onModal={() => {
            setCharacterModalOpen(false);
            getCharacterList();
          }}
          onSave={saveCharacter}
        />
      )}
      {loading && <Loading message="캐릭터를 생성중입니다..." />}
    </div>
  );
}

export default NovelSetCharacter;
