import React, { useState, useEffect } from "react";
import { $put, $post } from "../utils/common";
import Swal from "sweetalert2";

function PaymentModal(props) {
  const [point, setPoint] = useState(props.activePayment.point);
  const [price, setPrice] = useState(props.activePayment.price);

  const savePayment = async (e) => {
    Swal.fire({
      iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
      title: "결제 상품 수정",
      text: "수정하시겠습니까?",
      showCancelButton: true,
      confirmButtonText: "예",
      cancelButtonText: "아니오",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $put(`/api/point/price/${props.activePayment.id}`, {
          price: price,
          point: point,
        });

        if (res.status === 200) {
          props.onSave();
        } else {
          Swal.fire({
            iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
            title: "결제 상품 수정 실패",
            text: res.message,
            showConfirmButton: false,
            timer: 1000,
          });
        }
      }
    });
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="modal-header">
          <span className="close" onClick={() => props.onModal(false)}>
            &times;
          </span>
          <h3>결제 상품</h3>
        </div>
        <div className="modal-body">
          <div className="form-group">
            <label className="form-label">결제 금액(원)</label>
            <input
              type="number"
              className="form-control"
              value={price}
              onChange={(e) => setPrice(e.target.value.replace(/[^0-9]/g, ""))}
            />
          </div>
          <div className="form-group">
            <label className="form-label">충전 잼(포인트)</label>
            <input
              type="number"
              className="form-control"
              value={point}
              onChange={(e) => setPoint(e.target.value.replace(/[^0-9]/g, ""))}
            />
          </div>
        </div>
        <div className="modal-footer flex justify-content-end">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => savePayment()}
          >
            저장
          </button>
        </div>
      </div>
    </div>
  );
}

export default PaymentModal;
