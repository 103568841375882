import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useAuth } from "../login/AuthProvider";
import { useNavigate, useLocation } from "react-router-dom";
import {
  $post,
  $put,
  $delete,
  $get,
  $download,
  $checkOpenAIAvailable,
  $convertMarkdownToHtml,
  $convertNumberFormat,
} from "../utils/common";
import Swal from "sweetalert2";
import SummaryAssistantModal from "../component/SummaryAssistantModal";
import ParagraphAssistantModal from "../component/ParagraphAssistantModal";
import MobileSimulator from "../component/MobileSimulator";
import Loading from "../component/Loading";
import SpellCheckerModal from "../component/SpellCheckerModal";

const EpisodeEditor = forwardRef((props, ref) => {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const navigate = useNavigate();
  const { isLoggedIn, userData } = useAuth();

  const [leftSidebarOpen, setLeftSidebarOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [isAuthor, setIsAuthor] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [lastModifiedDatetime, setLastModifiedDatetime] = useState(new Date());
  const [basicInfo, setBasicInfo] = useState(props.productInfo);
  const [activeEpisode, setActiveEpisode] = useState(null);
  const [episodeTitle, setEpisodeTitle] = useState(props.episode.title);
  const [episodeContent, setEpisodeContent] = useState(props.episode.content);
  const [episodeCompleteYN, setEpisodeCompleteYN] = useState(
    props.episode.complete_yn
  );
  const [assistantMessage, setAssistantMessage] = useState(
    props.episode.content
  );
  const [episodeContentLength, setEpisodeContentLength] = useState(0);

  const [assistantModalOpen, setAssistantModalOpen] = useState(false);
  const [selectedText, setSelectedText] = useState("");
  const [selectedRange, setSelectedRange] = useState(null);
  const [showAIPopover, setShowAIPopover] = useState(false);
  const [showMemoViewPopover, setShowMemoViewPopover] = useState(false);
  const [memoMessage, setMemoMessage] = useState("");
  const [AIPopoverPosition, setAIPopoverPosition] = useState({
    top: 0,
    left: 0,
  });
  const [showMemoPopover, setShowMemoPopover] = useState(false);
  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const [showParagraphModal, setShowParagraphModal] = useState(false);
  const [showMobileSimulator, setShowMobileSimulator] = useState(false);
  const [spellCheckText, setSpellCheckText] = useState([]);
  const [showSpellCheckModal, setShowSpellCheckModal] = useState(false);
  const [mainFont, setMainFont] = useState("font-gothic");
  const [fontSize, setFontSize] = useState("1");
  const scrollRef = useRef(null);
  const noteRef = useRef(null);
  const assistantRef = useRef(null);

  const koreanFontFamily = [
    { className: "font-gothic", title: "고딕" },
    { className: "font-nanumMyeongjo", title: "나눔명조" },
    { className: "font-pretendard", title: "프리텐다드" },
  ];

  useImperativeHandle(ref, () => ({
    scrollNote: (memoId) => {
      const mark = document.querySelector(`mark[data-memo-id="${memoId}"]`);
      if (mark) {
        mark.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    },
    deleteMemo: (memoId) => {
      // const mark = document.querySelector(`mark[data-memo-id="${memoId}"]`);
      // if (mark) {
      //   mark.outerHTML = mark.innerHTML;
      // }
      deleteMemo2(memoId);
    },
  }));

  const openLeftSidebar = () => {
    setLeftSidebarOpen(!leftSidebarOpen);
  };

  const convertHTMLStringToElements = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    return Array.from(doc.body.childNodes);
  };

  const resizeHeight = (bScroll = true) => {
    // 공백 제거 regex

    // const totalTextLength = Array.from(
    //   convertHTMLStringToElements(noteRef.current.innerHTML)
    // ).reduce((acc, cur) => {
    //   return acc + cur.textContent.replace(/\s/g, "").length;
    // }, 0);

    setTimeout(() => {
      const totalTextLength = Array.from(noteRef.current.childNodes).reduce(
        (acc, cur) => {
          return acc + cur.textContent.replace(/\s/g, "").length;
        },
        0
      );

      setEpisodeContentLength(totalTextLength);
    }, 1000);

    // setEpisodeContentLength(
    //   noteRef.current.innerText.replace(/\s/g, "").length
    // );
    if (bScroll) scrollRef.current.scrollTop = scrollRef.current.scrollHeight;

    // lastModifiedDatetime과 현재 datetime을 비교해서 60초 이상 차이가 나면 자동 저장
    const now = new Date();
    const diff = now.getTime() - lastModifiedDatetime.getTime();

    // localStorage에 beingSaveWork 가 있는지 확인
    // beingSaveWork 가 true이면 자동 저장 중이므로 return
    if (
      localStorage.getItem("beingSaveWork") === undefined ||
      localStorage.getItem("beingSaveWork") === null ||
      localStorage.getItem("beingSaveWork") === "true"
    )
      return;

    if (diff > 60000) {
      localStorage.setItem("beingSaveWork", true);
      setTimeout(() => {
        if (!loading) saveEpisode();
        setLastModifiedDatetime(now);
        localStorage.setItem("beingSaveWork", false);
      }, 1000);
    }
  };

  const handleMouseOver = (e) => {
    // e.target == <mark>
    if (e.target.tagName === "MARK") {
      setMemoMessage(e.target.getAttribute("data-memo"));
      setShowMemoViewPopover(true);
      const rect = e.target.getBoundingClientRect();
      setAIPopoverPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX + rect.width,
      });
    } else {
      setMemoMessage("");
      setShowMemoViewPopover(false);
    }
  };

  const handleSelectionChange = () => {
    if (assistantModalOpen) return;
    const selection = window.getSelection();
    const selectedText = selection.toString().trim();

    if (
      selectedText.length > 0 &&
      noteRef.current.contains(selection.anchorNode)
    ) {
      setSelectedText(selectedText);
      const range = selection.getRangeAt(0);
      setSelectedRange(range);
      const rect = range.getBoundingClientRect();
      // 선택된 텍스트의 위치를 기준으로 popover가 부모 요소의 왼쪽이나 오른쪽을 벗어나지 않도록 위치 조정
      //   const top = rect.bottom;
      //   const left = rect.left;
      //   const right = rect.left + window.scrollX + rect.width;
      //   setAIPopoverPosition({
      //     top: top,
      //     left: left,
      //   });

      setAIPopoverPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX + rect.width,
        // left: rect.left + window.scrollX + rect.width / 2,
      });

      //   setShowAIPopover(true);
    } else {
      setShowAIPopover(false);
    }
  };

  const getDirectParentElement = (e) => {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    let parentElement = range.commonAncestorContainer;

    // 텍스트 노드가 반환될 경우, 부모 요소를 얻음
    if (parentElement.nodeType === Node.TEXT_NODE) {
      parentElement = parentElement.parentNode;
    }

    return parentElement;
  };

  const htmlStringToNode = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    if (doc.body.childNodes.length === 1) {
      if (doc.body.firstChild.nodeType === Node.TEXT_NODE) {
        return doc.body.firstChild.textContent;
      } else {
        return doc.body.firstChild.innerHTML;
      }
    } else {
      return doc.body.innerHTML;
    }
  };

  const checkNode = (nodeName) => {
    let node = getDirectParentElement();
    while (
      node !== noteRef.current &&
      node.nodeName !== nodeName &&
      node.nodeName !== "P"
    ) {
      node = node.parentNode;
    }

    const html = node.outerHTML;
    console.log("html", html);
    if (html.indexOf(`<${nodeName}>`) > -1) {
      const ele = htmlStringToNode(
        html.replace(`<${nodeName}>`, "").replace(`</${nodeName}>`, "")
      );
      console.log(ele);
      return [true, ele, node];
    }

    return [false, null];
  };

  const applyFormat = (format) => {
    if (!editMode) return;
    const selection = window.getSelection();
    if (noteRef.current.contains(selection.anchorNode)) {
      setSelectedText(selectedText);
      const range = selection.getRangeAt(0);
      setSelectedRange(range);

      if (format === "bold" && selectedText.length > 0) {
        // if (getDirectParentElement().nodeName === "STRONG") {
        //   const strong = getDirectParentElement();
        //   const text = strong.innerText;
        //   strong.parentNode.removeChild(strong);
        //   range.insertNode(document.createTextNode(text));
        // } else {
        //   const strong = document.createElement("strong");
        //   strong.appendChild(range.extractContents());
        //   range.insertNode(strong);
        // }
        const ele = getDirectParentElement();
        if (ele.outerHTML.indexOf("<strong>") > -1) {
          ele.outerHTML = ele.outerHTML
            .replace("<strong>", "")
            .replace("</strong>", "");
        } else {
          const node = checkNode("strong");
          console.log("node", node);
          if (node[0]) {
            node[2].innerHTML = node[1];
          } else {
            const strong = document.createElement("strong");
            strong.appendChild(range.extractContents());
            range.insertNode(strong);
          }
        }
      } else if (format === "italic" && selectedText.length > 0) {
        // if (getDirectParentElement().nodeName === "EM") {
        //   const em = getDirectParentElement();
        //   const text = em.innerText;
        //   em.parentNode.removeChild(em);
        //   range.insertNode(document.createTextNode(text));
        // } else {
        //   const em = document.createElement("em");
        //   em.appendChild(range.extractContents());
        //   range.insertNode(em);
        // }

        const ele = getDirectParentElement();
        if (ele.outerHTML.indexOf("<em>") > -1) {
          ele.outerHTML = ele.outerHTML
            .replace("<em>", "")
            .replace("</em>", "");
        } else {
          const node = checkNode("em");
          if (node[0]) {
            node[2].innerHTML = node[1];
          } else {
            const em = document.createElement("em");
            em.appendChild(range.extractContents());
            range.insertNode(em);
          }
        }
      } else if (format === "underline" && selectedText.length > 0) {
        // if (getDirectParentElement().nodeName === "U") {
        //   const u = getDirectParentElement();
        //   const text = u.innerText;
        //   u.parentNode.removeChild(u);
        //   range.insertNode(document.createTextNode(text));
        // } else {
        //   const u = document.createElement("u");
        //   u.appendChild(range.extractContents());
        //   range.insertNode(u);
        // }
        const ele = getDirectParentElement();
        // console.log(ele.outerHTML.st)
        if (ele.outerHTML.indexOf("<u>") > -1) {
          ele.outerHTML = ele.outerHTML.replace("<u>", "").replace("</u>", "");
        } else {
          const node = checkNode("u");
          if (node[0]) {
            node[2].innerHTML = node[1];
          } else {
            const u = document.createElement("u");
            u.appendChild(range.extractContents());
            range.insertNode(u);
          }
        }
      } else if (format === "strikethrough" && selectedText.length > 0) {
        // if (getDirectParentElement().nodeName === "S") {
        //   const s = getDirectParentElement();
        //   const text = s.innerText;
        //   s.parentNode.removeChild(s);
        //   range.insertNode(document.createTextNode(text));
        // } else {
        //   const s = document.createElement("s");
        //   s.appendChild(range.extractContents());
        //   range.insertNode(s);
        // }
        const ele = getDirectParentElement();
        if (ele.outerHTML.indexOf("<s>") > -1) {
          ele.outerHTML = ele.outerHTML.replace("<s>", "").replace("</s>", "");
        } else {
          const node = checkNode("s");
          if (node[0]) {
            node[2].innerHTML = node[1];
            // node[2].outerHTML = node[2].innerHTML;
          } else {
            const s = document.createElement("s");
            s.appendChild(range.extractContents());
            range.insertNode(s);
          }
        }
      } else if (format === "bigger" && selectedText.length > 0) {
        if (getDirectParentElement().nodeName === "P") {
          const s = document.createElement("span");
          s.style.fontSize = "1.2rem";
          s.appendChild(range.extractContents());
          range.insertNode(s);
        }
      } else if (format === "smaller" && selectedText.length > 0) {
        if (
          getDirectParentElement().nodeName === "SPAN" &&
          getDirectParentElement().style.fontSize === "1.2rem"
        ) {
          const span = getDirectParentElement();
          const text = span.innerText;
          span.parentNode.removeChild(span);
          range.insertNode(document.createTextNode(text));
        }
      } else if (format === "quote" && selectedText.length > 0) {
        if (getDirectParentElement().nodeName !== "Q") {
          getDirectParentElement().style.textAlign = "center";
          const q = document.createElement("q");
          const text = range.extractContents();
          q.style.fontSize = "1.2rem";
          q.style.fontWeight = "700";
          q.appendChild(text);
          range.insertNode(q);
        }
      } else if (format === "alignLeft") {
        getDirectParentElement().style.textAlign = "left";
      } else if (format === "alignCenter") {
        getDirectParentElement().style.textAlign = "center";
      } else if (format === "alignRight") {
        getDirectParentElement().style.textAlign = "right";
      } else if (format === "alignJustify") {
        const p = document.createElement("p");
        p.style.textAlign = "justify";
        p.appendChild(range.extractContents());
        range.insertNode(p);
      } else if (format === "indent") {
        if (getDirectParentElement().style.paddingLeft === "") {
          getDirectParentElement().style.paddingLeft = "20px";
        } else {
          getDirectParentElement().style.paddingLeft =
            parseInt(getDirectParentElement().style.paddingLeft) + 20 + "px";
        }
      } else if (format === "outdent") {
        if (parseInt(getDirectParentElement().style.paddingLeft) >= 20) {
          getDirectParentElement().style.paddingLeft =
            parseInt(getDirectParentElement().style.paddingLeft) - 20 + "px";
        }
      }
    }
  };

  const removeSelectedText = () => {
    if (selectedRange) {
      selectedRange.deleteContents();
      setSelectedRange(null);
      setSelectedText("");
      setShowAIPopover(false);
    }
  };

  const hidePopover = (e) => {
    // double click return;
    if (e.detail > 1) return;

    if (e.target.closest(".fa-robot")) return;
    if (e.target.closest(".fa-message")) return;

    setShowAIPopover(false);
    setShowMemoPopover(false);
  };

  const insertMemo = async (memo) => {
    if (memo === "") return;

    setShowMemoPopover(false);

    const res = await $post("/api/product/episode-memo", {
      product_id: activeEpisode.product_id,
      episode_id: activeEpisode.id,
      memo: memo,
    });

    if (res.status === 200) {
      const mark = document.createElement("mark");

      if (isAuthor) mark.setAttribute("class", "author");
      else mark.setAttribute("class", "subauthor");

      mark.setAttribute("data-memo-id", res.data.insertId);
      mark.setAttribute("data-memo", memo);
      mark.appendChild(selectedRange.extractContents());
      selectedRange.insertNode(mark);
      props.onMemo(activeEpisode.id);
      setTimeout(async () => {
        const res = await $put(`/api/product/episode/${activeEpisode.id}`, {
          title: episodeTitle,
          content: noteRef.current.innerHTML,
        });
      }, 1000);
    }
  };

  const deleteMemo2 = async (memoId) => {
    const res = await $delete(`/api/product/episode-memo/${memoId}`);
    if (res.status === 200) {
      const mark = document.querySelector(`mark[data-memo-id="${memoId}"]`);
      mark.outerHTML = mark.innerHTML;
      props.onMemo(activeEpisode.id);
      setTimeout(async () => {
        const res = await $put(`/api/product/episode/${activeEpisode.id}`, {
          title: episodeTitle,
          content: noteRef.current.innerHTML,
        });
      }, 1000);
    }
  };

  const writeNextParagraph = async () => {
    if (!editMode) return;
    if (!activeEpisode) return;
    if (!noteRef.current) return;

    const totalTextLength = Array.from(noteRef.current.childNodes).reduce(
      (acc, cur) => {
        return acc + cur.textContent.replace(/\s/g, "").length;
      },
      0
    );

    if (totalTextLength < 300) {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "이어쓰기를 할 수 없습니다.",
        text: "에피소드 내용이 너무 짧습니다.",
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }

    const deductionPoints = await getDeductionPoints("D");
    const userPoints = await getUserPoints();
    if (userPoints < deductionPoints) {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: `잼이 부족합니다.`,
        text: `잼을 충전하시겠습니까?(나의 잼: ${$convertNumberFormat(
          userPoints
        )}, 필요 잼: ${$convertNumberFormat(deductionPoints)})`,
        showCancelButton: true,
        confirmButtonText: "네, 충전하겠습니다.",
        cancelButtonText: "아니오.",
      }).then(async (result) => {
        if (result.isConfirmed) {
          navigate("/payment");
        }
      });
      return;
    } else {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "이어쓰기",
        text: `${$convertNumberFormat(
          deductionPoints
        )}잼이 사용됩니다. 이어쓰기 하시겠습니까?`,
        showCancelButton: true,
        confirmButtonText: "예",
        cancelButtonText: "아니오",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true);
          sessionStorage.setItem("stopAssistant", false);

          let url = `${serverUrl}/api/prompt/next-paragraph`;

          const res = await fetch(url, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "include",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              productId: activeEpisode.product_id,
              episodeNo: activeEpisode.episode_no,
              episodeContent: noteRef.current.innerHTML,
            }),
          });

          const reader = res.body
            .pipeThrough(new TextDecoderStream())
            .getReader();
          const tempAssistantMessage = [];
          const finishRegexp = /(\[\[.*?\]\])/;
          let finishReason = "";
          while (true) {
            if (sessionStorage.getItem("stopAssistant") === "true") {
              reader.cancel();
              reader.releaseLock();
              break;
            }

            let { value, done } = await reader.read();
            if (done) break;

            // value = value.replace(/(?:\r\n|\r|\n)/g, "<br />");
            if (finishRegexp.test(value)) {
              finishReason = value.match(finishRegexp)[0];
              value = "";
            }

            setAssistantMessage((prev) => prev + value);
            tempAssistantMessage.push(value);
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
          }

          sessionStorage.setItem("stopAssistant", false);

          setAssistantMessage("");

          setEpisodeContent(
            noteRef.current.innerHTML +
              $convertMarkdownToHtml(tempAssistantMessage.join("")) +
              "<p><br></p>"
          );

          saveEpisode();

          setLoading(false);
          resizeHeight();
        }
      });
    }
  };

  const toggleEdit = async () => {
    if (!editMode) {
      const res = await $get(`/api/product/episode/detail/${activeEpisode.id}`);
      if (
        res.data.checkin_email === null ||
        res.data.checkin_email === "" ||
        res.data.checkin_email === userData.email
      ) {
        const res2 = await $put(`/api/product/episode/${activeEpisode.id}`, {
          checkin_email: userData.email,
        });
        if (res2.status === 200) {
          setEditMode(true);
        } else {
          Swal.fire({
            iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
            title: "에피소드 작성자 변경 실패",
            text: res2.message,
            showConfirmButton: false,
            timer: 1000,
          });
        }
      } else {
        Swal.fire({
          iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
          title: `${res.data.checkin_email} 사용자가 작성 중입니다.`,
          text: `에피소드 작성은 한사람만 가능합니다. 작성을 원하는 경우  ${res.data.checkin_email}에 편집 완료로 상태 변경을 요청하세요.`,
          showConfirmButton: false,
          timer: 1000,
        });
      }
    } else {
      const res = await $put(`/api/product/episode/${activeEpisode.id}`, {
        checkin_email: "",
      });
      if (res.status === 200) {
        setEditMode(false);
        saveEpisode();
      } else {
        Swal.fire({
          iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
          title: "에피소드 작성자 해제 실패",
          text: res.message,
          showConfirmButton: false,
          timer: 1000,
        });
      }
    }
  };

  const changeParagraph = (paragraph) => {
    const range = window.getSelection().getRangeAt(0);
    const newNodes = convertHTMLStringToElements(paragraph).reverse();

    range.deleteContents();
    newNodes.forEach((node) => {
      range.insertNode(node);
    });

    // selectedRange.innerHTML = paragraph;

    if (window.getSelection) {
      window.getSelection().removeAllRanges();
    }

    setSelectedRange(null);
    setSelectedText("");
  };

  // const changeParagraph = (paragraph) => {
  //   const range = selectedRange;
  //   const startNode = range.startContainer;
  //   // startContainer가 텍스트 노드인지 요소 노드인지 확인합니다.
  //   if (startNode.nodeType === Node.TEXT_NODE) {
  //     startNode.parentNode.insertBefore(
  //       document.createTextNode(paragraph),
  //       startNode
  //     );
  //     startNode.parentNode.removeChild(startNode);
  //   } else if (startNode.nodeType === Node.ELEMENT_NODE) {
  //     var tempP = document.createElement("p");
  //     tempP.innerHTML = paragraph;
  //     var ele = tempP.firstChild;

  //     // 요소 노드의 경우, startOffset이 가리키는 위치에 새 요소를 삽입합니다.
  //     const child = startNode.childNodes[range.startOffset];
  //     startNode.insertBefore(ele, child);
  //   }
  //   // range.deleteContents();
  //   // range.insertNode(paragraph);
  // };

  const getEpisode = async (episodeId) => {
    const res = await $get(`/api/product/episode/detail/${episodeId}`);
    if (res.status === 200) {
      setActiveEpisode(res.data);
    }
  };

  const checkSpell = async () => {
    const pTags = noteRef.current.querySelectorAll("p");
    const paragraphs = [];
    // noteRef 안의 모든 텍스트 노드의 텍스트를 추출
    const textNodes = Array.from(noteRef.current.childNodes).filter(
      (node) =>
        node.nodeType === Node.TEXT_NODE ||
        (node.nodeType === Node.ELEMENT_NODE && node.nodeName !== "TABLE")
    );

    // textNodes.forEach((node) => {
    //   if (node.textContent.trim() !== "") {
    //     paragraphs.push(node.textContent.trim());
    //   }
    // });

    for (let i = 0; i < pTags.length; i++) {
      const text = pTags[i].innerText.trim();
      if (text === "") continue;
      if (text.length > 300) {
        const sentences = text.split(".");
        sentences.forEach((sentence) => {
          if (sentence.trim() !== "") {
            sentence = sentence.replace(/\\n/g, "");
            paragraphs.push(sentence.trim());
          }
        });
      } else {
        paragraphs.push(text);
      }
    }

    if (props.leftSidebarOpen) {
      openLeftSidebar();
    }
    setSpellCheckText(paragraphs);
    setTimeout(() => {
      setShowSpellCheckModal(true);
    }, 500);
  };

  const reOpenEpisode = async () => {
    Swal.fire({
      iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
      title: "에피소드 재개",
      text: "에피소드를 완료를 취소하시겠습니까?",
      showCancelButton: true,
      confirmButtonText: "예",
      cancelButtonText: "아니오",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $put(`/api/product/episode/${activeEpisode.id}`, {
          complete_yn: "N",
          summary: "",
        });
        if (res.status === 200) {
          getEpisode(activeEpisode.id);
          props.onList();
          Swal.fire({
            iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
            title: "에피소드 재개",
            text: "에피소드 완료가 취소되었습니다.",
            showConfirmButton: false,
            timer: 1000,
          });
        } else {
          Swal.fire({
            iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
            title: "에피소드 재개 실패",
            text: res.message,
            showConfirmButton: false,
            timer: 1000,
          });
        }
      }
    });
  };

  const completeEpisode = async () => {
    Swal.fire({
      iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
      title: "에피소드 완료",
      text: "에피소드 작성을 완료하시겠습니까?",
      showCancelButton: true,
      confirmButtonText: "예",
      cancelButtonText: "아니오",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoadingMessage("에피소드 작성을 완료하는 중입니다.");
        setLoading(true);
        const res = await $put(
          `/api/product/episode/complete/${activeEpisode.id}`,
          {
            product_id: activeEpisode.product_id,
            title: episodeTitle,
            content: noteRef.current.innerHTML,
            complete_yn: "Y",
          }
        );
        if (res.status === 200) {
          getEpisode(activeEpisode.id);
          if (
            props.productInfo.bread_yn === "Y" &&
            props.productInfo.bread_product_id !== null &&
            props.productInfo.bread_product_id !== ""
          ) {
            setLoadingMessage("에피소드를 브레드로 업로드하는 중입니다.");
            const res2 = await $post(
              `/api/bread/episode/${props.productInfo.bread_product_id}/${activeEpisode.id}`
            );
            if (res2.data.success) {
              setActiveEpisode({ ...activeEpisode, complete_yn: "Y" });
              setLoadingMessage("에피소드 줄거리를 생성하는 중입니다.");
              setShowSummaryModal(true);
            } else {
              Swal.fire({
                iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
                title: "에피소드를 브레드에 등록하지 못했습니다.",
                text: "에피소드 회차를 재개한 후 다시 회차 완료를 시도하세요. 문제가 지속되는 경우 브레드로 직접 에피소드를 등록하세요.",
                showConfirmButton: true,
              });
            }
          } else {
            setActiveEpisode({ ...activeEpisode, complete_yn: "Y" });
            setLoadingMessage("에피소드 줄거리를 생성하는 중입니다.");
            setShowSummaryModal(true);
          }

          props.onList();
        } else {
          Swal.fire({
            iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
            title: "에피소드 완료 실패",
            text: res.message,
            showConfirmButton: false,
            timer: 1000,
          });
        }

        setLoadingMessage("");
        setLoading(false);
      }
    });
  };

  const saveEpisode = async () => {
    if (!editMode) return;

    if (
      activeEpisode.content === noteRef.current.innerHTML &&
      activeEpisode.title === episodeTitle
    )
      return;

    const res = await $put(`/api/product/episode/${activeEpisode.id}`, {
      title: episodeTitle,
      content: noteRef.current.innerHTML,
    });

    if (res.status === 200) {
      const res2 = await $get(
        `/api/product/episode/detail/${activeEpisode.id}`
      );
      setActiveEpisode(res2.data);
    }
  };

  //   키보드 이벤트를 처리하는 함수
  const handleKeyDown = (event) => {
    if ((event.metaKey || event.ctrlKey) && event.key === "s") {
      event.preventDefault();
      saveEpisode();
    }
  };

  const copyEpisodeContent = () => {
    navigator.clipboard.writeText(noteRef.current.innerText);
    Swal.fire({
      iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
      title: "복사 완료",
      text: "클립보드에 복사되었습니다.",
      showConfirmButton: false,
      timer: 1000,
    });
  };

  const downloadPdf = async () => {
    setLoading(true);
    try {
      const res = await $download(
        `/api/product/convert/pdf/${activeEpisode.id}`
      );
      console.log(res);
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${activeEpisode.episode_no}-${activeEpisode.title}.pdf`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("PDF 다운로드 중 오류 발생:", error);
    }
    setLoading(false);
  };

  const getBreadEpisode = async () => {
    const res = await $get(
      `/api/bread/episode/${props.productInfo.bread_product_id}/${props.episode.episode_no}`
    );
  };

  const getUserPoints = async () => {
    const res = await $get("/api/user/points");
    if (res.status === 200) {
      return res.data.points;
    } else {
      return 0;
    }
  };

  const getDeductionPoints = async (funcCategory) => {
    const res = await $get(`/api/user/func/points/${funcCategory}`);
    if (res.status === 200) {
      return res.data.deduction_points;
    } else {
      return 0;
    }
  };

  const generateSummary = async () => {
    const deductionPoints = await getDeductionPoints("C");
    const userPoints = await getUserPoints();
    if (userPoints < deductionPoints) {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: `잼이 부족합니다.`,
        text: `잼을 충전하시겠습니까?(나의 잼: ${$convertNumberFormat(
          userPoints
        )}, 필요 잼: ${$convertNumberFormat(deductionPoints)})`,
        showCancelButton: true,
        confirmButtonText: "네, 충전하겠습니다.",
        cancelButtonText: "아니오.",
      }).then(async (result) => {
        if (result.isConfirmed) {
          navigate("/payment");
        }
      });
      return;
    } else {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "줄거리 제안 받기",
        text: `${$convertNumberFormat(
          deductionPoints
        )}잼이 사용됩니다. 줄거리를 제안 받으시겠습니까?`,
        showCancelButton: true,
        confirmButtonText: "예",
        cancelButtonText: "아니오",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setShowSummaryModal(true);
        }
      });
    }
  };

  useEffect(() => {
    if (noteRef.current) {
      if (noteRef.current.innerText.replace(/\s/g, "") === "") {
        noteRef.current.innerHTML = "";
      }
    }
  }, [editMode]);

  useEffect(() => {
    $checkOpenAIAvailable();
    // 컴포넌트가 마운트될 때 이벤트 리스너를 추가합니다.
    window.addEventListener("keydown", handleKeyDown);
    const saveInterval = setInterval(() => {
      if (!loading) saveEpisode();
    }, 60000);

    // 컴포넌트가 언마운트될 때 이벤트 리스너를 제거합니다.
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      clearInterval(saveInterval);
    };
  }, []); // 빈 의존성 배열을 전달하여 마운트와 언마운트 시에만 실행되게 합니다.

  useEffect(() => {
    setEditMode(false);
    // noteRef.current.innerHTML = "";
    setSelectedRange(null);
    setSelectedText("");
    setShowAIPopover(false);
    setShowMemoPopover(false);
    setShowSummaryModal(false);
    setShowParagraphModal(false);
    setActiveEpisode(props.episode);
    setEpisodeTitle(props.episode.title);
    setEpisodeContent(props.episode.content);
    if (props.episode.checkin_email === userData.email) setEditMode(true);
    setAssistantMessage("");

    // getBreadEpisode();

    if (props.episode.content !== null && props.episode.content !== "") {
      setTimeout(() => {
        if (noteRef.current) {
          // setEpisodeContentLength(
          //   noteRef.current.innerText.replace(/\s/g, "").length
          // );
          // const totalTextLength = Array.from(
          //   convertHTMLStringToElements(noteRef.current.innerHTML)
          // ).reduce((acc, cur) => {
          //   return acc + cur.textContent.replace(/\s/g, "").length;
          // }, 0);

          const totalTextLength = Array.from(noteRef.current.childNodes).reduce(
            (acc, cur) => {
              return acc + cur.textContent.replace(/\s/g, "").length;
            },
            0
          );

          setEpisodeContentLength(totalTextLength);
        }
      }, 1000);
    }

    if (props.productInfo.email === userData.email) {
      setIsAuthor(true);
    } else {
      setIsAuthor(false);
    }

    if (
      (props.episode.content === null || props.episode.content === "") &&
      (props.episode.suggest_summary === null ||
        props.episode.suggest_summary === "")
    ) {
      generateSummary();
    }
  }, [props.episode]);

  return (
    <div className="content">
      {userData && userData.role_level === 1 && (
        <div className="toolbar">
          <div className="left">
            <div
              className="item"
              onClick={() => {
                openLeftSidebar();
                props.onToggle();
              }}
            >
              <button
                type="button"
                className="btn btn-xsm btn-transparent tooltip"
              >
                <i
                  className={
                    leftSidebarOpen
                      ? "fa-solid fa-angles-left"
                      : "fa-solid fa-angles-right"
                  }
                ></i>
                <div className="tooltiptext">
                  {leftSidebarOpen ? "사이드바 감추기" : "사이드바 보이기"}
                </div>
              </button>
            </div>
            <div className="item-group">
              <div className="item">
                <select
                  className="sel-font-family"
                  value={mainFont}
                  onChange={(e) => setMainFont(e.target.value)}
                >
                  {koreanFontFamily.map((font, idx) => (
                    <option key={`font-${idx}`} value={font.className}>
                      {font.title}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="item-group">
              <div className="item">
                <select
                  className="sel-font-family"
                  value={fontSize}
                  onChange={(e) => setFontSize(e.target.value)}
                >
                  <option value="0.9">작게</option>
                  <option value="1">보통</option>
                  <option value="1.1">크게</option>
                </select>
              </div>
            </div>
            <div className="item-group">
              <div className="item">
                <button
                  type="button"
                  className="btn btn-xsm btn-transparent"
                  onClick={(e) => applyFormat("bold")}
                >
                  <i className="fa-solid fa-bold"></i>
                </button>
              </div>
              <div className="item">
                <button
                  type="button"
                  className="btn btn-xsm btn-transparent"
                  onClick={(e) => applyFormat("italic")}
                >
                  <i className="fa-solid fa-italic"></i>
                </button>
              </div>
              <div className="item">
                <button
                  type="button"
                  className="btn btn-xsm btn-transparent"
                  onClick={(e) => applyFormat("underline")}
                >
                  <i className="fa-solid fa-underline"></i>
                </button>
              </div>
              <div className="item">
                <button
                  type="button"
                  className="btn btn-xsm btn-transparent"
                  onClick={(e) => applyFormat("strikethrough")}
                >
                  <i className="fa-solid fa-strikethrough"></i>
                </button>
              </div>
            </div>
            <div className="item-group">
              <div className="item">
                <button
                  type="button"
                  className="btn btn-xsm btn-transparent"
                  onClick={(e) => applyFormat("alignLeft")}
                >
                  <i className="fa-solid fa-align-left"></i>
                </button>
              </div>
              <div className="item">
                <button
                  type="button"
                  className="btn btn-xsm btn-transparent"
                  onClick={(e) => applyFormat("alignCenter")}
                >
                  <i className="fa-solid fa-align-center"></i>
                </button>
              </div>
              <div className="item">
                <button
                  type="button"
                  className="btn btn-xsm btn-transparent"
                  onClick={(e) => applyFormat("alignRight")}
                >
                  <i className="fa-solid fa-align-right"></i>
                </button>
              </div>
              <div className="item">
                <button
                  type="button"
                  className="btn btn-xsm btn-transparent"
                  onClick={(e) => applyFormat("alignJustify")}
                >
                  <i className="fa-solid fa-align-justify"></i>
                </button>
              </div>
            </div>
            <div className="item-group">
              <div className="item">
                <button
                  type="button"
                  className="btn btn-xsm btn-transparent"
                  onClick={(e) => applyFormat("indent")}
                >
                  <i className="fa-solid fa-indent"></i>
                </button>
              </div>
              <div className="item">
                <button
                  type="button"
                  className="btn btn-xsm btn-transparent"
                  onClick={(e) => applyFormat("outdent")}
                >
                  <i className="fa-solid fa-outdent"></i>
                </button>
              </div>
            </div>
            <div className="item-group">
              <div className="item">
                <button
                  type="button"
                  className="btn btn-xsm btn-transparent tooltip"
                  onClick={() => setShowMobileSimulator(true)}
                >
                  <i className="fa-solid fa-mobile-screen"></i>
                  <div className="tooltiptext">모바일 보기</div>
                </button>
              </div>
              <div className="item">
                <button
                  type="button"
                  className="btn btn-xsm btn-transparent tooltip"
                  onClick={() => copyEpisodeContent()}
                >
                  <i className="fa-regular fa-copy"></i>
                  <div className="tooltiptext">복사하기</div>
                </button>
              </div>
              <div className="item">
                <button
                  type="button"
                  className="btn btn-xsm btn-transparent tooltip"
                  onClick={() => saveEpisode()}
                >
                  <i className="fa-regular fa-save"></i>
                  <div className="tooltiptext">저장하기</div>
                </button>
              </div>
              {/* {basicInfo.bread_yn === "Y" && (
              <div className="item">
                <button
                  type="button"
                  className="btn btn-xsm btn-transparent tooltip"
                >
                  <i className="fa-solid fa-file-export"></i>
                  <div className="tooltiptext">브레드에 올리기</div>
                </button>
              </div>
              )} */}
              <div className="item">
                <button
                  type="button"
                  className="btn btn-xsm btn-transparent tooltip"
                  onClick={(e) => checkSpell()}
                >
                  <i className="fa-solid fa-spell-check"></i>
                  <div className="tooltiptext">맞춤법검사</div>
                </button>
              </div>
              <div className="item">
                <button
                  type="button"
                  className="btn btn-xsm btn-transparent tooltip"
                  onClick={(e) => downloadPdf()}
                >
                  <i className="fa-solid fa-file-pdf"></i>
                  <div className="tooltiptext">PDF 다운받기</div>
                </button>
              </div>
            </div>
            {episodeContentLength > 0 && (
              <div className="item-group">
                <div className="item">{episodeContentLength}글자</div>
              </div>
            )}
          </div>
          <div className="right">
            <div className="item-group">
              <div className="item">
                <button
                  type="button"
                  className="btn btn-xsm btn-main2"
                  onClick={() => setShowSummaryModal(true)}
                >
                  {activeEpisode &&
                  (activeEpisode.suggest_summary ||
                    activeEpisode.complete_yn === "Y")
                    ? "줄거리 보기"
                    : "줄거리 제안 받기"}{" "}
                  <i className="fa-solid fa-book"></i>
                </button>
              </div>
              {activeEpisode && activeEpisode.complete_yn === "N" && (
                <div className="item">
                  <button
                    type="button"
                    className="btn btn-xsm btn-main2"
                    onClick={(e) => writeNextParagraph()}
                    disabled={!editMode}
                  >
                    AI 이어쓰기{" "}
                    <i className="fa-solid fa-wand-magic-sparkles"></i>
                  </button>
                </div>
              )}
              {activeEpisode && activeEpisode.complete_yn === "N" && (
                <div className="item">
                  <button
                    type="button"
                    className="btn btn-xsm btn-primary"
                    style={{ borderRadius: "10px" }}
                    onClick={() => toggleEdit()}
                  >
                    {editMode ? (
                      <i className="fa-solid fa-lock-open"></i>
                    ) : (
                      <i className="fa-solid fa-lock"></i>
                    )}
                    {editMode ? " 편집 완료" : " 편집 시작"}
                  </button>
                </div>
              )}
              <div className="item">
                {activeEpisode && activeEpisode.complete_yn === "Y" && (
                  <button
                    type="button"
                    className="btn btn-xsm btn-primary"
                    style={{ borderRadius: "10px" }}
                    onClick={() => reOpenEpisode()}
                  >
                    회차 재개
                  </button>
                )}
                {activeEpisode && activeEpisode.complete_yn === "N" && (
                  <button
                    type="button"
                    className="btn btn-xsm btn-default"
                    style={{ borderRadius: "10px" }}
                    onClick={() => completeEpisode()}
                  >
                    회차 완료
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {activeEpisode && (
        <div
          className="content-body overflow-y"
          ref={scrollRef}
          style={{ height: `calc(100vh - 130px)` }}
          onClick={(e) => hidePopover(e)}
        >
          <div className="note">
            <div className="episode-title">
              <input
                type="text"
                value={episodeTitle}
                placeholder="제목을 입력하세요."
                className={!editMode ? "color-gray" : ""}
                onChange={(e) => {
                  setEpisodeTitle(e.target.value);
                }}
                readOnly={!editMode}
              />
            </div>
            <div
              className={`episode-content ${mainFont}${
                !editMode ? " color-gray" : ""
              }`}
              style={{ fontSize: `${fontSize}rem` }}
              contentEditable={editMode}
              ref={noteRef}
              onInput={(e) => resizeHeight(false)}
              onMouseUp={(e) => handleSelectionChange(e)}
              onMouseOver={(e) => handleMouseOver(e)}
              onPaste={(e) => {
                e.preventDefault();

                // 클립보드에서 텍스트 데이터만 가져오기
                const text = (e.clipboardData || window.clipboardData).getData(
                  "text/plain"
                );

                // 텍스트 데이터를 contenteditable 요소에 삽입
                document.execCommand("insertText", false, text);
              }}
              onContextMenu={(e) => {
                e.preventDefault();
                if (!editMode) return;
                setAIPopoverPosition({
                  top: e.pageY,
                  left: e.pageX,
                });
                setShowMemoPopover(false);
                setShowAIPopover(true);
              }}
              dangerouslySetInnerHTML={{
                __html:
                  episodeContent === "" ||
                  episodeContent === null ||
                  episodeContent === "<p><br></p>"
                    ? ``
                    : episodeContent,
              }}
              onClick={(e) => {
                if (editMode && e.target.innerText.replace(/\s/g, "") === "") {
                  e.target.innerHTML = "<p><br></p>";
                }
              }}
              placeholder="여기에 글을 작성하세요."
            ></div>
            <div
              className={`episode-content-ai ${mainFont}`}
              style={{
                fontSize: `${fontSize}rem`,
                marginTop: "0px !important",
              }}
              ref={assistantRef}
              dangerouslySetInnerHTML={{ __html: assistantMessage }}
            ></div>
            {!editMode &&
              (episodeContent === "" ||
                episodeContent === null ||
                episodeContent === "<p><br></p>") && (
                <div className="work-guide">
                  편집시작 버튼을 클릭해서 시작하세요.
                </div>
              )}
          </div>
        </div>
      )}

      {showAIPopover && (
        <div
          className="ai-popover"
          style={{
            top: `${AIPopoverPosition.top}px`,
            left: `${AIPopoverPosition.left}px`,
            transform: "translateX(-50%)",
          }}
        >
          <button
            className="btn btn-transparent btn-xsm mr-5"
            onClick={async () => {
              setShowAIPopover(false);
              const deductionPoints = await getDeductionPoints("E");
              const userPoints = await getUserPoints();
              if (userPoints < deductionPoints) {
                Swal.fire({
                  iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
                  title: `잼이 부족합니다.`,
                  text: `잼을 충전하시겠습니까?(나의 잼: ${$convertNumberFormat(
                    userPoints
                  )}, 필요 잼: ${$convertNumberFormat(deductionPoints)})`,
                  showCancelButton: true,
                  confirmButtonText: "네, 충전하겠습니다.",
                  cancelButtonText: "아니오.",
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    navigate("/payment");
                  }
                });
                return;
              } else {
                setShowParagraphModal(true);
              }
            }}
          >
            <i className="fa-solid fa-robot"></i> 문장 바꿔쓰기
          </button>
          <button
            className="btn btn-transparent btn-xsm mr-5"
            onClick={(e) => {
              setShowAIPopover(false);
              setShowMemoPopover(true);
            }}
          >
            <i className="fa-regular fa-message"></i> 메모하기
          </button>
          {/* <button
                          className="btn btn-default btn-xsm"
                          onClick={(e) => removeSelectedText(e)}
                        >
                          삭제하기
                        </button> */}
        </div>
      )}

      {showMemoPopover && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            //   width: "430px",
            width: "600px",
            minWidth: "400px",
            justifyContent: "center",
            top: `${AIPopoverPosition.top}px`,
            left: `${AIPopoverPosition.left}px`,
            transform: "translateX(-50%)",
            backgroundColor: "white",
            border: "1px solid black",
            padding: "5px",
            borderRadius: "5px",
            zIndex: 1000,
          }}
        >
          <input
            type="text"
            className="form-control"
            placeholder="메모를 입력하세요."
            onKeyDown={(e) => {
              e.key === "Enter" && insertMemo(e.target.value);
            }}
          />
        </div>
      )}
      {showMemoViewPopover && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            top: `${AIPopoverPosition.top}px`,
            left: `${AIPopoverPosition.left}px`,
            transform: "translateX(-50%)",
            backgroundColor: "black",
            color: "white",
            border: "1px solid black",
            padding: "10px",
            borderRadius: "5px",
            zIndex: 1000,
          }}
        >
          {memoMessage}
        </div>
      )}
      {showSummaryModal && (
        <SummaryAssistantModal
          episode={activeEpisode}
          onSummary={(suggest_summary) => {
            setActiveEpisode({ ...activeEpisode, suggest_summary });
            setShowSummaryModal(false);
          }}
          onSummarize={(summary) => {
            setActiveEpisode({ ...activeEpisode, summary });
            setShowSummaryModal(false);
          }}
          onModal={setShowSummaryModal}
        />
      )}

      {showParagraphModal && (
        <ParagraphAssistantModal
          selectedText={selectedText}
          episode={activeEpisode}
          onModal={setShowParagraphModal}
          onChange={(paragraph) => {
            setShowParagraphModal(false);
            changeParagraph(paragraph);
          }}
        />
      )}

      {showMobileSimulator && (
        <MobileSimulator
          title={episodeTitle}
          content={noteRef.current.innerHTML}
          onModal={setShowMobileSimulator}
        />
      )}

      {showSpellCheckModal && (
        <SpellCheckerModal
          check={spellCheckText}
          onChangeSentence={(sentence) => {
            noteRef.current.innerHTML = noteRef.current.innerHTML.replace(
              `<span class="spell-check-highlight">${sentence.original}</span>`,
              sentence.corrected
            );

            noteRef.current.innerHTML = noteRef.current.innerHTML.replace(
              sentence.original,
              sentence.corrected
            );
          }}
          onMouseOver={(sentence) => {
            const regex = new RegExp(sentence.original, "g");
            noteRef.current.innerHTML = noteRef.current.innerHTML.replace(
              sentence.original,
              `<span class="spell-check-highlight">${sentence.original}</span>`
            );

            setTimeout(() => {
              const span = noteRef.current.querySelector(
                `span.spell-check-highlight`
              );
              if (span)
                span.scrollIntoView({ behavior: "smooth", block: "center" });
            }, 300);
          }}
          onMouseOut={(sentence) => {
            const regex = new RegExp(
              `<span class="spell-check-highlight">${sentence.original}</span>`,
              "g"
            );
            noteRef.current.innerHTML = noteRef.current.innerHTML.replace(
              `<span class="spell-check-highlight">${sentence.original}</span>`,
              `${sentence.original}`
            );
          }}
          onModal={setShowSpellCheckModal}
        />
      )}

      {loading && <Loading message={loadingMessage} />}
    </div>
  );
});

export default EpisodeEditor;
