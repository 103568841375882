// AuthProvider.js
import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AuthContext from "./AuthContext";
import { $get } from "../utils/common";
import NavData from "../layout/Nav.json";

export const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState(null);

  const login = (data) => {
    setIsLoggedIn(true);
    setUserData(data);
  };

  const logout = async () => {
    setIsLoggedIn(false);
    setUserData(null);
    const res = (await $get("/api/logout")).data;
    if (res.success) {
      navigate("/");
    }
  };

  const checkLogin = async () => {
    const res = (await $get("/api/login-check")).data;
    if (res.isLoggedIn) {
      const user = await $get(`/api/user/${res.email}`);
      login(user.data);
      if (user.data.active_yn === "N") {
        navigate("/waiting-list");
      } else {
        const menu = NavData.menus.find(
          (item) => item.link === location.pathname
        );
        if (menu) {
          const role_level = menu.role_level;
          if (user.data && user.data.role_level > role_level) {
            navigate("/401");
          }
        }
      }
    } else {
      setIsLoggedIn(false);
      setUserData(null);
      navigate("/");
    }
  };

  useEffect(() => {
    checkLogin();
  }, []);

  useEffect(() => {
    if (location.state && location.state.auth) {
      checkLogin();
    }

    // document.location.href 에서 pathname 을 가져올 수 있음
    // console.log(document.location.href);
    // console.log(document.location.pathname);

    // NavData에서 pathname에 해당하는 메뉴를 찾아서 role_level 가져오기
    // const menu = NavData.menus.find((item) => item.link === location.pathname);
    // if (menu) {
    //   const role_level = menu.role_level;
    //   console.log("role_level", role_level);
    //   console.log("userData.role_level", userData);
    //   if (userData && userData.role_level > role_level) {
    //     navigate("/401");
    //   }
    // }
  }, [location]);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        userData,
        setUserData,
        login,
        logout,
        checkLogin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
