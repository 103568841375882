import React, { useEffect, useState, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../login/AuthProvider";
import { $get, $convertNumberFormat } from "../utils/common";
import Swal from "sweetalert2";
import { loadPaymentWidget } from "@tosspayments/payment-widget-sdk";

function Order() {
  const appUrl = process.env.REACT_APP_URL;
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const tossClientKey = process.env.REACT_APP_TOSS_CLIENT_KEY;
  const tossGlobalClientKey = process.env.REACT_APP_TOSS_G_CLIENT_KEY;
  const navigate = useNavigate();
  const { price } = useParams();
  const [payments, setPayments] = useState([]);

  if (!price) {
    navigate("/");
  }

  const { isLoggedIn, userData, login, logout } = useAuth();
  const [customerName, setCustomerName] = useState("");
  const [phone, setPhone] = useState("");
  const [point, setPoint] = useState(0);
  const [paymentWidget, setPaymentWidget] = useState(null);
  const paymentWidgetRef = useRef(null);
  const paymentMethodsWidgetRef = useRef(null);
  const agreementWidgetRef = useRef(null);

  const generateRandomString = () => window.btoa(Math.random()).slice(0, 20);

  const initTosspament = async () => {
    // const customerKey = generateRandomString();
    const customerKey = "bwriter-userid-" + userData.user_id;
    const paymentWidget = await loadPaymentWidget(tossClientKey, customerKey);

    if (paymentWidgetRef.current == null) {
      paymentWidgetRef.current = paymentWidget;
    }

    /**
     * 결제창을 렌더링합니다.
     * @docs https://docs.tosspayments.com/reference/widget-sdk#renderpaymentmethods%EC%84%A0%ED%83%9D%EC%9E%90-%EA%B2%B0%EC%A0%9C-%EA%B8%88%EC%95%A1
     */
    const paymentMethodsWidget = paymentWidgetRef.current.renderPaymentMethods(
      "#payment-method",
      { value: price, currency: "KRW" },
      { variantKey: "DEFAULT" }
    );

    /**
     * 약관을 렌더링합니다.
     * @docs https://docs.tosspayments.com/reference/widget-sdk#renderagreement%EC%84%A0%ED%83%9D%EC%9E%90-%EC%98%B5%EC%85%98
     */
    agreementWidgetRef.current = paymentWidgetRef.current.renderAgreement(
      "#agreement",
      { variantKey: "DEFAULT" }
    );

    paymentMethodsWidgetRef.current = paymentMethodsWidget;
  };

  const buyPoints = async () => {
    const paymentWidget = paymentWidgetRef.current;

    try {
      /**
       * 결제 요청
       * @docs https://docs.tosspayments.com/reference/widget-sdk#requestpayment%EA%B2%B0%EC%A0%9C-%EC%A0%95%EB%B3%B4
       */
      await paymentWidget?.requestPayment({
        orderId: generateRandomString(),
        orderName: `${point}잼 구매`,
        customerEmail: userData.email,
        customerName: customerName,
        customerMobilePhone: phone,
        successUrl: `${window.location.origin}/order-success`,
        failUrl: `${window.location.origin}/order-fail`,
      });
    } catch (error) {
      // TODO: 에러 처리
      console.log(error);
    }
  };

  const getPayments = async () => {
    const res = await $get("/api/payment/point-price");
    if (res.status === 200) {
      setPayments(res.data);
      setPoint(res.data.find((item) => item.price === parseInt(price)).point);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      setCustomerName(userData.name ? userData.name : "");
      getPayments();
      setPhone(userData.phone);

      initTosspament();
    }
  }, []);

  return (
    <div className="container">
      <div className="order-form">
        <div className="sub-title font-nanumMyeongjo">주문 정보</div>
        <div className="order-price">
          <h3>주문 금액</h3>
          <p>
            {$convertNumberFormat(price)}원({$convertNumberFormat(point)}잼)
          </p>
        </div>
        {userData && (
          <div className="user-info">
            <h3>주문자 정보</h3>
            <div className="form-group">
              <label className="form-label">이름</label>
              <input
                type="text"
                className="form-control"
                value={customerName || ""}
                onChange={(e) => setCustomerName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label className="form-label">이메일</label>
              <input
                type="text"
                className="form-control"
                value={userData.email}
                disabled={true}
              />
            </div>
            <div className="form-group">
              <label className="form-label">연락처</label>
              <input
                type="text"
                className="form-control"
                placeholder="01012345678"
                value={phone || ""}
                onChange={(e) =>
                  setPhone(e.target.value.replace(/[^0-9]/g, ""))
                }
              />
            </div>
          </div>
        )}

        <div className="order-method">
          <div id="payment-method" style={{ width: "100%" }}></div>
          <div id="agreement" style={{ width: "100%" }}></div>
          <div className="btn-group">
            <button
              className="btn btn-lg btn-primary"
              onClick={() => buyPoints()}
            >
              결제하기
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Order;
