import React, { useState, useEffect } from "react";
import { $put, $post } from "../utils/common";
import Swal from "sweetalert2";

function QuestionModal(props) {
  const [question, setQuestion] = useState(props.question);

  const saveQuestion = async (e) => {
    if (props.question.id === undefined) {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "문의 등록",
        text: "문의를 등록하시겠습니까?",
        showCancelButton: true,
        confirmButtonText: "예",
        cancelButtonText: "아니오",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const res = await $post(`/api/question`, {
            title: question.title,
            content: question.content,
          });

          if (res.status === 200) {
            props.onSave();
          } else {
            Swal.fire({
              iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
              title: "문의 등록 실패",
              text: res.message,
              showConfirmButton: false,
              timer: 1000,
            });
          }
        }
      });
    } else {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "문의 수정",
        text: "문의를 수정하시겠습니까?",
        showCancelButton: true,
        confirmButtonText: "예",
        cancelButtonText: "아니오",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const res = await $put(`/api/question/${props.question.id}`, {
            title: question.title,
            content: question.content,
          });

          if (res.status === 200) {
            props.onSave();
          } else {
            Swal.fire({
              iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
              title: "문의 수정 실패",
              text: res.message,
              showConfirmButton: false,
              timer: 1000,
            });
          }
        }
      });
    }
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="modal-header">
          <span className="close" onClick={() => props.onModal(false)}>
            &times;
          </span>
          <h3>문의하기</h3>
        </div>
        <div className="modal-body">
          <div className="form-group">
            <label className="form-label">제목</label>
            <input
              type="text"
              className="form-control"
              value={question.title}
              onChange={(e) =>
                setQuestion({ ...question, title: e.target.value })
              }
            />
          </div>
          <div className="form-group">
            <label className="form-label">내용</label>
            <textarea
              className="form-control"
              rows="10"
              value={question.content}
              onChange={(e) =>
                setQuestion({ ...question, content: e.target.value })
              }
            />
          </div>
        </div>
        <div className="modal-footer flex justify-content-end">
          <button
            type="button"
            className="btn btn-default"
            onClick={() => props.onModal(false)}
          >
            닫기
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => saveQuestion()}
          >
            저장
          </button>
        </div>
      </div>
    </div>
  );
}

export default QuestionModal;
