import React from "react";
import HashLoader from "react-spinners/HashLoader";

function Loading(props) {
  return (
    <div className="modal-loading">
      <div className="modal-loading-animation">
        {/* <HashLoader color="#ae7e55" /> */}
        <div className="bread-animation"></div>
        {props.message && (
          <div className="loading-message">{props.message}</div>
        )}
      </div>
    </div>
  );
}

export default Loading;
