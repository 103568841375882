import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  $post,
  $put,
  $delete,
  $get,
  $dateTimezone,
  $convertNumberFormat,
} from "../utils/common";

function HomeMobile() {
  const [totalActiveUserCount, setTotalActiveUserCount] = useState(0);
  const [totalProductCount, setTotalProductCount] = useState(0);
  const [totalEpisodeCount, setTotalEpisodeCount] = useState(0);

  const getStatus = async () => {
    const res = await $get(`/api/bwriter-status`);
    if (res.status === 200) {
      setTotalActiveUserCount(res.data.userCount);
      setTotalProductCount(res.data.productCount);
      setTotalEpisodeCount(res.data.episodeCount);
    }
  };

  useEffect(() => {
    getStatus();
  }, []);

  return (
    <div className="home-mobile">
      <div className="mobile-title">
        <p>반갑습니다.</p>
        <p>집필을 원하시면 랩탑/데스크탑 혹은 태블릿에서 접속해보세요.</p>
      </div>
      <div className="mobile-subtitle">
        <p>
          비·라이터는 모바일을 제외한 1280x720픽셀 해상도 이상의 태블릿이나
          랩탑/데스크탑에서만 사용이 가능합니다.
        </p>
      </div>
      <div className="mobile-subtitle">
        <p>
          비·라이터는 글쓰기에 몰입할 수 있는 데스크탑/랩탑 환경을 우선적으로
          고려하여 개발되고 있으며, 베타서비스 이후 모바일 버전도 준비될
          예정입니다.
        </p>
      </div>
      <div className="mobile-subtitle">
        <p>
          비·라이터 안에서 {$convertNumberFormat(totalProductCount)}개의 이야기
          작품과 {$convertNumberFormat(totalEpisodeCount)}개의 에피소드가 정성껏
          집필되고 있습니다. 여러분도 도전해보세요.
        </p>
      </div>
      <div className="logo-image">
        <img src="./assets/images/AW_B_Logo_writer Beta3.png" />
      </div>
    </div>
  );
}

export default HomeMobile;
